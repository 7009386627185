import clsx from 'clsx';
import { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IPageElement, IPageElementContainer, PageElement, PageElementContainer, PageElementContainerStyle, PageElementType } from '../../../Services/SakuraApiClient';
import { useState2 } from '../../../common/Hooks/useState2';
import { ArrayOperation } from '../../../common/helpers/ObjectAndArray';
import { generateUniqueId } from '../../../common/helpers/uniqueId';
import { styleBuilderForParentEdition } from '../Page/Elements/Container/styleBuilder';
import { EditorDataContext } from './EditorContext/EditorDataContextProvider';

import { Icon } from '@fluentui/react';
import { CustomComponentName } from '../Page/Elements/CustomComponent/type';
import './ElementSelector.scss';
import { PageBlockAddDialog } from './ToolBox/PageBlockAddDialog';
import { getEditorContainerStylePerElement } from './getEditorContainerStylePerElement';
import { getEditorFlexStylePerElement } from './getEditorFlexStylePerElement';

export type DropPosition = 'Start' | 'End';

export interface ElementSelectorProps {
    data: PageElement;
    index: number;
    parentContainerId?: string;
    orientation?: 'Vertical' | 'Horizontal';
    isSelected: boolean;
    noDrag?: boolean;
    parentContainerStyle?: PageElementContainerStyle;
    onSelect: () => void;
    onDropItem?: (position: DropPosition, elementType: PageElementType, elementSubType: CustomComponentName | undefined, index: number) => void;
    onDropLibraryItem?: (position: DropPosition, element: IPageElementContainer, index: number) => void;
    onMoveItem?: (position: DropPosition, element: PageElement, index: number) => void;
    onRemoveItem?: (immediate: boolean, index: number, position?: DropPosition) => void;
    onCopyPaste?: (itemCopied: IPageElement, to: IPageElement, index: number) => void;
}
interface IItemElementType {
    elementType: PageElementType;
    elementSubType: CustomComponentName | undefined;
}
interface IItemElement {
    data: PageElement;
    parentContainerId?: string;
}
let elementClipBoard: IPageElement | undefined = undefined;
let elementClipBoardDisabled: boolean;
export function disableElementClipBoard() {
    elementClipBoardDisabled = true;
}
export function enableElementClipBoard() {
    elementClipBoardDisabled = false;
}

export const ElementSelector: FC<PropsWithChildren<ElementSelectorProps>> = (props: PropsWithChildren<ElementSelectorProps>) => {
    const { children, index, isSelected, onSelect, orientation, onDropItem, onDropLibraryItem, onMoveItem, data, onRemoveItem, parentContainerId, noDrag, parentContainerStyle, onCopyPaste } = props;
    const [mouseOver, setMouseOver] = useState<boolean>();
    const [copyPasteFlag, setCopyPasteFlag] = useState<'COPY' | 'PASTE' | undefined>();
    const [AddToLibDialog, setAddToLibDialog] = useState<boolean>();
    const selectorId = useRef(generateUniqueId('selector_'));
    const htmlDivRef = useRef<HTMLDivElement>();
    const [dragOver, setDragOver, dragOverRef] = useState2<DropPosition | undefined>();
    const editorContext = useContext(EditorDataContext);
    const dataRef = useRef<PageElement>(data);
    const indexRef = useRef<number>(index);
    useEffect(() => {
        dataRef.current = data;
    }, [data]);
    useEffect(() => {
        indexRef.current = index;
    }, [index]);

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: ['ELEMENT_TYPE', 'ELEMENT', 'ELEMENT_LIB_ITEM'],
            drop: (item: IItemElementType | IItemElement | IPageElementContainer, monitor) => {
                setDragOver(undefined);
                if (monitor.didDrop()) {
                    return monitor.getDropResult();
                }
                if (!htmlDivRef.current) {
                    htmlDivRef.current = document.getElementById(selectorId.current) as HTMLDivElement;
                }
                if (monitor.getItemType() === 'ELEMENT_TYPE') {
                    if (dragOverRef.current && onDropItem) {
                        onDropItem(dragOverRef.current, (item as IItemElementType).elementType, (item as IItemElementType).elementSubType, indexRef.current);
                    }
                }
                if (monitor.getItemType() === 'ELEMENT_LIB_ITEM') {
                    if (dragOverRef.current && onDropLibraryItem) {
                        onDropLibraryItem(dragOverRef.current, item as IPageElementContainer, indexRef.current);
                    }
                }
                if (monitor.getItemType() === 'ELEMENT') {
                    if (dragOverRef.current && onMoveItem) {
                        const itemElement = item as IItemElement;
                        if (itemElement.data.type === PageElementType.Container) {
                            if (itemElement.data === data) {
                                return undefined;
                            }
                            if (ArrayOperation.isChild((itemElement.data as PageElementContainer).items ?? [], data, 'items')) {
                                return undefined;
                            }
                        }

                        const hasDeleteItem = onMoveItem(dragOverRef.current, itemElement.data, indexRef.current);
                        return { position: dragOverRef.current, hasDeleteItem };
                    }
                }
                return monitor.getDropResult();
            },
            hover: (item, monitor) => {
                if (!htmlDivRef.current) {
                    htmlDivRef.current = document.getElementById(selectorId.current) as HTMLDivElement;
                }
                const offset = monitor.getClientOffset();
                if (offset) {
                    if (orientation === 'Horizontal') {
                        setDragOver(offset.x - htmlDivRef.current.offsetLeft < htmlDivRef.current.clientWidth / 2 ? 'Start' : 'End');
                    } else {
                        setDragOver(offset.y - htmlDivRef.current.offsetTop < htmlDivRef.current.clientHeight / 2 ? 'Start' : 'End');
                    }
                }
            },
            canDrop: (item, monitor) => {
                if (monitor.getItemType() === 'ELEMENT') {
                    const itemElement = item as IItemElement;
                    if (itemElement.data.type === PageElementType.Container) {
                        const isChild = ArrayOperation.isChild((itemElement.data as PageElementContainer).items ?? [], data, 'items');
                        console.log('ES: can drop = ', !isChild);
                        return !isChild;
                    }
                }
                return true;
            },
            collect: (monitor) => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        }),
        [orientation, onDropItem, onDropLibraryItem, onMoveItem, parentContainerId, data],
    );
    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type: 'ELEMENT',
            item: { data, parentContainerId },

            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end(draggedItem, monitor) {
                if (monitor.didDrop()) {
                    if (onRemoveItem) {
                        const result = monitor.getDropResult() as { position: DropPosition; hasDeleteItem: boolean } | undefined;
                        if (result && !result.hasDeleteItem) {
                            onRemoveItem(false, indexRef.current, result.position);
                        }
                    }
                }
            },
        }),
        [data],
    );
    const style = useMemo(() => {
        if (data.type === PageElementType.Container) {
            return styleBuilderForParentEdition((data as PageElementContainer).style);
        }
        return { display: 'flex', flex: getEditorFlexStylePerElement(data, parentContainerStyle) };
    }, [data, parentContainerStyle]);
    useEffect(() => {
        if (isDragging && dataRef.current) {
            editorContext.assignedPlanSelection(dataRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging, editorContext.assignedPlanSelection]);
    const onClickSelect = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            if (onSelect) {
                onSelect();
            }
        },
        [onSelect],
    );
    const divStyle = useMemo(() => {
        return { ...style, opacity: isDragging ? 0.5 : 1, ...getEditorContainerStylePerElement(data) };
    }, [isDragging, data, style]);

    const previewKeyUp = useCallback(
        (ev: React.KeyboardEvent<HTMLDivElement>) => {
            if (onCopyPaste && !elementClipBoardDisabled) {
                ev.stopPropagation();
                if (ev.ctrlKey && ev.key === 'c') {
                    elementClipBoard = { ...data };
                    setCopyPasteFlag('COPY');
                    setTimeout(() => {
                        setCopyPasteFlag(undefined);
                    }, 500);
                }
                if (ev.ctrlKey && ev.key === 'v' && elementClipBoard) {
                    onCopyPaste(elementClipBoard, data, indexRef.current);
                    setCopyPasteFlag('PASTE');
                    setTimeout(() => {
                        setCopyPasteFlag(undefined);
                    }, 500);
                }
            }
        },
        [onCopyPaste, data],
    );
    const onPanelClick = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setAddToLibDialog(true);
            ev.stopPropagation();
        },
        [setAddToLibDialog],
    );
    const onPanelClose = useCallback(() => setAddToLibDialog(false), [setAddToLibDialog]);
    const onKeyUp = useCallback(
        (ev: React.KeyboardEvent<HTMLDivElement>) => {
            if (ev.key === 'Delete' && onRemoveItem) {
                onRemoveItem(true, indexRef.current);
                ev.stopPropagation();
            }
            if (ev.key === ' ') {
                editorContext.assignedPlanSelection(dataRef.current);
                ev.stopPropagation();
            }
            if (ev.key === 'Enter' && onSelect) {
                onSelect();
                ev.stopPropagation();
            }
        },
        [onSelect, onRemoveItem, editorContext],
    );
    const onMouseOver = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            setMouseOver(true);
        },
        [setMouseOver],
    );
    const onMouseOut = useCallback(() => {
        setMouseOver(false);
    }, [setMouseOver]);

    return (
        <div ref={dragPreview} style={divStyle} onKeyUp={previewKeyUp}>
            <div ref={noDrag ? undefined : drag} style={style}>
                {isSelected && data.type === PageElementType.Container ? (
                    <>
                        <div className='elementSelectorOptionPanel' onClick={onPanelClick}>
                            <Icon iconName={'AddIn'} />
                            <span>{'Ajouter à la bibliothéque'}</span>
                        </div>
                        {AddToLibDialog ? <PageBlockAddDialog element={data} onClose={onPanelClose} /> : null}
                    </>
                ) : null}
                <div
                    id={selectorId.current}
                    ref={onDropItem ? drop : undefined}
                    style={style}
                    className={clsx(
                        'elementSelector',
                        isSelected ? 'selected' : '',
                        mouseOver ? 'mouseOver' : '',
                        isOver ? `dragHover${orientation}${dragOver}` : '',
                        isOver && canDrop === false ? 'noDrop' : '',
                    )}
                    onClick={onClickSelect}
                    tabIndex={0}
                    onKeyUp={onKeyUp}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    {copyPasteFlag ? (
                        <div className='copyPasteText'>
                            <Icon iconName={copyPasteFlag === 'COPY' ? 'Copy' : 'Paste'} />
                            <span>{copyPasteFlag === 'COPY' ? 'Copié' : 'Collé'}</span>
                        </div>
                    ) : undefined}
                    {children}
                </div>
            </div>
        </div>
    );
};
