import { DialogFooter, IIconProps, PrimaryButton } from '@fluentui/react';
import { FC, PropsWithChildren } from 'react';
import { useAppDispatch } from '../../../../Redux/hook';
import { clearContext, useLoadingSelector } from '../../../../Redux/Reducers/System/reducer';
import { useTimeout } from '../../../helpers/timeUtils';
import { CustomDialog } from '../CustomDialog';

export interface SimpleDialogProps {
    id: string;
    title: string;
    subText?: string;
    icon?: IIconProps;
    show: boolean;
    timeout?: number;
    closeButtonText?: string;
    disableDismiss?: boolean;
    onClose: () => void;
    enableFlyingPopup?: boolean;
}

export const SimpleDialog: FC<PropsWithChildren<SimpleDialogProps>> = (props: PropsWithChildren<SimpleDialogProps>) => {
    const { children, title, subText, id, show, onClose, icon, closeButtonText, disableDismiss, timeout, enableFlyingPopup } = props;
    const loadingIndicator = useLoadingSelector(id);
    const dispatch = useAppDispatch();
    const onCloseDialog = () => {
        dispatch(clearContext({ contextId: id }));
        onClose();
    };
    useTimeout(() => {
        if (timeout) {
            onClose();
        }
    }, timeout ?? 0);
    return (
        <CustomDialog
            id={id}
            hidden={!show}
            onDismiss={
                disableDismiss
                    ? () => {
                          // no close
                      }
                    : onCloseDialog
            }
            subText={subText}
            title={title}
            icon={icon}
            enableFlyingPopup={enableFlyingPopup}
        >
            <>
                {children}
                <DialogFooter>
                    <PrimaryButton disabled={loadingIndicator?.isLoading} onClick={onCloseDialog} text={closeButtonText ?? 'Fermer'} />
                </DialogFooter>
            </>
        </CustomDialog>
    );
};
