// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"themePrimary": `#49c1c5`,
	"themeLighterAlt": `#f6fcfd`,
	"themeLighter": `#ddf5f6`,
	"themeLight": `#c1eced`,
	"themeTertiary": `#89d9dc`,
	"themeSecondary": `#5bc8cb`,
	"themeDarkAlt": `#41adb1`,
	"themeDark": `#379295`,
	"themeDarker": `#296c6e`,
	"neutralLighterAlt": `#faf9f8`,
	"neutralLighter": `#f3f2f1`,
	"neutralLight": `#edebe9`,
	"neutralQuaternaryAlt": `#e1dfdd`,
	"neutralQuaternary": `#d0d0d0`,
	"neutralTertiaryAlt": `#c8c6c4`,
	"neutralTertiary": `#a19f9d`,
	"neutralSecondary": `#605e5c`,
	"neutralPrimaryAlt": `#3b3a39`,
	"neutralPrimary": `#323130`,
	"neutralDark": `#201f1e`,
	"black": `#000`,
	"white": `#fff`
};
export default ___CSS_LOADER_EXPORT___;
