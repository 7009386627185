import { DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import { useGridSelection } from '../../common/Hooks/useGridSelection';
import DetailsList from '../../common/components/CustomDetailList/CustomDetailList';
import { ColumnInfo } from '../../common/components/CustomDetailList/FilteredHeaderColumn';
import { PaginationSetting } from '../../common/components/CustomDetailList/ItemHelper';

export interface LocalDatasetGridProps<T> {
    data: T[];
    columns: ColumnInfo[];
    paginationSetting?: PaginationSetting;
    getKey?: (resource: T) => string | number;
    onSelect?: (resource: T | undefined) => void;
}

export const LocalDatasetGrid = <T extends object>(props: LocalDatasetGridProps<T>) => {
    const { onSelect, columns, data, getKey, paginationSetting } = props;

    const selection = useGridSelection(getKey, onSelect);

    return (
        <DetailsList
            columns={columns}
            noItemsPlaceholder={<></>}
            labels={{ resultPerPage: 'Résultats par page', totalRecord: 'Total' }}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            selectionMode={getKey ? SelectionMode.single : SelectionMode.none}
            selection={selection?.current}
            selectionPreservedOnEmptyClick
            items={data}
            totalItemCount={data?.length ?? 0}
            paginationSetting={paginationSetting}
        />
    );
};
