import { CSSProperties } from 'react';
import { IFontStyle, IFontStyles } from '../../Services/SakuraApiClient';
import { idToCssVar } from './common';

export const defaultFont = `'Poppins', sans-serif`;
export const listOfFontFamily = [
    'Arial,sans-serif',
    'Verdana, sans-serif',
    'Tahoma, sans-serif',
    "'Times New Roman', serif",
    "'Courier New', monospace",
    'Garamond, serif',
    "'Trebuchet MS', sans-serif",
    "'Poppins', sans-serif",
    "'Brush Script MT', cursive",
    'Copperplate, Papyrus, fantasy',
    "'Playfair Display'",
    "'Playfair Display SC', sans-serif",
    'Montserrat',
    'Montserrat-black',
    "'Brittany Aubrey', sans-serif",
    "'Nunito Sans', sans-serif",
    'Cursive',
    'Caption',
    'Waltograph',
    'Bukhari',
    'Garmani',
    'Publico',
    "'Courrier Prime'",
    'Lekton',
];

export interface FontProperties {
    style?: string;
    variant?: string;
    weight?: string;
    stretch?: string;
    size?: string;
    height?: string;
    family?: string;
}

export function duplicateFontStyles(fontStyles: IFontStyles) {
    let entries = fontStyles.entries ? [...fontStyles.entries] : undefined;
    if (entries) {
        entries = entries.map((e) => {
            return {
                font: e.font,
                entryName: e.entryName,
                color: e.color,
                strike: e.strike,
                underline: e.underline,
            };
        });
    }
    return { entries };
}
export function loadFontStyles(fontStyles: IFontStyles) {
    (fontStyles.entries ?? []).forEach((entry) => {
        if (entry.font && entry.entryName) {
            const entryName = entry.entryName.replaceAll(' ', '_');
            document.body.style.setProperty(`--F${entryName}`, entry.font);
            if (entry.color) {
                document.body.style.setProperty(`--FC${entryName}`, entry.color);
            }
            if (entry.strike || entry.underline) {
                document.body.style.setProperty(`--FTD${entryName}`, entry.underline ? 'underline' : entry.strike ? 'line-through' : '');
            }
        }
    });
}
export function fontStyleToCss(fontStyle: IFontStyle | undefined): CSSProperties {
    if (fontStyle) {
        if (fontStyle.presetName) {
            const presetName = fontStyle.presetName.replaceAll(' ', '_');
            return {
                font: idToCssVar(`--F${presetName}`),
                color: idToCssVar(`--FC${presetName}`),
                textDecoration: idToCssVar(`--FTD${presetName}`),
            };
        }
        let textDecoration = undefined;
        const textDecorations = [];

        if (fontStyle.underline) {
            textDecorations.push('underline');
        }
        if (fontStyle.strike) {
            textDecorations.push('line-through');
        }
        if (textDecorations.length > 0) {
            textDecoration = textDecorations.join(',');
        }

        return {
            ...fontPropertyToCss(fontStyle.font),
            color: fontStyle.color,
            textDecoration,
        };
    }
    return {};
}
export function fontPropertyToCss(fontProperty: string | undefined): CSSProperties {
    if (fontProperty) {
        const props = parseFontProperty(fontProperty);
        if (props.family && props.size && props.family !== '' && props.size !== '') {
            return {
                font: fontProperty,
            };
        }
        return {
            fontSize: props.size,
            fontWeight: props.weight,
            fontVariant: props.variant,
            fontFamily: props.family,
            lineHeight: props.height,
            fontStretch: props.stretch,
            fontStyle: props.style,
        };
    }
    return {};
}
export function parseFontProperty(fontProperty: string | undefined): FontProperties {
    const fontProperties: FontProperties = {};

    if (fontProperty) {
        const fontParts = fontProperty.split(/\s+/);

        fontParts.forEach((part) => {
            if (part.includes('italic') || (part.includes('normal') && !fontProperties.style)) {
                fontProperties.style = part;
            } else if (part.includes('small-caps') || part.includes('normal')) {
                fontProperties.variant = part;
            } else if (part.indexOf('/') !== -1) {
                const subPart = part.split('/');
                if (/^\d*\.?\d*(em|rem|px|%)$/.test(subPart[0])) {
                    fontProperties.size = subPart[0];
                }
                if (/^\d*\.?\d*(em|rem|px|%)$/.test(subPart[1])) {
                    fontProperties.height = subPart[1];
                }
            } else if (/^\d*\.?\d*(em|rem|px|%)$/.test(part) && !fontProperties.size) {
                fontProperties.size = part;
            } else if (/^\d*\.?\d*$/i.test(part) && !fontProperties.weight) {
                fontProperties.weight = part;
            } else {
                if (fontProperties.family === undefined) {
                    fontProperties.family = '';
                }
                fontProperties.family += part + ' ';
            }
        });

        if (fontProperties.family) {
            fontProperties.family = fontProperties.family.trimEnd();
        }
    }
    return fontProperties;
}
export function stringifyFontProperty(fontProperties: FontProperties): string {
    const parts: string[] = [];

    if (fontProperties.style) {
        parts.push(fontProperties.style);
    }

    if (fontProperties.variant) {
        parts.push(fontProperties.variant);
    }

    if (fontProperties.weight) {
        parts.push(fontProperties.weight);
    }
    if (fontProperties.stretch) {
        parts.push(fontProperties.stretch);
    }
    if (fontProperties.size) {
        let h = '';
        if (fontProperties.height) {
            h = '/' + fontProperties.height;
        }
        parts.push(fontProperties.size + h);
    }

    if (fontProperties.family) {
        parts.push(`${fontProperties.family}`);
    }

    return parts.join(' ');
}

export function fontPropertyChange(font: string | undefined, change: Partial<FontProperties>) {
    const fontProperty = { ...parseFontProperty(font), ...change };
    const style = stringifyFontProperty(fontProperty);
    return style;
}
