import { Dropdown, Toggle } from '@fluentui/react';
import { FC, useCallback, useMemo } from 'react';
import { Answer, IAnswer, IImageEffect, IQuestion, QuestionTypeEnum, QuestionnaryKind } from '../../../../Services/SakuraApiClient';
import { DeepPartial, DeepPartialWithArrayFunc, FormError } from '../../../../common/Hooks/Form/useForm';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { QuestionTypes } from './QuestionType';

import { ExpressionField, ExpressionKind } from '../../../../common/components/ExpressionField/ExpressionField';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { imageEffectPredefinedDropDown, imageEffectShapeDropDown } from '../../../../common/components/ImageViewer/ImageEffectPreDefined';
import { PanelCommand } from '../../../../common/components/Panel/Panel';
import { ArrayOperation } from '../../../../common/helpers/ObjectAndArray';
import { fisherYatesShuffle } from '../../../../common/helpers/fisherYates';
import { VisibilityComponent } from '../../../Common/PageEditor/ElementPanel/VisibilityComponent';
import { AnswerDraggable } from './AnswerDraggable';
import { AnswerEdit } from './AnswerEdit';
import { AnswerSetting } from './AnswerSetting';
import './QuestionEdit.scss';

export interface QuestionEditProps {
    question: DeepPartial<IQuestion>;
    error: FormError<IQuestion> | undefined;
    onUpdate: (question: DeepPartialWithArrayFunc<IQuestion>) => void;
    questionnaryKind: QuestionnaryKind;
    hasResponse: boolean;
}
export function shuffleAnswer(answerArray: DeepPartialWithArrayFunc<IAnswer>[], questionId: string) {
    const newArray = [...answerArray];
    fisherYatesShuffle(newArray as []);
    newArray.forEach((value, index) => (newArray[index] = { ...newArray[index], id: `${questionId}R${index + 1}` }));
    return newArray;
}
export const QuestionEdit: FC<QuestionEditProps> = (props: QuestionEditProps) => {
    const { question, error, onUpdate, questionnaryKind, hasResponse } = props;

    const reponseCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: 'Ajouter',
                icon: 'CircleAddition',
                disabled: hasResponse,
                onClick: () => {
                    const answer = new Answer();
                    answer.id = `${question.id}R${(question.answers ?? []).length + 1}`;
                    onUpdate({
                        answers: (answerArray) => {
                            const newArray = ArrayOperation.add(answerArray, answer);
                            newArray.forEach((value, index) => (value.id = `${question.id}R${index + 1}`));
                            return newArray;
                        },
                    });
                },
            },
            {
                text: 'Mélanger les réponses',
                icon: 'PageListFilter',
                disabled: (question.type !== QuestionTypeEnum.Choice && question.type !== QuestionTypeEnum.MultiChoice) || hasResponse,
                onClick: () => {
                    onUpdate({
                        answers: (answerArray) => {
                            const newArray = [...answerArray];
                            fisherYatesShuffle(newArray as []);
                            newArray.forEach((value, index) => (newArray[index] = { ...newArray[index], id: `${question.id}R${index + 1}` }));
                            return newArray;
                        },
                    });
                },
            },
        ];
    }, [hasResponse, question.type, question.id, question.answers, onUpdate]);

    const swapAnswer = useCallback(
        (currentIndex: number, targetIndex: number) => {
            onUpdate({
                answers: (array) => {
                    const newArray = [...array];
                    const src = newArray[currentIndex];
                    const dst = newArray[targetIndex];
                    newArray[targetIndex] = src;
                    newArray[currentIndex] = dst;
                    src.id = `${question.id}R${targetIndex + 1}`;
                    dst.id = `${question.id}R${currentIndex + 1}`;
                    return newArray;
                },
            });
        },
        [onUpdate, question],
    );

    return (
        <div>
            <div className='questionVisibility'>
                <VisibilityComponent visibleIf={question.visibleIf} onChange={(visibleIf) => onUpdate({ visibleIf })} />
            </div>
            <div className='questionEdit'>
                <ExpressionField
                    required
                    label='Intitulé de la question'
                    kind={ExpressionKind.TextWithExpression}
                    value={question.text}
                    errorMessage={error?.text}
                    multilines
                    minVisibleLines={2}
                    maxVisibleLines={2}
                    onChange={(text) => onUpdate({ text })}
                />
                <div>
                    <Dropdown
                        label='Type'
                        errorMessage={error?.type}
                        required
                        options={QuestionTypes}
                        selectedKey={question.type}
                        onChange={(_, type) => {
                            let change = {};
                            if (type?.key !== QuestionTypeEnum.YesNo) {
                                change = { yes: undefined, no: undefined };
                            }
                            onUpdate({
                                type: type?.key as QuestionTypeEnum,
                                answers: (array) => {
                                    if (type?.key === QuestionTypeEnum.FreeText || type?.key === QuestionTypeEnum.YesNo) {
                                        return undefined;
                                    }
                                    return array;
                                },
                                ...change,
                            });
                        }}
                    />
                    <div style={{ marginTop: '0.5rem' }}>
                        <Toggle
                            onText='Question obligatoire'
                            offText='Question optionelle'
                            checked={question.optional !== true}
                            onChange={(_, mandatory) => {
                                onUpdate({ optional: mandatory ? undefined : true });
                            }}
                        />
                    </div>
                </div>
                <ResourceLookupField
                    label='Image'
                    type='ResourceImageContentLookup'
                    selectedResourceId={question.imageResourceId}
                    onSelectResource={(resource) => {
                        if (resource) {
                            onUpdate({ imageResourceId: resource.id });
                        } else {
                            onUpdate({ imageResourceId: undefined });
                        }
                    }}
                />
                <Dropdown
                    label='Effet Visuel'
                    title="Effet visuel utiliser pour les questions avec réponse a base d'image"
                    errorMessage={error?.imageEffect?.preDefined}
                    options={imageEffectPredefinedDropDown}
                    selectedKey={question.imageEffect?.preDefined ?? ''}
                    onChange={(_, option) => {
                        let change: DeepPartial<IImageEffect> | undefined = undefined;
                        if (option?.key && option?.key !== '') {
                            change = {
                                ...question.imageEffect,
                                preDefined: option.key as string,
                            };
                        }
                        onUpdate({
                            imageEffect: change,
                        });
                    }}
                />
                <Dropdown
                    disabled={question?.imageEffect?.preDefined === undefined}
                    label='Forme'
                    title="Forme de l'image"
                    errorMessage={error?.imageEffect?.shape}
                    options={imageEffectShapeDropDown}
                    selectedKey={question.imageEffect?.shape ?? ''}
                    onChange={(_, option) => {
                        onUpdate({
                            imageEffect: {
                                ...question.imageEffect,
                                shape: option?.key === undefined || option?.key === '' ? undefined : (option.key as string),
                            },
                        });
                    }}
                />
                {question.type !== QuestionTypeEnum.FreeText ? (
                    <div className='fullRow'>
                        <FormGroup
                            title={question.type === QuestionTypeEnum.Choice || question.type === QuestionTypeEnum.MultiChoice ? `Réponses (${question?.answers?.length ?? 0})` : 'Réponse'}
                            isCollapsable
                            commands={question.type !== QuestionTypeEnum.YesNo ? reponseCommand : undefined}
                        >
                            {question.type === QuestionTypeEnum.YesNo ? (
                                <div className='fullRowDivHorizontal'>
                                    <AnswerSetting
                                        label='Si repond Oui'
                                        data={question.yes}
                                        error={error?.yes}
                                        questionnaryKind={questionnaryKind}
                                        onUpdate={(yes) => {
                                            onUpdate({ yes });
                                        }}
                                    />
                                    <AnswerSetting
                                        label='Si repond Non'
                                        data={question.no}
                                        error={error?.no}
                                        questionnaryKind={questionnaryKind}
                                        onUpdate={(no) => {
                                            onUpdate({ no });
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {question.type === QuestionTypeEnum.Choice || question.type === QuestionTypeEnum.MultiChoice ? (
                                <div className='answerList'>
                                    {(question.answers ?? []).map((answer, index) => {
                                        const errorItem = error?.answers?.at(index);
                                        return (
                                            <AnswerDraggable key={`R_${index}`} onDrop={swapAnswer} index={index} disabled={hasResponse}>
                                                <AnswerEdit
                                                    answer={answer}
                                                    error={errorItem}
                                                    onUpdate={(data) => {
                                                        onUpdate({ answers: (answerArray) => ArrayOperation.updateByIndex(answerArray, index, data) });
                                                    }}
                                                    disabledDelete={hasResponse}
                                                    onDelete={() => {
                                                        onUpdate({
                                                            answers: (answerArray) => {
                                                                const newArray = ArrayOperation.removeByIndex(answerArray, index);
                                                                newArray.forEach((value, index) => (value.id = `${question.id}R${index + 1}`));
                                                                return newArray;
                                                            },
                                                        });
                                                    }}
                                                    questionnaryKind={questionnaryKind}
                                                />
                                            </AnswerDraggable>
                                        );
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}
                        </FormGroup>
                    </div>
                ) : (
                    <></>
                )}
                {questionnaryKind === QuestionnaryKind.Exam && question.type !== QuestionTypeEnum.FreeText ? (
                    <div className='fullRowDivHorizontal'>
                        <AnswerSetting
                            label='Si reponse correcte'
                            data={question.whenCorrect}
                            error={error?.whenCorrect}
                            questionnaryKind={questionnaryKind}
                            onUpdate={(whenCorrect) => {
                                onUpdate({ whenCorrect });
                            }}
                            hideIsCorrect
                        />
                        <AnswerSetting
                            label='Si reponse incorrect'
                            data={question.whenNotCorrect}
                            error={error?.whenNotCorrect}
                            questionnaryKind={questionnaryKind}
                            onUpdate={(whenNotCorrect) => {
                                onUpdate({ whenNotCorrect });
                            }}
                            hideIsCorrect
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
