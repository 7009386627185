import { Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { BadgeLookup } from '../../../Redux/Reducers/DataSet/ApiCall/Badge';
import { EmailTemplateLookup } from '../../../Redux/Reducers/DataSet/ApiCall/Brevo';
import { ProductLookup } from '../../../Redux/Reducers/DataSet/ApiCall/Product';
import { useAppDispatch } from '../../../Redux/hook';
import { EmailServiceAction, IBadge, IEmailTemplate, IProduct, IWorkflowAction, WorkflowActionType } from '../../../Services/SakuraApiClient';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { BadgeLookupField } from '../../../common/LookupFields/BadgeLookupField';
import { ContactAttributeLookupField } from '../../../common/LookupFields/ContactAttributeLookupField';
import { ContactListLookupField } from '../../../common/LookupFields/ContactListLookupField';
import { ProductLookupField } from '../../../common/LookupFields/ProductLookupField';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { DropDownWithPictureWithPic } from '../../../common/components/DropDownWithPicture';
import { ExpressionField, ExpressionKind } from '../../../common/components/ExpressionField/ExpressionField';
import { NumberField } from '../../../common/components/NumberField/NumberField';
import { EmailTemplateSelector } from '../EmailTemplateSelector/EmailTemplateSelector';

export interface WorkflowActionEditProps {
    action: IWorkflowAction;
    onChange: (action: Partial<IWorkflowAction> | undefined, mode: 'merge' | 'override') => void;
    contextId?: string;
}
const actionOptions: IDropdownOption[] = [
    { key: WorkflowActionType.CreditSakuraCoin, text: 'Créditer des petales', data: { icon: 'Flower' } },
    { key: WorkflowActionType.CreditSakuraDiamond, text: 'Créditer des diamants', data: { icon: 'Diamond' } },
    { key: WorkflowActionType.CreditExperience, text: "Donner des points d'experience", data: { icon: 'D365TalentLearn' } },
    { key: WorkflowActionType.EarnBadge, text: 'Attribuer un badge', data: { icon: 'Badge' } },
    { key: WorkflowActionType.AssignmentExpression, text: 'Modifier une variable', data: { icon: 'Variable2' } },
    { key: WorkflowActionType.AcquiredProduct, text: 'Affecter un produit', data: { icon: 'Product' } },
    { key: WorkflowActionType.Email, text: 'Envoyé un email', data: { icon: 'Send' } },
    { key: WorkflowActionType.EmailServiceAttribute, text: 'Modifier un attribut Brevo', data: { icon: 'ContactCard' } },
    { key: WorkflowActionType.EmailServiceList, text: "Ajouter/Enlever d'une liste de contacts Brevo", data: { icon: 'ContactList' } },
    { key: WorkflowActionType.Workflow, text: 'Executer un workflow', data: { icon: 'SetAction' } },
];

export const addRemoveOptions: IDropdownOption[] = [
    {
        key: EmailServiceAction.Add,
        text: 'Ajouter à la liste',
    },
    {
        key: EmailServiceAction.Remove,
        text: 'Enlever de la liste',
    },
];

export const WorkflowActionEdit: FC<WorkflowActionEditProps> = (props: WorkflowActionEditProps) => {
    const { action, onChange, contextId } = props;
    const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>();
    const [selectedBadge, setSelectedBadge] = useState<IBadge | undefined>();
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<IEmailTemplate | undefined>();
    const appDispatch = useAppDispatch();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (action.productId) {
            const product = ProductLookup.lastResult?.result?.find((r) => r.id === action.productId);
            if (product === undefined) {
                performApiCall(
                    async (client, cancelToken) => {
                        if (action.productId) {
                            const prod = await client.getProduct(action.productId, cancelToken);
                            setSelectedProduct(prod);
                        }
                    },
                    appDispatch,
                    {
                        contextId,
                        cancelToken: source.token,
                    },
                );
            } else {
                setSelectedProduct(product);
            }
        } else {
            setSelectedProduct(undefined);
        }
        return () => {
            source.cancel();
        };
    }, [action.productId, appDispatch, contextId]);

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (action.badgeId) {
            const badge = BadgeLookup.lastResult?.result?.find((r) => r.id === action.badgeId);
            if (badge === undefined) {
                performApiCall(
                    async (client, cancelToken) => {
                        if (action.badgeId) {
                            const badge = await client.getBadgeById(action.badgeId, cancelToken);
                            setSelectedBadge(badge);
                        }
                    },
                    appDispatch,
                    {
                        contextId,
                        cancelToken: source.token,
                    },
                );
            } else {
                setSelectedBadge(badge);
            }
        } else {
            setSelectedBadge(undefined);
        }
        return () => {
            source.cancel();
        };
    }, [action.badgeId, appDispatch, contextId]);

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (action.templateEmailId) {
            const template = EmailTemplateLookup.lastResult?.result?.find((r) => r.id === action.templateEmailId) as IEmailTemplate;
            setSelectedEmailTemplate(template);
        } else {
            setSelectedEmailTemplate(undefined);
        }
        return () => {
            source.cancel();
        };
    }, [action.templateEmailId]);
    return (
        <div className='WorkflowActionEdit'>
            <PrimaryButton
                className='button'
                title='Effacer'
                iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    onChange(undefined, 'override');
                }}
            />
            <DropDownWithPictureWithPic
                placeholder='Sélectionner une action'
                options={actionOptions}
                selectedKey={action.type}
                onSelectKey={(key) => onChange({ type: key as WorkflowActionType }, 'override')}
            />

            {action.type === WorkflowActionType.CreditSakuraCoin ? <NumberField label='Quantité' value={action.coin} onChange={(value) => onChange({ ...action, coin: value }, 'merge')} /> : <></>}
            {action.type === WorkflowActionType.CreditSakuraDiamond ? (
                <NumberField label='Quantité' value={action.diamond} onChange={(value) => onChange({ ...action, diamond: value }, 'merge')} />
            ) : (
                <></>
            )}
            {action.type === WorkflowActionType.CreditExperience ? (
                <NumberField label='Quantité' value={action.experience} onChange={(value) => onChange({ ...action, experience: value }, 'merge')} />
            ) : (
                <></>
            )}
            {action.type === WorkflowActionType.AssignmentExpression ? (
                <>
                    <div className='FlexWrap'>
                        <ExpressionField
                            kind={ExpressionKind.Assignment}
                            label="Expression d'affectation"
                            value={action.expression}
                            onChange={(value) => onChange({ ...action, expression: value }, 'merge')}
                        />
                    </div>
                </>
            ) : (
                <></>
            )}
            {action.type === WorkflowActionType.AcquiredProduct ? (
                <>
                    <ProductLookupField label='Séléctionner un produit' selectedProduct={selectedProduct} onSelectProduct={(p) => onChange({ ...action, productId: p?.id }, 'merge')} />
                </>
            ) : (
                <></>
            )}
            {action.type === WorkflowActionType.EarnBadge ? (
                <>
                    <BadgeLookupField label='Séléctionner un badge' selectedBadge={selectedBadge} onSelectBadge={(b) => onChange({ ...action, badgeId: b?.id }, 'merge')} />
                </>
            ) : (
                <></>
            )}
            {action.type === WorkflowActionType.Email ? (
                <>
                    <EmailTemplateSelector
                        label='Séléctionner un Template'
                        emailTemplate={selectedEmailTemplate}
                        onSelectEmailTemplate={(e) => onChange({ ...action, templateEmailId: e?.id }, 'merge')}
                    />
                    {selectedEmailTemplate ? (
                        (selectedEmailTemplate.parameterNames ?? []).map((p) => {
                            return (
                                <TextField
                                    key={p}
                                    label={p}
                                    value={action.templateParameters ? action.templateParameters[p] : undefined}
                                    onChange={(_, v) => {
                                        const copyParams = { ...action.templateParameters };
                                        copyParams[p] = v;
                                        onChange({ ...action, templateParameters: copyParams }, 'merge');
                                    }}
                                />
                            );
                        })
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}

            {action.type === WorkflowActionType.EmailServiceAttribute ? (
                <>
                    <div className='FlexWrap'>
                        <ContactAttributeLookupField
                            label={`Attribut contact`}
                            contactAttributeName={action.emailServiceAttribute?.name}
                            onSelectContactAttribute={(contactAttributeResultLink) =>
                                onChange({ ...action, emailServiceAttribute: { ...action.emailServiceAttribute, name: contactAttributeResultLink?.name } }, 'merge')
                            }
                        />
                        <TextField
                            label='Valeur'
                            title="Valeur de l'attribut"
                            value={action.emailServiceAttribute?.value}
                            onChange={(_, value) => onChange({ ...action, emailServiceAttribute: { ...action.emailServiceAttribute, value } }, 'merge')}
                        />
                    </div>
                </>
            ) : (
                <></>
            )}
            {action.type === WorkflowActionType.EmailServiceList ? (
                <>
                    <div className='FlexWrap'>
                        <Dropdown
                            label='Opération'
                            required
                            options={addRemoveOptions}
                            selectedKey={action.emailServiceList?.action}
                            onChange={(_, v) => onChange({ ...action, emailServiceList: { ...action.emailServiceList, action: v?.key as EmailServiceAction } }, 'merge')}
                        />
                        <ContactListLookupField
                            label={`Liste de diffusion`}
                            contactListId={action.emailServiceList?.listId}
                            onSelectContactList={(contactListId) => onChange({ ...action, emailServiceList: { ...action.emailServiceList, listId: contactListId?.id ?? 0 } }, 'merge')}
                        />
                    </div>
                </>
            ) : (
                <></>
            )}
            {action.type === WorkflowActionType.Workflow ? (
                <>
                    <ResourceLookupField
                        type='ResourceWorkflowLookup'
                        label='Workflow'
                        selectedResourceId={action.workflowResourceId}
                        onSelectResource={(resource) => {
                            onChange({ ...action, workflowResourceId: resource?.id }, 'merge');
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
