import {
    ActionButton,
    Callout,
    ComboBox,
    DatePicker,
    DefaultButton,
    Dropdown,
    IComboBox,
    IComboBoxOption,
    Icon,
    IOnRenderComboBoxLabelProps,
    ISelectableDroppableTextProps,
    ISelectableOption,
    Label,
    PrimaryButton,
    TextField,
    Toggle,
} from '@fluentui/react';
import clsx from 'clsx';
import { Fragment, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { booleanAsString, parseBoolean } from '../../helpers/boolean';
import { generateUniqueId } from '../../helpers/uniqueId';
import { PaginationSetting } from '../CustomDetailList/ItemHelper';
import { NavigationAction } from '../CustomDetailList/Pagination';
import { ComboBoxItemPanel, SearchComboBoxMode } from './ComboBoxItemPanel';
import { NoRecordPanelProps } from './NoRecordPanel';
import { searchComboBoxReducer, SearchComboBoxReducer, SearchComboBoxState } from './Reducer';
import { FilterDefinition, SearchCriteria, SearchParameter } from './SearchParameters';

import './SearchComboBox.scss';

export type Value = string | number | boolean | Date | null | undefined;

export interface SearchComboBoxProps<TDataItem> {
    prefix?: string;
    viewMode: 'List' | 'Grid';
    label?: string;
    url?: string;
    readOnly?: boolean;
    required?: boolean;
    errorMessage?: string;
    onSearch?: (text: string | undefined, filters: SearchCriteria[], pageIndex: number) => Promise<void>;
    onClearDataset?: () => void;
    onChangeTextSearch?: (text: string) => string;
    paginationSetting?: PaginationSetting;
    frequentlyUsed?: TDataItem[];
    items?: TDataItem[];
    selectedItem?: TDataItem;
    totalCount?: number;
    onCreateNewItem?: () => void;
    onSelectItem?: (item?: TDataItem) => void;
    onRenderItem?: (item: TDataItem, comboBoxProps?: ISelectableOption, defaultRender?: (dfProps?: ISelectableOption) => JSX.Element | null) => JSX.Element | null;
    onRenderLabel?: (item: TDataItem) => JSX.Element | null;
    accessKeyProp: (di: TDataItem) => string;
    accessTextProp: (di: TDataItem) => string;
    OneResultBehavior?: 'None' | 'select';
    alignment?: 'Left' | 'right';
    filters?: FilterDefinition[];
    rtl?: boolean;
    callOutWidth?: 'ContentFit' | 'Fixed';
    callOutClassName?: string;
    panelCustomLabelResource?: string;
    customNoRecordPanel?: (props: NoRecordPanelProps) => JSX.Element;
    clearOnSelectedItem?: boolean;
}

export const SearchComboBox = <T,>(props: SearchComboBoxProps<T>) => {
    const {
        url,
        viewMode,
        onChangeTextSearch,
        onSearch,
        onClearDataset,
        items,
        accessKeyProp,
        accessTextProp,
        selectedItem,
        onSelectItem,
        onRenderItem,
        onRenderLabel,
        prefix,
        totalCount,
        frequentlyUsed,
        OneResultBehavior,
        label,
        readOnly,
        required,
        alignment,
        filters,
        paginationSetting,
        rtl,
        errorMessage,
        callOutWidth,
        callOutClassName,
        customNoRecordPanel,
        clearOnSelectedItem,
        onCreateNewItem,
    } = props;
    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const [state, dispatch] = useReducer<SearchComboBoxReducer<T>, SearchComboBoxState<T>>(
        searchComboBoxReducer,
        {
            filters,
            rtl: false,
            isSelected: false,
            filterVisible: false,
            hasCriterias: false,
        },
        (c) => c,
    );
    const [mode, setMode] = useState<SearchComboBoxMode>(frequentlyUsed ? 'FrequentlyUsed' : 'Search');
    const [isLoading, setIsLoading] = useState<boolean>();
    const menuState = useRef<boolean>();

    const componentDomId = useRef(`ComboBox_${generateUniqueId()}`);
    const searchComboBox = useRef(`SearchComboBox_${generateUniqueId()}`);

    useEffect(() => {
        dispatch({ type: 'ChangeDirection', payload: { rtl: rtl ?? false } });
    }, [rtl]);

    useEffect(() => {
        dispatch({ type: 'pageSettingChanged', paginationSetting });
    }, [paginationSetting]);
    useEffect(() => {
        dispatch({ type: 'DeclareFilter', payload: { filters } });
    }, [filters]);

    useEffect(() => {
        setIsLoading(false);
        if (items?.length === 1 && onSelectItem && OneResultBehavior === 'select') {
            onSelectItem(items[0] as T);
        }
        if (items === undefined) {
            setMode('FrequentlyUsed');
        }
        dispatch({ type: 'receivedNewItems', payload: { items: items ?? [] } });
    }, [items, OneResultBehavior, onSelectItem]);
    useEffect(() => {
        if (errorMessage) {
            setIsLoading(false);
            dispatch({ type: 'receivedNewItems', payload: { items: [] } });
        }
    }, [errorMessage]);

    const openMenu = useCallback(() => {
        const comboBoxElement = document.getElementById(componentDomId.current);
        const comboBoxCaretDownButton = comboBoxElement?.getElementsByClassName('ms-ComboBox-CaretDown-button');
        if (comboBoxCaretDownButton) {
            const element = comboBoxCaretDownButton.item(0) as HTMLButtonElement;
            if (element) {
                menuState.current = true;
                element.click();
            }
        }
    }, []);

    const performSearch = useCallback(
        async (text: string | undefined, pageIndex: number) => {
            setMode('Search');
            setIsLoading(true);

            const parameter = new SearchParameter(filters ?? [], text, state.rtl);
            if (onSearch) {
                await onSearch(parameter.primaryText, parameter.criterias, pageIndex);
                setIsLoading(false);
            }
        },
        [onSearch, setMode, setIsLoading, filters, state.rtl],
    );

    const onNavigate = useCallback(
        (action: NavigationAction) => {
            let pageIndex = 0;
            switch (action.type) {
                case 'pageNav':
                    pageIndex = action.pageNumber;
                    break;
                case 'beginNav':
                    pageIndex = 0;
                    break;
                case 'endNav':
                    pageIndex = paginationSetting?.totalPage ? paginationSetting?.totalPage - 1 : 0;
                    break;
                default:
                    break;
            }
            performSearch(state.searchText, pageIndex);
            dispatch(action);
        },
        [state.searchText, paginationSetting?.totalPage, performSearch, dispatch],
    );

    const onRenderList = useCallback(
        (
            ComboBoxProps?: ISelectableDroppableTextProps<IComboBox, IComboBox>,
            defaultRender?: (dfProps?: ISelectableDroppableTextProps<IComboBox, IComboBox>) => JSX.Element | null,
        ): JSX.Element | null => {
            if (defaultRender) {
                return (
                    <ComboBoxItemPanel
                        isLoading={isLoading ?? false}
                        viewMode={viewMode}
                        mode={mode ?? 'Search'}
                        searchComboBoxState={state}
                        paginationSetting={paginationSetting}
                        searchText={state.searchText ?? ''}
                        url={url}
                        nbItems={mode === 'Search' ? state.itemsView?.length ?? 0 : frequentlyUsed?.length ?? 0}
                        totalItems={totalCount}
                        onNavigate={onNavigate}
                        errorMessage={errorMessage}
                        showFrequentlyUsedLink={frequentlyUsed !== undefined && frequentlyUsed.length > 0}
                        onShowFrequentlyUsedLink={() => setMode('FrequentlyUsed')}
                        customNoRecordPanel={customNoRecordPanel}
                        onCreateNewItem={onCreateNewItem}
                        onchangeFilter={() => {
                            openMenu(); // to close it
                            dispatch({ type: 'FilterPanelToggle', payload: { visible: true } });
                        }}
                    >
                        {defaultRender(ComboBoxProps)}
                    </ComboBoxItemPanel>
                );
            }
            return null;
        },
        [openMenu, dispatch, setMode, state, paginationSetting, isLoading, viewMode, mode, url, totalCount, onNavigate, customNoRecordPanel, onCreateNewItem, frequentlyUsed, errorMessage],
    );
    const onRenderOption = useCallback(
        (comboBoxProps?: ISelectableOption, defaultRender?: (dfProps?: ISelectableOption) => JSX.Element | null): JSX.Element | null => {
            if (comboBoxProps?.key === -1 && comboBoxProps?.data === undefined && onCreateNewItem) {
                return <ActionButton iconProps={{ iconName: 'AddTo' }} text='Nouveau' onClick={() => onCreateNewItem()} />;
            }
            if (onRenderItem) {
                return (
                    <div
                        className={clsx(
                            'itemPanel',
                            alignment === 'right' ? 'rightSide' : 'leftSide',
                            selectedItem && comboBoxProps?.data && accessKeyProp(selectedItem as T) === accessKeyProp(comboBoxProps?.data) ? 'selected' : '',
                        )}
                    >
                        {onRenderItem(comboBoxProps?.data as T, comboBoxProps, defaultRender) ?? <></>}
                    </div>
                );
            }
            if (defaultRender) {
                return defaultRender(comboBoxProps);
            }
            return null;
        },
        [selectedItem, onCreateNewItem, onRenderItem, accessKeyProp, alignment],
    );
    const onRenderLabelCallback = useCallback(
        (comboBoxProps?: IOnRenderComboBoxLabelProps, defaultRender?: (dfProps?: IOnRenderComboBoxLabelProps) => JSX.Element | null): JSX.Element | null => {
            if (onRenderLabel && selectedItem) {
                return (
                    <div
                        className={clsx(
                            'itemPanel',
                            alignment === 'right' ? 'rightSide' : 'leftSide',
                            selectedItem && accessKeyProp(selectedItem as T) === accessKeyProp(selectedItem) ? 'selected' : '',
                        )}
                    >
                        {onRenderLabel(selectedItem) ?? <></>}
                    </div>
                );
            }
            if (defaultRender) {
                return defaultRender(comboBoxProps);
            }
            return null;
        },
        [selectedItem, onRenderLabel, accessKeyProp, alignment],
    );

    const itemToOptions = useCallback(
        (dataItems: T[] | undefined, getkey: (di: T) => string, getText: (di: T) => string) => {
            if (dataItems) {
                const options = dataItems.map<IComboBoxOption>((di) => {
                    return {
                        data: di,
                        key: getkey(di),
                        text: getText(di),
                    };
                });
                if (onCreateNewItem) {
                    options.unshift({ key: -1, text: '', data: undefined });
                }
                return options;
            }
            return [];
        },
        [onCreateNewItem],
    );
    useEffect(() => {
        if (selectedItem) {
            dispatch({ type: 'Select', payload: { text: accessTextProp(selectedItem) } });
        } else {
            dispatch({ type: 'unSelect' });
        }
    }, [selectedItem, accessTextProp]);

    const errMsg = state.filterVisible ? undefined : errorMessage;
    const calloutProps = useMemo(() => {
        return {
            className: clsx('applyContentMinWidthFitSearchComboBox', callOutClassName ?? '', callOutWidth === 'Fixed' ? 'applyContentFitSearchComboBox' : ''),
        };
    }, [callOutClassName, callOutWidth]);
    const onRenderContainer = useCallback(
        (p: ISelectableDroppableTextProps<IComboBox, IComboBox> | undefined, df?: (dfp: ISelectableDroppableTextProps<IComboBox, IComboBox> | undefined) => JSX.Element | null) => {
            const dd = document.getElementById(searchComboBox.current);
            document.body.style.setProperty('--currentSearchComboBoxMinSize', `${dd?.clientWidth.toString()}px`);
            if (df) {
                return df(p);
            }
            return null;
        },
        [],
    );
    const onClick = useCallback(() => {
        setHasFocus(true);
        if (menuState.current !== true && state.filterVisible === false) {
            openMenu();
        }
    }, [setHasFocus, openMenu, state.filterVisible]);
    const onBlur = useCallback(() => setHasFocus(false), [setHasFocus]);
    const onMenuDismissed = useCallback(() => {
        menuState.current = false;
    }, []);
    const comboBoxStyle = useMemo(() => {
        return { flex: 1 };
    }, []);
    const onKeyUp = useCallback(
        (ev: React.KeyboardEvent<IComboBox>) => {
            const txt = (ev.nativeEvent.target as HTMLInputElement).defaultValue;
            if (selectedItem) {
                if (txt !== state.searchText) {
                    if (onSelectItem) {
                        onSelectItem(undefined);
                    }
                    dispatch({ type: 'unSelect' });
                }
            } else {
                dispatch({ type: 'ChangeSearch', payload: { searchText: txt } });
            }
        },
        [onSelectItem, dispatch, selectedItem, state.searchText],
    );
    const onChange = useCallback(
        (_cc: React.FormEvent<IComboBox>, option: IComboBoxOption | undefined, _index: number | undefined, value: string | undefined) => {
            if (onSelectItem) {
                onSelectItem(option?.data);
            }
            if (option) {
                if (clearOnSelectedItem) {
                    dispatch({ type: 'Clear' });
                    if (onClearDataset) {
                        onClearDataset();
                    }
                    return;
                }
                let val = option.text;
                if (onChangeTextSearch) {
                    val = onChangeTextSearch(option.text);
                }
                dispatch({ type: 'Select', payload: { text: val } });
            } else {
                let val = value ?? '';
                if (onChangeTextSearch) {
                    val = onChangeTextSearch(val);
                }
                performSearch(val, state.pageInfo?.currentPage ?? 0);
                dispatch({ type: 'ChangeSearch', payload: { searchText: val } });
                openMenu();
            }
        },
        [onClearDataset, clearOnSelectedItem, performSearch, onSelectItem, onChangeTextSearch, openMenu, dispatch, state.pageInfo?.currentPage],
    );
    const onClickIconClear = useCallback(async () => {
        if (selectedItem) {
            if (onSelectItem) {
                onSelectItem(undefined);
            }
        } else {
            dispatch({ type: 'Clear' });
            if (onClearDataset) {
                onClearDataset();
            }
            //setMode(frequentlyUsed ? 'FrequentlyUsed' : 'Search');
            await performSearch(undefined, 0);
        }
    }, [performSearch, onClearDataset, onSelectItem, selectedItem, dispatch]);
    const onClickIconFilter = useCallback(() => {
        const dd = document.getElementById(searchComboBox.current);
        document.body.style.setProperty('--currentSearchComboBoxMinSize', `${dd?.clientWidth.toString()}px`);
        if (state.filterVisible) {
            dispatch({
                type: 'FilterPanelToggle',
                payload: { visible: false },
            });
        } else {
            dispatch({
                type: 'FilterPanelToggle',
                payload: { visible: true },
            });
        }
    }, [dispatch, state.filterVisible]);

    const onClickSearch = useCallback(() => {
        performSearch(state.searchText, state.pageInfo?.currentPage ?? 0);
        dispatch({
            type: 'FilterPanelToggle',
            payload: { visible: false },
        });
        openMenu();
    }, [performSearch, openMenu, dispatch, state.searchText, state.pageInfo?.currentPage]);

    return (
        <>
            <div id={searchComboBox.current} className={clsx('vertialFlexDiv')}>
                {label && <Label required={required}>{label}</Label>}
                <div className={clsx('searchComboBox', hasFocus ? 'hasFocus' : '', (errMsg ?? '') !== '' ? 'hasError' : '')}>
                    {prefix && (
                        <div className={clsx('searchComboBoxPrefix', hasFocus ? 'hasFocus' : '', (errMsg ?? '') !== '' ? 'hasError' : '')}>
                            <span>{prefix}</span>
                        </div>
                    )}
                    <ComboBox
                        disabled={readOnly}
                        style={comboBoxStyle}
                        id={componentDomId.current}
                        className={clsx(prefix ? 'hasPrefix' : '', (errMsg ?? '') !== '' ? 'hasError' : '')}
                        calloutProps={calloutProps}
                        onRenderList={onRenderList}
                        onRenderOption={onRenderOption}
                        onRenderLabel={onRenderLabelCallback}
                        onRenderContainer={onRenderContainer}
                        onClick={onClick}
                        onBlur={onBlur}
                        onMenuDismissed={onMenuDismissed}
                        options={itemToOptions(mode === 'Search' ? state.itemsView : frequentlyUsed, accessKeyProp, accessTextProp)}
                        text={state.searchText}
                        autoComplete='off'
                        allowFreeform
                        selectedKey={selectedItem ? accessKeyProp(selectedItem) : null}
                        onKeyUp={onKeyUp}
                        onChange={onChange}
                    />
                    {(state.searchText ? state.searchText.trim() : '') !== '' && !readOnly && (
                        <Icon className={clsx('clearButton', hasFocus ? 'hasFocus' : '', (errMsg ?? '') !== '' ? 'hasError' : '')} iconName='Cancel' onClick={onClickIconClear} />
                    )}
                    {filters && filters.length > 0 && state.isSelected === false && (
                        <Icon
                            className={clsx('filterButton', hasFocus ? 'hasFocus' : '', (errMsg ?? '') !== '' ? 'hasError' : '')}
                            iconName={state.hasCriterias ? 'FilterSolid' : 'Filter'}
                            onClick={onClickIconFilter}
                        />
                    )}
                    {selectedItem ? (
                        <Icon iconName='CompletedSolid' className={clsx('itemSelected', hasFocus ? 'hasFocus' : '')} />
                    ) : (
                        <PrimaryButton disabled={readOnly} className='searchComboBox-searchButton' iconProps={{ iconName: 'Search' }} onClick={onClickSearch} />
                    )}
                </div>
                <span className='SearchComboBoxErrorMessage'>{errMsg ?? ''}</span>
            </div>
            {state.filterVisible && (
                <Callout
                    target={`#${searchComboBox.current}`}
                    isBeakVisible={false}
                    className={clsx('applyContentMinWidthFitSearchComboBox', callOutClassName ?? '', callOutWidth === 'Fixed' ? 'applyContentFitSearchComboBox' : '')}
                >
                    <div className='filterComboBoxPanel'>
                        <div className='filterComboBoxGrid'>
                            {state.editCriterias?.map((criteria) => {
                                return (
                                    <Fragment key={`criteria_${criteria.name}`}>
                                        <Label>{criteria.name}</Label>
                                        {criteria.definition.type === 'text' && (
                                            <TextField
                                                errorMessage={criteria.definition.errorMessage}
                                                prefix={criteria.definition.prefix}
                                                value={criteria.value}
                                                onChange={(_, val) => {
                                                    dispatch({
                                                        type: 'UpdateCriteria',
                                                        payload: {
                                                            name: criteria.name,
                                                            value: val,
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                        {criteria.definition.type === 'date' && (
                                            <DatePicker
                                                value={(criteria.value ?? '') !== '' ? new Date(Date.parse(criteria.value ?? '')) : undefined}
                                                onSelectDate={(d) => {
                                                    dispatch({
                                                        type: 'UpdateCriteria',
                                                        payload: {
                                                            name: criteria.name,
                                                            value: d ? d.toDateString() : '',
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                        {criteria.definition.type === 'boolean' && (
                                            <Toggle
                                                checked={parseBoolean(criteria.value)}
                                                onChange={(_, checked) => {
                                                    dispatch({
                                                        type: 'UpdateCriteria',
                                                        payload: {
                                                            name: criteria.name,
                                                            value: booleanAsString(checked, rtl ?? false),
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                        {criteria.definition.type === 'list' && (
                                            <Dropdown
                                                errorMessage={criteria.definition.errorMessage}
                                                selectedKey={criteria.value}
                                                options={criteria.definition.options ?? []}
                                                onChange={(_, val) => {
                                                    dispatch({
                                                        type: 'UpdateCriteria',
                                                        payload: {
                                                            name: criteria.name,
                                                            value: val?.key ? val?.key.toString() : undefined,
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </div>
                        <div className='filterComboBoxActions'>
                            <DefaultButton
                                text={'Annuler'}
                                onClick={() => {
                                    dispatch({ type: 'CancelCriterias' });
                                }}
                            />
                            <PrimaryButton
                                iconProps={{ iconName: 'FilterSolid' }}
                                text={'Appliquer'}
                                onClick={() => {
                                    dispatch({ type: 'ApplyCriterias' });
                                }}
                            />
                        </div>
                    </div>
                </Callout>
            )}
        </>
    );
};
