import { FC, useCallback, useMemo } from 'react';
import { declareVariables, useDynamicDataIntelisense } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../../../Redux/hook';
import { PageElementRichText, PageElementType } from '../../../../../Services/SakuraApiClient';
import { Expression } from '../../../../../common/components/ExpressionField/Expression';
import { ExpressionVariable } from '../../../../../common/components/ExpressionField/Expression/ExpressionVariable';
import RichTextLexicalEditor, { EditorContent } from '../../../../../common/components/RichTextLexical/Editor';
import { ExpressionPluginProps } from '../../../../../common/components/RichTextLexical/Plugins/ExpressionPlugin';
import { createNewElement } from '../Container/createNewElement';

export interface ElementRichTextEditionProps {
    data: PageElementRichText;
    onChangeData: (data: PageElementRichText) => void;
    isSelected: boolean;
}

export const ElementRichTextEdition: FC<ElementRichTextEditionProps> = (props: ElementRichTextEditionProps) => {
    const { data, onChangeData, isSelected } = props;

    const dispatch = useAppDispatch();

    const intelisense = useDynamicDataIntelisense();

    const expressionOptions = useMemo<ExpressionPluginProps>(() => {
        return {
            intelisenseDataSource: intelisense,
            enabledBracketExpression: true,
        };
    }, [intelisense]);

    const editorOnChange = useCallback(
        (content: EditorContent) => {
            const newData = createNewElement(PageElementType.RichText, undefined) as PageElementRichText;
            if (newData) {
                const newContent = content.getJson();
                console.log(newContent);
                newData.init({ type: PageElementType.RichText, visibleIf: data.visibleIf, content: newContent });
                onChangeData(newData);
            }
            const text = content.getPlainText();
            const expressions = Expression.getExpressionInBrackets(text);
            const variables: ExpressionVariable[] = [];
            expressions.forEach((exp) => {
                variables.push(...exp.getListOfVariableUsed());
            });
            if (variables.length > 0) {
                dispatch(declareVariables({ variables }));
            }
        },
        [dispatch, onChangeData, data],
    );

    return (
        <div>
            <RichTextLexicalEditor jsonState={data.content} expressionOptions={expressionOptions} onChange={editorOnChange} placeholder='taper un texte' showTabBar={isSelected} />
        </div>
    );
};
