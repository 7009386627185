import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { jsonParse } from '../common/helpers/Json';
import { addBadgeToDisplay, setBalance, setExperienceChangeAction } from '../Redux/Reducers/User/reducer';
import { store } from '../Redux/store';
import { ServiceCreator } from './ServiceCreator';

function getNumber(str: string | undefined) {
    if (str) {
        const val = Number.parseInt(str, 10);
        if (isNaN(val)) {
            return undefined;
        }
        return val;
    }
    return undefined;
}
export async function interceptResponseHeader(headers: RawAxiosResponseHeaders | AxiosResponseHeaders): Promise<void> {
    const badgeHeader = headers['sakura-acquired-badges'];
    if (badgeHeader && badgeHeader !== '') {
        const ids = jsonParse(badgeHeader);
        if (ids.length > 0) {
            const client = ServiceCreator.SakuraApiClient();
            for (let i = 0; i < ids.length; i++) {
                const badge = await client.getBadgeById(ids[i]);
                store.dispatch(addBadgeToDisplay({ badge }));
            }
        }
    }

    const productHeader = headers['sakura-acquired-products'];
    if (productHeader && productHeader !== '') {
        const ids = jsonParse(productHeader);
        if (ids.length > 0) {
            const client = ServiceCreator.SakuraApiClient();
            for (let i = 0; i < ids.length; i++) {
                await client.getProduct(ids[i]);

                //TODO show product acquired
                // store.dispatch(addBadgeToDisplay({ badge }));
            }
        }
    }
    const state = store.getState();
    const newCoin = getNumber(headers['sakura-acquired-coin']);
    const coinBalance = getNumber(headers['sakura-coin-balance']);
    const newDiamond = getNumber(headers['sakura-acquired-diamond']);
    const diamondBalance = getNumber(headers['sakura-diamond-balance']);

    if (state.user.userProfile?.sakuraCoin !== coinBalance || state.user.userProfile?.sakuraDiamond !== diamondBalance) {
        store.dispatch(setBalance({ acquiredCoin: newCoin, acquiredDiamond: newDiamond, newBalanceCoin: coinBalance, newBalanceDiamond: diamondBalance }));
    }

    const xp = getNumber(headers['sakura-experience']);
    const level = getNumber(headers['sakura-level']);
    const experienceAcquired = getNumber(headers['sakura-acquired-experience']);
    const experienceRequiredToLevelUp = getNumber(headers['sakura-experience-required-to-levelup']);
    const hasLevelUp = headers['sakura-LevelUp'];
    let levelUp = state.user.userProfile?.level !== level;
    if (hasLevelUp === 'true') {
        levelUp = true;
    }
    if (state.user.userProfile?.level !== level || state.user.userProfile?.experience !== xp || state.user.experienceRequiredToLevelUp !== experienceRequiredToLevelUp) {
        store.dispatch(
            setExperienceChangeAction({
                experience: xp,
                experienceAcquired,
                hasLevelUp: levelUp,
                level,
                experienceRequiredToLevelUp,
            }),
        );
    }
}
