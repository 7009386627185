import { FC, useMemo, useRef } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { generateUniqueId } from '../../../helpers/uniqueId';
import InitializerPlugin from '../Plugins/InitializerPlugin';
import { commonTheme } from '../Theming/commonTheme';
import '../Theming/lexical.scss';
import './lexicalViewer.scss';

const theme = { ...commonTheme, paragraph: 'lexical-viewer-paragraph' };

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
    console.error(error);
}

export interface RichTextLexicalViewerProps {
    jsonState?: SerializedEditorState<SerializedLexicalNode>;
}
const RichTextLexicalViewer: FC<RichTextLexicalViewerProps> = (props: RichTextLexicalViewerProps) => {
    const { jsonState } = props;
    const viewerId = useRef(generateUniqueId('lexicalViewer'));
    const initialConfig = useMemo(() => {
        return {
            namespace: 'SakuraLexicalViewer',
            theme,
            editable: false,
            onError,
        };
    }, []);
    return (
        <LexicalComposer initialConfig={initialConfig}>
            <RichTextPlugin contentEditable={<ContentEditable readOnly />} ErrorBoundary={LexicalErrorBoundary} />
            <InitializerPlugin key={viewerId.current} jsonState={jsonState} content={undefined} />
        </LexicalComposer>
    );
};

export default RichTextLexicalViewer;
