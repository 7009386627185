import { INavMenuEntry } from '../../../common/components/NavMenu';
import { IAccountDataResource, IResourceHierarchyView } from '../../../Services/SakuraApiClient';

export function resourceHierarchyViewToMenuEntry(items: IResourceHierarchyView[] | undefined): INavMenuEntry<IResourceHierarchyView>[] {
    if (items) {
        return items
            .sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0))
            .map((item) => {
                const menuItem = resourceHierarchyViewItemToMenuEntry(item);
                menuItem.children = resourceHierarchyViewToMenuEntry(item.children);
                return menuItem;
            });
    }
    return [];
}
export function resourceHierarchyViewItemToMenuEntry(item: IResourceHierarchyView): INavMenuEntry<IResourceHierarchyView> {
    return {
        id: item.resourcePath,
        icon: item.overrideIcon ?? item.resource.icon,
        name: item.overrideName ?? item.resource.name,
        children: undefined,
        data: item,
    };
}
export function getParentPath(path: string | undefined) {
    if (path) {
        const index = path.lastIndexOf('/');
        const firstPart = index !== -1 ? path.substring(0, index) : path;
        return firstPart;
    }
    return undefined;
}
export function getIdPart(path: string | undefined) {
    if (path) {
        const index = path.lastIndexOf('/');
        const lastPart = path.substring(index + 1);
        return lastPart;
    }
    return undefined;
}
export function getParts(path: string | undefined) {
    if (path) {
        const index = path.lastIndexOf('/');
        return { parentPath: index !== -1 ? path.substring(0, index) : undefined, id: path.substring(index + 1) };
    }
    return undefined;
}

export interface FormationContent {
    formation: IResourceHierarchyView;
    menu: INavMenuEntry<IResourceHierarchyView>[];
    content: Record<string, IResourceHierarchyView>;
    userData: Record<string, IAccountDataResource>;
}

export function buildFormationContent(hierarchyView: IResourceHierarchyView): FormationContent {
    const content: FormationContent = {
        formation: hierarchyView,
        menu: resourceHierarchyViewToMenuEntry(hierarchyView.children),
        content: {},
        userData: {},
    };
    buildFormationContentForChildren(hierarchyView.children, content);
    return content;
}
function buildFormationContentForChildren(children: IResourceHierarchyView[] | undefined, content: FormationContent) {
    if (children) {
        children.sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0));
        for (let i = 0; i < children.length; i++) {
            const item = children[i];
            if (item?.resourcePath) {
                content.content[item.resourcePath] = item;
                content.userData[item.resourcePath] = item.accountDataResource;
                buildFormationContentForChildren(item.children, content);
            }
        }
    }
}
