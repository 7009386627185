import { Icon } from '@fluentui/react';
import { FC } from 'react';
import { useUserDataOfMenuItem } from './FormationContext/FormationContextSelector';

export interface FormationNavigationMenuItemStateProps {
    resourcePath: string;
}
export const FormationNavigationMenuItemState: FC<FormationNavigationMenuItemStateProps> = (props: FormationNavigationMenuItemStateProps) => {
    const { resourcePath } = props;
    const userdata = useUserDataOfMenuItem(resourcePath);

    if (userdata?.data?.completedOn) {
        return <Icon iconName='SkypeCircleCheck' className='menuStateIcon' />;
    }
    return <Icon iconName='CircleRing' className='menuStateIcon' />;
};
