import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { LexicalEditor } from 'lexical';
import { FC, useCallback } from 'react';
import { applyStyleOnSelection } from '../../Helpers/selection';
import { predefinedStylePropertyName } from './PredefinedStyleSelector';

export interface FontSizeSelectorProps {
    fontSize?: string;
    editor: LexicalEditor;
}

export const defaultFontSize = `16px`;
function generateFontSize(start: number, end: number) {
    return Array.from({ length: end - start + 1 }, (_, i) => {
        const size = start + i;
        return {
            key: `${size}px`,
            text: `${size}px`,
        };
    });
}

export const listFontSizes: IDropdownOption[] = generateFontSize(8, 72);

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 80 },
};
export const FontSizeSelector: FC<FontSizeSelectorProps> = (props: FontSizeSelectorProps) => {
    const { editor, fontSize } = props;

    const handleClick = useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
            applyStyleOnSelection(
                editor,
                {
                    [predefinedStylePropertyName]: null,
                    'font-size': (option?.key as string) ?? defaultFontSize,
                },
                false,
            );
        },
        [editor],
    );
    return <Dropdown className='ddlsmallsize' styles={dropdownStyles} options={listFontSizes} selectedKey={fontSize ?? defaultFontSize} onChange={handleClick} />;
};
