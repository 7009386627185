import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FC, useEffect } from 'react';

export interface ReadOnlyPluginProps {
    readonly: boolean;
}
const ReadOnlyPlugin: FC<ReadOnlyPluginProps> = (props: ReadOnlyPluginProps) => {
    const { readonly } = props;

    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.setEditable(!readonly);
    }, [readonly, editor]);
    return null;
};
export default ReadOnlyPlugin;
