import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { notifyTokenExpired, useHasTokenExpired } from '../../Redux/Reducers/System/reducer';
import { updateProfile } from '../../Redux/Reducers/User/actionChain';
import { userOrganizationSelector } from '../../Redux/Reducers/User/reducer';
import { useAppSelector } from '../../Redux/hook';
import { useNav } from '../../common/Hooks/useNav';
import { useOnWindowsActivated } from '../../common/Hooks/useWindowsActivation';
import { SimpleDialog } from '../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { AuthenticationService } from './AuthService';

export interface IDisconnectedPopupProps {
    authService: AuthenticationService;
}
export const DisconnectedPopup: FC<IDisconnectedPopupProps> = (props: IDisconnectedPopupProps) => {
    const { authService } = props;
    const organization = useAppSelector(userOrganizationSelector);
    const dispatch = useDispatch();
    const nav = useNav();
    const sessionClosed = useHasTokenExpired();

    useOnWindowsActivated(() => {
        console.log('Trigger Activate windows');
        if (authService.currentUser) {
            console.log('authService.currentUser Expired = ' + authService.currentUser.expired + ' in ' + authService.currentUser.expires_in);
            if (authService.currentUser.expired) {
                console.log('token expired');
                dispatch(notifyTokenExpired());
            }
        } else {
            console.log('authService.currentUser is NULL');
            console.log('token expired');
            dispatch(notifyTokenExpired());
        }
    });
    return (
        <>
            {sessionClosed ? (
                <SimpleDialog
                    id={'Disconnected'}
                    title={''}
                    disableDismiss
                    show={true}
                    closeButtonText='Se reconnecter'
                    onClose={() => {
                        updateProfile(dispatch, { profile: undefined, experienceRequiredToLevelUp: 0, organization: '' });
                        nav.navigateGlobal(`/logout/${organization}`);
                    }}
                >
                    <div className='Disconnected'>
                        <img src='/logo.svg' alt='Sakura coaching logo' tabIndex={-1} width={150} />
                        <span style={{ color: 'gray', fontSize: 20 }}>&#x1F62E;{' Oups, ta session a expiré.'}</span>
                        <span style={{ color: 'gray' }}>{'Pas de souci, tu peux te reconnecter en cliquant sur le bouton ci-dessous'}</span>
                    </div>
                </SimpleDialog>
            ) : (
                <></>
            )}
        </>
    );
};
