import { Toggle } from '@fluentui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { OsTypes, deviceType, isMobile, osName } from 'react-device-detect';
import { useNotificationSubscribed } from '../../Redux/Reducers/System/reducer';
import { WebPushSubscription } from '../../Services/SakuraApiClient';
import { useApiCall } from '../../common/Hooks/useApiCall';
import { isInAppMode } from '../../common/helpers/IsInAppMode';
import { subscribeForPushNotifications, unsubscribeFromPushNotifications } from '../../serviceWorker';

const appMode = isInAppMode();

export const PushNotificationSetting: FC = () => {
    const [notificationSub, setNotificationSub] = useState<WebPushSubscription[]>();
    const pushNotificationInfo = useNotificationSubscribed();

    useApiCall(
        useCallback(
            async (client, cancelToken) => {
                const subscription = await client.listMySubscription(cancelToken);
                setNotificationSub(subscription);
            },
            [setNotificationSub],
        ),
    );

    const deviceLabel = useMemo(() => {
        switch (deviceType) {
            case 'mobile':
                return 'ce téléphone';
        }
        return 'cet ordinateur';
    }, []);
    const enabledPushNofif = useMemo(() => {
        return (notificationSub ?? []).findIndex((i) => i.id === pushNotificationInfo.id) !== -1;
    }, [pushNotificationInfo.id, notificationSub]);
    return (
        <>
            {(isMobile && appMode) || isMobile === false ? (
                <Toggle
                    label={`Recevoir les notifications sur ${deviceLabel}`}
                    checked={enabledPushNofif}
                    onChange={(_, checked) => {
                        if (checked) {
                            subscribeForPushNotifications();
                        } else {
                            unsubscribeFromPushNotifications();
                        }
                    }}
                />
            ) : (
                <p>
                    <span>{"Pour bénéficier des notifications, tu dois utiliser le site Sakura Coaching en tant qu'application."}</span>
                    <br />
                    {osName === OsTypes.IOS ? (
                        <>
                            <span> {'Pour ce faire clique sur '}</span>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -10 50 50' fill='#0095FF' width={30}>
                                <path d='M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z' />
                                <path d='M24 7h2v21h-2z' />
                                <path d='M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z' />
                            </svg>

                            <span> {" puis 'Sur l'écran d'accueil'."}</span>
                        </>
                    ) : (
                        <></>
                    )}
                </p>
            )}
        </>
    );
};
