import { FC } from 'react';
import { TwitterShareButton } from 'react-share';
import { SocialNetworkLogo } from '../SocialNetworkLogo';
import { SharedButtonProps } from './type';

export const XSB: FC<SharedButtonProps> = (props: SharedButtonProps) => {
    const { url } = props;
    return url ? (
        <TwitterShareButton url={url}>
            <div className='x_share_b' title='Partager sur X'>
                <SocialNetworkLogo socialNetwork='x' size='small' />
                <span>{'Partager'}</span>
            </div>
        </TwitterShareButton>
    ) : null;
};
