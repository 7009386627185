import { FC, useCallback } from 'react';
import { IResourceData, IResourceDataPage, IResourceHierarchy, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ExecutionDataContextProvider } from '../../../Common/Page/ExecutionContext/ExecutionDataContextProvider';
import { IResourceInfo } from '../../../Common/Page/ExecutionContext/IExecutionContextState';
import { PageEditorStudio } from '../../../Common/PageEditor/PageEditorStudio';
import { ResourceHierarchyAction } from './FormResourceHierarchySlicer';
import { FormationEditDetails } from './FormationEditDetails';

export interface FormationContentViewProps {
    executionContextSerializationIdentifier: string | undefined;
    formationItem: IResourceHierarchy | undefined;
    innerResourcePackages: Record<number, IResourceInfo>;
    updateForm: (action: ResourceHierarchyAction) => void;
}
export const FormationContentView: FC<FormationContentViewProps> = (props: FormationContentViewProps) => {
    const { formationItem, updateForm, executionContextSerializationIdentifier, innerResourcePackages } = props;

    const onChangeData = useCallback(
        (newData: DeepPartial<IResourceDataPage>) => {
            if (formationItem && formationItem.resourcePath) {
                updateForm({
                    type: 'updateItemResourcePart',
                    payload: {
                        resourcePath: formationItem.resourcePath,
                        resource: {
                            data: {
                                content: {
                                    page: newData,
                                },
                            },
                        },
                    },
                });
            }
        },
        [formationItem, updateForm],
    );
    return formationItem ? (
        formationItem.resource.resourceType !== ResourceTypeEnum.Formation ? (
            <ExecutionDataContextProvider
                key={formationItem.resourcePath}
                pageResourceId={formationItem.resource.id}
                parentResourcePathId={formationItem.resourcePath}
                resources={innerResourcePackages}
                serializationIdentifier={executionContextSerializationIdentifier}
            >
                <PageEditorStudio
                    resourceData={formationItem.resource.data as IResourceData}
                    pageData={formationItem.resource.data?.content?.page as IResourceDataPage}
                    onChangePageData={onChangeData}
                    resourceAccountData={undefined} // TODO
                />
            </ExecutionDataContextProvider>
        ) : (
            <FormationEditDetails updateForm={updateForm} formationItem={formationItem} />
        )
    ) : null;
};
