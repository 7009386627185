import { ActionButton, Label, TextField } from '@fluentui/react';
import { CSSProperties, DragEventHandler, FC, MouseEventHandler, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Expression } from './Expression';

import clsx from 'clsx';
import { dynamicDataResolver } from '../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { declareVariables, useDynamicDataIntelisense } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import RichTextLexicalEditor, { EditorContent } from '../RichTextLexical/Editor';
import { ExpressionPluginProps } from '../RichTextLexical/Plugins/ExpressionPlugin';
import { ExpressionVariable } from './Expression/ExpressionVariable';
import './expressionField.scss';

export enum ExpressionKind {
    Condition,
    Assignment,
    VariableNameOnly,
    TextWithExpression,
}
export interface ExpressionFieldProps {
    label: string;
    value: string | undefined;
    kind: ExpressionKind;
    onChange: (value: string | undefined) => void;
    multilines?: boolean;
    maxVisibleLines?: number;
    minVisibleLines?: number;
    required?: boolean;
    errorMessage?: string;
    onDragStart?: DragEventHandler<HTMLDivElement> | undefined;
    onClick?: MouseEventHandler<HTMLDivElement> | undefined;
    onDrag?: DragEventHandler<HTMLDivElement> | undefined;
    className?: string;
    showExpandButton?: boolean;
    readonly?: boolean;
}

export const ExpressionField: FC<ExpressionFieldProps> = (props: ExpressionFieldProps) => {
    const { label, value, onChange, multilines, maxVisibleLines, minVisibleLines, required, errorMessage, className, onClick, onDrag, onDragStart, kind, showExpandButton, readonly } = props;
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [errMessage, setErrMessage] = useState<string | undefined>(errorMessage);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setErrMessage(errorMessage);
    }, [errorMessage]);

    const onChangeHandler = useCallback(
        (content: EditorContent) => {
            const text = content.getPlainText();
            if (kind !== ExpressionKind.TextWithExpression) {
                const error = Expression.validate(text, kind === ExpressionKind.Assignment);
                setErrMessage(error);
            } else {
                const error = Expression.validateAllExpressionInBrackets(text);
                setErrMessage(error);
            }

            if (kind === ExpressionKind.TextWithExpression) {
                const expressions = Expression.getExpressionInBrackets(text);
                const variables: ExpressionVariable[] = [];
                expressions.forEach((exp) => {
                    variables.push(...exp.getListOfVariableUsed());
                });
                if (variables.length > 0) {
                    dispatch(declareVariables({ variables }));
                }
            } else {
                if (!errorMessage) {
                    const expression = new Expression(text, dynamicDataResolver);
                    const variables = expression.getListOfVariableUsed();
                    dispatch(declareVariables({ variables }));
                }
            }
            // if (!errorMessage) {
            //     const expression = new Expression(text, dynamicDataResolver());
            //     const variables = expression.getListOfVariableUsed();
            //     dispatch(declareVariables({ variables }));
            // }
            onChange(text);
        },
        [dispatch, errorMessage, onChange, kind],
    );
    const intelisense = useDynamicDataIntelisense();
    const isSingleLine = useMemo(() => {
        if (showEditDialog) {
            return false;
        }
        return !multilines;
    }, [showEditDialog, multilines]);

    const divStyle = useMemo(() => {
        if (maxVisibleLines || !isSingleLine) {
            return {
                overflowY: 'auto',
                maxHeight: maxVisibleLines ? maxVisibleLines * 28 : undefined,
                minHeight: minVisibleLines ? minVisibleLines * 28 : 28,
            } as CSSProperties | undefined;
        }
        return {
            minHeight: 28,
            maxHeight: 28,
        } as CSSProperties;
    }, [maxVisibleLines, minVisibleLines, isSingleLine]);

    const divRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const parentDiv = divRef.current?.getElementsByClassName('lexical-editor-root');
        if (parentDiv && parentDiv.length > 0) {
            if (parentDiv[0].children.length > 0) {
                parentDiv[0].children[0].setAttribute('style', `overflow-y: auto;${divStyle?.maxHeight ? `max-height: ${divStyle?.maxHeight}px;` : ''}min-height: ${divStyle?.minHeight}px;`);
            }
        }
    }, [divStyle]);
    const expressionOptions = useMemo<ExpressionPluginProps>(() => {
        return {
            intelisenseDataSource: intelisense,
            disableReadOnlyData: kind === ExpressionKind.VariableNameOnly,
            enabledBracketExpression: kind === ExpressionKind.TextWithExpression,
        };
    }, [kind, intelisense]);
    return (
        <>
            <div
                className={clsx(
                    'expressionField',
                    className,

                    errorMessage ? 'invalid' : undefined,
                    !isSingleLine ? '' : 'singleLine',
                    showEditDialog ? 'fullscreen sakuraDialog' : '',
                )}
                onClick={onClick}
                onDrag={onDrag}
                onDragStart={onDragStart}
            >
                <div className='headerExpressionField'>
                    <Label>{label}</Label>
                    {required ? <Label style={{ color: 'rgb(164, 38, 44)', paddingLeft: 4, paddingRight: 12 }}>{'*'}</Label> : null}
                </div>
                <div className={clsx('bodyExpressionField', readonly ? 'ro' : '')}>
                    <div className={clsx('contentExpressionField', readonly ? 'ro' : '')} ref={divRef}>
                        <RichTextLexicalEditor
                            showTabBar={false}
                            readonly={readonly}
                            content={value}
                            onChange={onChangeHandler}
                            expressionOptions={expressionOptions}

                            //  singleLine={isSingleLine}
                        />
                    </div>
                    {showExpandButton ? (
                        <>
                            {' '}
                            {showEditDialog ? (
                                <ActionButton
                                    iconProps={{ iconName: 'ChromeClose' }}
                                    onClick={() => {
                                        setShowEditDialog(false);
                                    }}
                                />
                            ) : (
                                <ActionButton
                                    iconProps={{ iconName: 'windowEdit' }}
                                    onClick={() => {
                                        setShowEditDialog(true);
                                    }}
                                />
                            )}
                        </>
                    ) : null}
                </div>

                {errMessage ? <span>{errMessage}</span> : null}
            </div>
            {showExpandButton && showEditDialog ? <TextField label={label} disabled value='edition plein ecran' /> : null}
        </>
    );
};
