import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_HIGH, createCommand, KEY_DOWN_COMMAND, LexicalEditor, TextNode } from 'lexical';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Intelisense } from '../../../../../Redux/Reducers/DynamiqueData/state';

import { IntelisenseData, IntelisenseEntry, IntelisensePopup } from './IntelisensePopup';
import { checkInsideFunction, ExpressionText, filterEntries, FunctionInfo, getTextExpression, getTextToInsert, getTriggerRange, TriggerRange } from './utils';

// Commande personnalisée pour afficher le menu de suggestions
const SHOW_SUGGESTION_MENU_COMMAND = createCommand();
const HIDE_SUGGESTION_MENU_COMMAND = createCommand();

export interface ExpressionPluginProps {
    intelisenseDataSource: Intelisense;
    disableReadOnlyData?: boolean;
    enabledBracketExpression?: boolean;
}
interface MenuCommandData {
    searchText: string;
    triggerRange: TriggerRange;
    functionInfo: FunctionInfo;
}

const ExpressionPlugin: FC<ExpressionPluginProps> = (props: ExpressionPluginProps) => {
    const { intelisenseDataSource, disableReadOnlyData, enabledBracketExpression } = props;
    const [editor] = useLexicalComposerContext();

    const menuRef = useRef<HTMLDivElement>(null); // Ref pour le menu
    const [intelisenseData, setIntelisenseData] = useState<IntelisenseData>();

    const intelisenseEntries = useMemo(() => {
        if (intelisenseDataSource) {
            const result = filterEntries(intelisenseDataSource, intelisenseData?.searchText);

            if (result.results) {
                if (result.localFilter) {
                    const filtredResult = Object.entries(result.results)
                        .filter((e) => e[0].startsWith(result.localFilter ?? '') && (!disableReadOnlyData || e[1].type === 'var'))
                        .map((i) => {
                            return { name: i[0], info: i[1] };
                        });
                    if (filtredResult.length === 1) {
                        if (filtredResult[0].name === result.localFilter) {
                            return []; //When perfect match return empty list
                        }
                    }
                    return filtredResult;
                } else {
                    return Object.entries(result.results)
                        .filter((e) => !disableReadOnlyData || e[1].type === 'var')
                        .map((i) => {
                            return { name: i[0], info: i[1] };
                        });
                }
            }
        }
        return undefined;
    }, [intelisenseDataSource, intelisenseData?.searchText, disableReadOnlyData]);
    const onSelect = useCallback(
        (data: IntelisenseData, entry: IntelisenseEntry) => {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    const { text, final } = getTextToInsert(data, entry);
                    selection.insertText(text);
                    if (final) {
                        editor.dispatchCommand(HIDE_SUGGESTION_MENU_COMMAND, {});
                    }
                }
            });
        },
        [editor],
    );

    const onChangeTriggerIntelisense = useCallback(
        (editor: LexicalEditor, expression: ExpressionText, shouldTrigger: boolean) => {
            if (intelisenseDataSource) {
                const func = checkInsideFunction(expression, intelisenseDataSource);
                if (func) {
                    editor.dispatchCommand(SHOW_SUGGESTION_MENU_COMMAND, { searchText: func.triggerRange.text, triggerRange: func.triggerRange, functionInfo: func });
                    return;
                }
                const triggerRange = getTriggerRange(expression, shouldTrigger);

                if (triggerRange) {
                    editor.dispatchCommand(SHOW_SUGGESTION_MENU_COMMAND, { searchText: triggerRange.text, triggerRange });
                    return;
                }

                if (!triggerRange) {
                    editor.dispatchCommand(HIDE_SUGGESTION_MENU_COMMAND, {});
                }
            } else if (intelisenseData) {
                editor.dispatchCommand(HIDE_SUGGESTION_MENU_COMMAND, {});
            }
        },
        [intelisenseDataSource, intelisenseData],
    );

    useEffect(() => {
        if (enabledBracketExpression) {
            return editor.registerCommand(
                KEY_DOWN_COMMAND,
                (event) => {
                    if (event.ctrlKey && event.key === 'e') {
                        event.preventDefault();
                        editor.update(() => {
                            const selection = $getSelection();
                            if ($isRangeSelection(selection)) {
                                const expression = getTextExpression(selection);
                                if (expression === null || expression.inBracket === false) {
                                    const textNode = new TextNode('{{}}');
                                    selection.insertNodes([textNode]);

                                    // Déplace le curseur entre les accolades ouvrantes et fermantes
                                    selection.setTextNodeRange(textNode, 2, textNode, 2); // Place le curseur après '{{'
                                }
                            }
                        });
                        return true;
                    }
                    return false;
                },
                COMMAND_PRIORITY_HIGH,
            );
        }
        return () => {};
    }, [editor, enabledBracketExpression]);

    useEffect(() => {
        // Enregistre un listener sur les changements de l'état de l'éditeur
        const unsubscribe = editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                // Accédez ici aux informations de l'EditorState
                const selection = $getSelection();

                if ($isRangeSelection(selection) && intelisenseData) {
                    const expression = getTextExpression(selection);
                    if (expression) {
                        onChangeTriggerIntelisense(editor, expression, true);
                    }
                    //editor.dispatchCommand(SHOW_SUGGESTION_MENU_COMMAND, {});
                }
            });
        });

        // Nettoyez le listener lorsque le composant est démonté
        return () => {
            unsubscribe();
        };
    }, [editor, intelisenseData, onChangeTriggerIntelisense]);

    useEffect(() => {
        if (intelisenseData) {
            return editor.registerCommand(
                KEY_DOWN_COMMAND,
                (event) => {
                    let managedEvent = false;
                    if (intelisenseData && intelisenseEntries) {
                        switch (event.key) {
                            case 'Enter':
                            case 'Tab':
                                managedEvent = true;

                                if (intelisenseData.selectedIndex !== undefined && intelisenseData.selectedIndex >= 0 && intelisenseData.selectedIndex < intelisenseEntries.length) {
                                    onSelect(intelisenseData, intelisenseEntries[intelisenseData.selectedIndex]);
                                }
                                break;
                            case '(':
                            case '.':
                                if (intelisenseData.selectedIndex !== undefined && intelisenseData.selectedIndex >= 0 && intelisenseData.selectedIndex < intelisenseEntries.length) {
                                    managedEvent = true;
                                    onSelect(intelisenseData, intelisenseEntries[intelisenseData.selectedIndex]);
                                }
                                break;
                            case ')':
                                setIntelisenseData(undefined);
                                break;
                            case 'Escape':
                                managedEvent = true;
                                setIntelisenseData(undefined);
                                break;
                            // case 'ArrowLeft':
                            // case 'ArrowRight':
                            //     managedEvent = true;
                            //     break;
                            case 'ArrowDown':
                                managedEvent = true;
                                if (intelisenseData && intelisenseEntries && intelisenseEntries.length > 0) {
                                    const max = intelisenseEntries.length;
                                    let selectedIndex = (intelisenseData?.selectedIndex ?? 0) + 1;
                                    if (selectedIndex > max - 1) {
                                        selectedIndex = max - 1;
                                    }
                                    setIntelisenseData({ ...intelisenseData, selectedIndex });
                                }
                                break;
                            case 'ArrowUp':
                                managedEvent = true;
                                if (intelisenseData && intelisenseEntries && intelisenseEntries.length > 0) {
                                    let selectedIndex = (intelisenseData?.selectedIndex ?? 0) - 1;
                                    if (selectedIndex < 0) {
                                        selectedIndex = 0;
                                    }
                                    setIntelisenseData({ ...intelisenseData, selectedIndex });
                                }
                                break;
                        }
                    }
                    // editor.read(() => {
                    //     const selection = $getSelection();
                    //     if ($isRangeSelection(selection)) {
                    //         if (checkInsideExpressionBracket(selection)) {
                    //             const keysToBlock = ['Tab', 'Enter'];
                    //             if (keysToBlock.includes(event.key)) {
                    //                 event.preventDefault();
                    //                 managedEvent = true;
                    //             }
                    //         }
                    //     }
                    // });
                    if (managedEvent) {
                        event.preventDefault();
                    }
                    return managedEvent;
                },
                COMMAND_PRIORITY_HIGH,
            );
        }
        return () => {};
    }, [editor, enabledBracketExpression, intelisenseData, intelisenseEntries, onSelect]);
    // Capture de Ctrl+Space pour afficher le menu de suggestions
    useEffect(() => {
        return editor.registerCommand(
            KEY_DOWN_COMMAND,
            (event) => {
                if (event.ctrlKey && event.key === ' ') {
                    editor.read(() => {
                        const selection = $getSelection();

                        if ($isRangeSelection(selection)) {
                            const expression = getTextExpression(selection);
                            if ((expression && !enabledBracketExpression) || expression?.inBracket) {
                                event.preventDefault();

                                onChangeTriggerIntelisense(editor, expression, true);
                            }
                        }
                    });
                    return true;
                }
                return false;
            },
            COMMAND_PRIORITY_HIGH,
        );
    }, [editor, onChangeTriggerIntelisense, enabledBracketExpression]);

    // Gestion du menu de suggestions en fonction de la commande
    useEffect(() => {
        return editor.registerCommand<MenuCommandData>(
            SHOW_SUGGESTION_MENU_COMMAND,
            (data: MenuCommandData) => {
                const domSelection = window.getSelection();
                if (domSelection && domSelection.rangeCount > 0) {
                    const range = domSelection.getRangeAt(0);
                    let rect;
                    if (range.startOffset === 0) {
                        rect = (range.startContainer as unknown as Range).getBoundingClientRect();
                    } else {
                        rect = range.getBoundingClientRect();
                    }
                    setIntelisenseData({
                        position: {
                            top: rect.bottom + window.scrollY,
                            left: rect.left + window.scrollX,
                        },
                        searchText: data.searchText,
                        triggerRange: data.triggerRange,
                        selectedIndex: 0,
                        functionInfo: data.functionInfo?.info,
                    });
                }
                return true;
            },
            COMMAND_PRIORITY_HIGH,
        );
    }, [editor]);

    useEffect(() => {
        return editor.registerCommand(
            HIDE_SUGGESTION_MENU_COMMAND,
            () => {
                setIntelisenseData(undefined);
                return true;
            },
            COMMAND_PRIORITY_HIGH,
        );
    }, [editor]);

    // Fermeture du menu lors d'un clic en dehors
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (event.target && menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIntelisenseData(undefined);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onLostFocus = useCallback(() => {
        setIntelisenseData(undefined);
    }, []);
    useEffect(() => {
        if (!intelisenseEntries || (intelisenseEntries.length === 0 && !intelisenseData?.functionInfo)) {
            setIntelisenseData(undefined);
        }
    }, [intelisenseEntries, intelisenseData]);
    return <>{intelisenseData && intelisenseEntries ? <IntelisensePopup dataSource={intelisenseEntries} intelisenseData={intelisenseData} onSelect={onSelect} onLostFocus={onLostFocus} /> : null}</>;
};

export default ExpressionPlugin;
