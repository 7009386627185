import { FC, useLayoutEffect, useMemo, useRef } from 'react';

import './AnimatedCounter.scss';

export interface ValueChange {
    total: number;
    variation: number;
}
export interface AnimatedCounterProps {
    value: number | undefined;
    valueChange: ValueChange | undefined;
    onChangeValue: (value: number) => void;
    charMax: number;
    duration: number;
}
export const AnimatedCounter: FC<AnimatedCounterProps> = (props: AnimatedCounterProps) => {
    const { duration, value, valueChange, charMax, onChangeValue } = props;

    const spanValueRef = useRef<HTMLSpanElement>(null);
    useLayoutEffect(() => {
        let timeout: NodeJS.Timeout | string | number | undefined = undefined;
        let interval: NodeJS.Timeout | string | number | undefined = undefined;
        if (valueChange && spanValueRef.current) {
            timeout = setTimeout(() => {
                const sign = valueChange.variation < 0 ? -1 : 1;
                let incBy = 0;

                const totalSteps = Math.abs(valueChange.variation); // Nombre total de paliers
                const minStepTime = 10; // StepTime minimum en millisecondes

                // Calcul de l'incrémentation et du stepTime
                let stepTime = duration / totalSteps;
                let increment = 1; // Valeur d'incrémentation par défaut

                // Si le stepTime est inférieur à 1 ms, on ajuste l'incrémentation
                if (stepTime < minStepTime) {
                    stepTime = minStepTime;
                    increment = Math.ceil(totalSteps / (duration / minStepTime)); // Ajuster l'incrémentation
                }

                interval = setInterval(() => {
                    incBy += increment;
                    if (incBy <= valueChange.variation) {
                        const val = (value ?? 0) + incBy * sign;
                        if (spanValueRef.current) {
                            spanValueRef.current.innerText = val?.toString();
                        }
                    } else {
                        clearInterval(interval);
                        onChangeValue(valueChange.total);
                    }
                }, stepTime);
            }, 2000);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [valueChange, duration, onChangeValue, value]);
    const width = useMemo(() => {
        return charMax * 8.5;
    }, [charMax]);
    const variationSign = useMemo(() => {
        if (valueChange) {
            if (valueChange.variation > 0) {
                return '+';
            } else if (valueChange.variation < 0) {
                return '-';
            }
        }
        return '';
    }, [valueChange]);

    return (
        <div className={'animatedCounter'} style={{ width }}>
            <span ref={spanValueRef}>{value}</span>
            {valueChange ? (
                <div className='variation'>
                    <span>{`${variationSign}${valueChange.variation}`}</span>
                </div>
            ) : null}
        </div>
    );
};
