import { useMemo } from 'react';

export type FormationContextAction =
    | { type: 'selectFormationItem'; payload: { resourcePath: string } }
    | { type: 'movePrev' }
    | { type: 'moveNext' }
    | { type: 'completeItem'; payload: { resourcePath: string | undefined } }
    | { type: 'expandItem'; payload: { resourcePath: string; isExpanded: boolean } }
    | { type: 'clearServerSyncActions' };

export interface FormationActions {
    expandMenuItem: (resourcePath: string, isExpanded: boolean) => void;
    completeItem: (resourcePath: string) => void;
    completeCurrentItem: () => void;
    navigateTo: (resourcePath: string) => void;
    goBack: () => void;
    goNext: () => void;
    clearServerSyncActions: () => void;
}
export const defaultAction: FormationActions = {
    expandMenuItem: () => {
        /**/
    },
    navigateTo: () => {
        /**/
    },
    goBack: () => {
        /**/
    },
    goNext: () => {
        /**/
    },
    completeItem: () => {
        /**/
    },
    completeCurrentItem: () => {
        /**/
    },
    clearServerSyncActions: () => {
        /**/
    },
};
export function useActions(dispatch: React.Dispatch<FormationContextAction>) {
    return useMemo((): FormationActions => {
        return {
            expandMenuItem: (resourcePath, isExpanded) => {
                dispatch({ type: 'expandItem', payload: { resourcePath, isExpanded } });
            },
            navigateTo: (resourcePath) => {
                dispatch({ type: 'selectFormationItem', payload: { resourcePath } });
            },
            goBack: () => {
                dispatch({ type: 'movePrev' });
            },
            goNext: () => {
                dispatch({ type: 'moveNext' });
            },
            completeItem: (resourcePath: string) => {
                dispatch({ type: 'completeItem', payload: { resourcePath } });
            },
            completeCurrentItem: () => {
                dispatch({ type: 'completeItem', payload: { resourcePath: undefined } });
            },
            clearServerSyncActions: () => {
                dispatch({ type: 'clearServerSyncActions' });
            },
        };
    }, [dispatch]);
}
