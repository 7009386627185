export interface ClientApiConfig {
    none?: string;
}

export class BaseSakuraApiClient {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(configuration: ClientApiConfig) {
        //
    }
    public getBaseUrl(url: string) {
        return url;
    }
}
