import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { IProtectedValueInfo, OfferMarketingInfo } from '../Services/SakuraApiClient';
import { useApiCall } from '../common/Hooks/useApiCall';
import { ClickablePicture } from '../common/components/ClickablePicture/ClickablePicture';

import { getCurrentYear } from '../common/helpers/DateTimeHelper';
import './ViewMarketingOffer.scss';

export interface MarketingOffer {
    showCurrentOffers?: IProtectedValueInfo;
    offerId1?: IProtectedValueInfo;
    offerId2?: IProtectedValueInfo;
}
export interface ViewMarketingOfferProps {
    offer: MarketingOffer;
    enabled: boolean;
}

export const ViewMarketingOffer: FC<PropsWithChildren<ViewMarketingOfferProps>> = (props: PropsWithChildren<ViewMarketingOfferProps>) => {
    const { children, offer, enabled } = props;
    const [offers, setOffers] = useState<OfferMarketingInfo[]>();

    useApiCall(
        useCallback(
            async (client) => {
                if (offer) {
                    const values = await client.getMarkingOffers(offer.showCurrentOffers?.protected, offer.offerId1?.protected, offer.offerId2?.protected);
                    setOffers(values);
                }
            },
            [offer],
        ),
        { anonymous: true },
    );

    return (
        <div className='ViewMarketingOffer'>
            <div className='backgroundDiv'>
                <div>
                    <div className='content'>
                        <div className='pictureContainer'>
                            <div className='picture'>
                                <img src='/images/sakura_1.svg' alt='Sakura coaching' tabIndex={-1} />
                            </div>
                        </div>
                    </div>
                    <div className='footer'>
                        <span>Copyright © {getCurrentYear()} Sakura-Coaching. Tous droits réservés.</span>
                    </div>
                </div>
            </div>
            <div className='centerPart'>
                <div>
                    {offers ? (
                        <>
                            {children}
                            {offers.map((o, index) => {
                                return <ClickablePicture key={`offer${index}`} src={o.imageUrl} href={o.link} className='commercial' />;
                            })}
                        </>
                    ) : (
                        <></>
                    )}

                    {!enabled && <span className='spanError'>😥 {"Désolé mais ce document n'est plus disponible."}</span>}
                </div>
            </div>
        </div>
    );
};
