import { Pivot, PivotItem } from '@fluentui/react';
import { FC } from 'react';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { RoleEnum } from '../../../../Services/SakuraApiClient';

import { PageSetting } from './menuSetting';
import { PageSettingEditVariation } from './PageSettingEditVariation';

export interface PageSettingEditProps {
    data: DeepPartial<PageSetting>;
    onChange: (data: DeepPartial<PageSetting>) => void;
}

const Roles = [RoleEnum.Prospect, RoleEnum.Member, RoleEnum.PremiumMember, RoleEnum.OrganisationAdmin, RoleEnum.Admin];
export const PageSettingEdit: FC<PageSettingEditProps> = (props: PageSettingEditProps) => {
    const { data, onChange } = props;

    return (
        <div className='PageSettingEdit'>
            <div className='default'>
                <ResourceLookupField
                    label='Page par défaut'
                    selectedResourceId={data?.defaultPageId}
                    onSelectResource={(resource) => onChange({ defaultPageId: resource?.id })}
                    removeItemCreate
                    type={'ResourcePageAutreLookup'}
                />
            </div>
            <FormGroup title='Surcharge par rôle et période'>
                <Pivot aria-label=''>
                    {Roles.map((role) => (
                        <PivotItem key={role} headerText={role}>
                            <PageSettingEditVariation
                                data={(data?.pages && data?.pages[role]) ?? []}
                                onChange={(data) => {
                                    onChange({
                                        pages: {
                                            [role]: data,
                                        },
                                    });
                                }}
                            />
                        </PivotItem>
                    ))}
                </Pivot>
            </FormGroup>
        </div>
    );
};
