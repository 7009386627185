import { ActionButton, Icon } from '@fluentui/react';
import clsx from 'clsx';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import './Panel.scss';

import { DefaultCommandButton } from '../OverflowCommands/DefaultCommandButton';
import { OverflowCommands } from '../OverflowCommands/OverflowCommands';

export interface PanelCommand {
    text?: string;
    icon?: string;
    disabled?: boolean;
    onClick: () => void;
}
export interface PanelProps {
    icon?: string;
    title: string;
    footer?: ReactNode | undefined;
    removeBottomPadding?: boolean;
    noPadding?: boolean;
    disabled?: boolean;
    commands?: PanelCommand[];
    backButtonCommand?: () => void;
    isContentModified?: boolean;
    revertContentModification?: () => void;
    leftHeader?: React.ReactNode;
}
const backButtonStyle = {
    icon: {
        // color: palette['white'],
        fontSize: 30,
    },
    iconHovered: {
        //color: palette['neutralLight'],
        fontSize: 30,
    },
};
export const Panel: FC<PropsWithChildren<PanelProps>> = (props: PropsWithChildren<PanelProps>) => {
    const { title, icon, footer, children, removeBottomPadding, noPadding, disabled, commands, backButtonCommand, revertContentModification, isContentModified, leftHeader } = props;

    return (
        <div className='SakuraPanel'>
            <div className={clsx('SakuraPanelHeader', disabled ? 'disabled' : '')}>
                {backButtonCommand && (
                    <div className='back'>
                        <ActionButton styles={backButtonStyle} iconProps={{ iconName: 'NavigateBack' }} onClick={backButtonCommand} />
                    </div>
                )}
                <div className='content'>
                    {icon && <Icon iconName={icon} />}
                    <span>{title}</span>
                    {isContentModified ? (
                        <DefaultCommandButton
                            key='revert'
                            className='iconModification'
                            menuIcon='StarburstSolid'
                            title='Annuler les modifications'
                            onClick={revertContentModification ? () => revertContentModification() : undefined}
                        />
                    ) : (
                        <> </>
                    )}
                    {leftHeader}
                    <div className='SakuraPanelHeaderCommand'>
                        {commands && !disabled && (
                            <OverflowCommands
                                commandButtons={(commands ?? []).map((c) => ({
                                    ...c,
                                    key: `${c.icon ?? ''}${c.text ?? ''}`,
                                }))}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className={clsx(noPadding ? 'noPadding' : '', removeBottomPadding ? 'noBottomPadding' : '', 'SakuraPanelContent', disabled ? 'disabled' : '')}>{children}</div>
            {footer && <div className='SakuraPanelFooter'>{footer}</div>}
        </div>
    );
};
