import { DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import axios from 'axios';
import { useCallback, useEffect, useMemo } from 'react';
import { FilterChange } from '../../Redux/Reducers/DataSet/ApiCall/apiCall';
import { DataSetName, datasetCollections } from '../../Redux/Reducers/DataSet/DataSetName';
import { useDataSetSelector } from '../../Redux/Reducers/DataSet/reducer';
import { useGridSelection } from '../../common/Hooks/useGridSelection';
import DetailsList, { FilteredColumn } from '../../common/components/CustomDetailList/CustomDetailList';
import { ColumnInfo, FilterValue } from '../../common/components/CustomDetailList/FilteredHeaderColumn';

export interface DatasetGridProps<T> {
    datasetName: DataSetName;
    columns: ColumnInfo[];
    contextId?: string | undefined;
    defaultCriteria?: FilterChange | undefined;
    parameters?: Record<string, FilterValue> | undefined;
    getKey?: (resource: T) => string | number;
    onSelect?: (resource: T | undefined) => void;
    onRowClick?: (item: T) => void;
    onRowDoubleClick?: (item: T) => void;
    onRenderSubPanel?: (item: T) => JSX.Element;
    rowClassname?: string;
}
const labels = { resultPerPage: 'Résultats par page', totalRecord: 'Total' };
function getFiltreChange(filteredColumn: FilteredColumn[]): Record<string, FilterValue> {
    const filter: Record<string, FilterValue> = {};
    filteredColumn.forEach((f) => {
        if (f.criteria.length === 1) {
            filter[f.fieldName] = f.criteria[0].filterValue;
        } else {
            f.criteria.forEach((value, index) => {
                filter[`${f.fieldName}_${index}`] = value.filterValue;
            });
        }
    });
    return filter;
}
export const DatasetGrid = <T extends object>(props: DatasetGridProps<T>) => {
    const { onSelect, columns, datasetName, getKey, defaultCriteria, onRowClick, onRowDoubleClick, contextId, parameters, onRenderSubPanel, rowClassname } = props;
    const dataset = useDataSetSelector(datasetName);

    useEffect(() => {
        const source = axios.CancelToken.source();

        datasetCollections[datasetName].init(contextId ?? 'default', defaultCriteria, parameters, source.token);
        return () => {
            console.log('throw cancel api call for dataset');
            source.cancel();
        };
    }, [defaultCriteria, contextId, parameters, datasetName]);

    const selection = useGridSelection(getKey, onSelect);
    const canShow = useMemo(() => {
        return defaultCriteria ? datasetCollections[datasetName].canShowData(dataset.filters, defaultCriteria) : true;
    }, [dataset.filters, datasetName, defaultCriteria]);

    const paginationSetting = useMemo(
        () => ({
            nbItemPerPage: dataset.pageSize,
            nbPageShown: 5,
            currentPage: dataset.pageIndex,
            totalPage: dataset.totalPages,
        }),
        [dataset.pageIndex, dataset.pageSize, dataset.totalPages],
    );
    const onFilterChangedHandler = useCallback(
        (filteredColumn: FilteredColumn[]) => {
            const filtre = getFiltreChange(filteredColumn);
            datasetCollections[datasetName].update({}, { mode: 'Full', change: filtre });
            return true;
        },
        [datasetName],
    );
    const onPageChangedHandler = useCallback(
        (pageIndex: number) => {
            datasetCollections[datasetName].update({ pageIndex });
            return true;
        },
        [datasetName],
    );
    const onNbItemPerPageChangedHandler = useCallback(
        (pageSize: number) => {
            datasetCollections[datasetName].update({ pageSize, pageIndex: 0 });
            return true;
        },
        [datasetName],
    );
    return (
        <DetailsList
            columns={columns}
            noItemsPlaceholder={<></>}
            labels={labels}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            selectionMode={getKey ? SelectionMode.single : SelectionMode.none}
            selection={selection?.current}
            selectionPreservedOnEmptyClick
            items={canShow ? dataset.data : []}
            totalItemCount={dataset.totalCount}
            filterValues={dataset.filters}
            onRowClick={onRowClick}
            onRowDoubleClick={onRowDoubleClick}
            onRenderSubPanel={onRenderSubPanel}
            rowClassname={rowClassname}
            onFilterChanged={onFilterChangedHandler}
            onPageChanged={onPageChangedHandler}
            onNbItemPerPageChanged={onNbItemPerPageChangedHandler}
            paginationSetting={paginationSetting}
        />
    );
};
