import { CSSProperties, FC, ReactEventHandler, useEffect, useState } from 'react';

export interface ImageViewerProps {
    width?: string;
    height?: string;
    url?: string;
    file?: File;
    style?: CSSProperties | undefined;
    className?: string;
    onLoad?: ReactEventHandler<HTMLImageElement>;
}

export const ImageViewer: FC<ImageViewerProps> = (props: ImageViewerProps) => {
    const { width, height, url, file, style, className, onLoad } = props;
    const [imgUrl, setImgUrl] = useState<string | undefined>();

    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (ev) => {
                setImgUrl(ev?.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, [file]);
    return <img onLoad={onLoad} alt='_' src={imgUrl ?? url} className={className} style={{ width, height, ...style }} />;
};
