import { FC } from 'react';
import { NavMenu } from '../../../common/components/NavMenu';
import { useFormationContextActions, useFormationContextSelector } from './FormationContext/FormationContextSelector';
import { FormationNavigationMenuItemState } from './FormationNavigationMenuItemState';

export interface FormationNavigationMenuProps {
    onDoubleClickItem?: (id: string | undefined) => void;
}
export const FormationNavigationMenu: FC<FormationNavigationMenuProps> = (props: FormationNavigationMenuProps) => {
    const selectedContentPath = useFormationContextSelector((context) => context.selectedContentPath);
    const menu = useFormationContextSelector((context) => context.menu);

    const formationContextActions = useFormationContextActions();
    const { onDoubleClickItem } = props;

    return (
        <>
            <NavMenu
                selectedItemId={selectedContentPath}
                items={menu}
                customPart={(_, data) => {
                    return <FormationNavigationMenuItemState resourcePath={data.resourcePath!} />;
                }}
                onExpanded={(_, data, isExpanded) => {
                    formationContextActions.expandMenuItem(data.resourcePath!, isExpanded);
                }}
                onSelectedItem={(id) => {
                    if (id) {
                        formationContextActions.navigateTo(id);
                    }
                }}
                onDoubleClickItem={onDoubleClickItem}
            />
        </>
    );
};
