import { FC, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResourceConsumerPackage } from '../Services/SakuraApiClient';
import { INavigationState } from '../common/Hooks/useNav';
import { InvalidResource } from './InvalidResource';
import { ResourcePage } from './ResourcePage';

export const ResourceRouteView: FC = () => {
    const location = useLocation();
    const entityFromNavState = location?.state as INavigationState<IResourceConsumerPackage>;

    const { id } = useParams();
    const resourceId = useMemo(() => {
        if (id !== undefined) {
            const idNumber: number = Number.parseInt(id, 10);

            return idNumber;
        }
        return undefined;
    }, [id]);
    return resourceId ? <ResourcePage resourceConsumerPackage={entityFromNavState?.state} fallback={<InvalidResource />} resourceId={resourceId} /> : <></>;
};
