import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { FC, useEffect, useRef } from 'react';
import { loadEditorState } from '../../Helpers/EditorLoader';

export interface InitializerPluginProps {
    content: string | undefined;
    jsonState: SerializedEditorState<SerializedLexicalNode> | undefined;
    OnTime?: boolean;
}
const InitializerPlugin: FC<InitializerPluginProps> = (props: InitializerPluginProps) => {
    const { jsonState, content, OnTime } = props;
    const [editor] = useLexicalComposerContext();
    const initialized = useRef<boolean>(false);
    useEffect(() => {
        if (!OnTime || initialized.current === false) {
            loadEditorState(editor, content, jsonState);
        }
        initialized.current = true;
    }, [jsonState, content, editor, OnTime]);

    return null;
};

export default InitializerPlugin;
