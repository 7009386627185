import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useContext, useMemo } from 'react';
import { useAnimationState, visiblityAnimatedStatetoBoolean } from '../../../common/Hooks/useAnimationState';
import { IResourceHierarchyView } from '../../../Services/SakuraApiClient';
import { FormationDataContext } from './FormationContext/FormationContextProvider';
import { FormationHeader } from './FormationHeader';
import { FormationNavigationMenu } from './FormationNavigationMenu';
import { resourceHierarchyViewItemToMenuEntry } from './helper';

export interface FormationNavigationMobileProps {
    formationHierarchyView: IResourceHierarchyView;
}
export const FormationNavigationMobile: FC<FormationNavigationMobileProps> = (props: FormationNavigationMobileProps) => {
    const { formationHierarchyView } = props;
    const [menuOpenState, setIsMenuOpen] = useAnimationState(300, false);
    const formationContext = useContext(FormationDataContext);

    const currentItem = useMemo(() => {
        if (!((formationContext.selectedContent?.children?.length ?? 0) > 0)) {
            setIsMenuOpen(false);
        }
        return formationContext.selectedContent ? resourceHierarchyViewItemToMenuEntry(formationContext.selectedContent) : undefined;
    }, [formationContext.selectedContent, setIsMenuOpen]);
    return (
        <div className={clsx('tocMobile', menuOpenState)}>
            <div
                className='header'
                onClick={() => {
                    setIsMenuOpen(!visiblityAnimatedStatetoBoolean(menuOpenState));
                }}
            >
                <Icon iconName='CollapseMenu' />
                <div className='CollapseMenu' />
                <Icon iconName={currentItem?.icon} />
                <span>{currentItem?.name}</span>
            </div>
            <div className={'menu'}>
                <FormationHeader formationHierarchyView={formationHierarchyView} isMobile />
                <FormationNavigationMenu
                    onDoubleClickItem={() => {
                        setIsMenuOpen(false);
                    }}
                />
            </div>
        </div>
    );
};
