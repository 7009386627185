import { INavMenuEntry } from '../../../../common/components/NavMenu';
import { IAccountDataResource, IResourceHierarchyView, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { getParentPath } from '../helper';
import { IFormationContextState } from './IFormationContextState';

interface ItemUserData {
    resourcePath: string;
    resourceType: ResourceTypeEnum;
    childrenCount: number;
    data: IAccountDataResource | undefined;
}
export interface FormationUserData {
    userDataIndex: Record<string, number>; //resourcePath to index
    userDatas: ItemUserData[];
}
export function buildFormationUserData(userData: Record<string, IAccountDataResource | undefined>, content: Record<string, IResourceHierarchyView>): FormationUserData {
    const userDatas: ItemUserData[] = [];
    const userDataIndex: Record<string, number> = {};
    const keys = Object.keys(userData);
    for (let i = 0; i < keys.length; i++) {
        userDataIndex[keys[i]] = i;
        const resource = content[keys[i]];
        userDatas.push({ resourcePath: keys[i], data: userData[keys[i]], childrenCount: resource.children?.length ?? 0, resourceType: resource.resource.resourceType });
    }
    return { userDataIndex, userDatas };
}

export function updateMenuItem(
    menu: INavMenuEntry<IResourceHierarchyView>[],
    resourcePath: string,
    update: (item: INavMenuEntry<IResourceHierarchyView>) => INavMenuEntry<IResourceHierarchyView>,
): INavMenuEntry<IResourceHierarchyView>[] {
    const matchIndex = menu.findIndex((item) => item.data.resourcePath === resourcePath);
    if (matchIndex !== -1) {
        const result = [...menu];
        result[matchIndex] = update(result[matchIndex]);
        return result;
    }
    const matchChildIndex = menu.findIndex((item) => resourcePath.startsWith(item.data.resourcePath!));

    if (matchChildIndex !== -1) {
        const result = [...menu];
        result[matchChildIndex] = { ...result[matchChildIndex], children: updateMenuItem(result[matchChildIndex].children!, resourcePath, update) };
        return result;
    }

    return menu;
}

export function updateMenuItemToOpenRequired(menu: INavMenuEntry<IResourceHierarchyView>[], resourcePath: string): INavMenuEntry<IResourceHierarchyView>[] {
    const matchIndex = menu.findIndex((item) => resourcePath.startsWith(item.data.resourcePath!));
    if (matchIndex !== -1) {
        const item = menu[matchIndex];
        if (item.children && item.children.length > 0 && item.data.resourcePath !== resourcePath) {
            const result = [...menu];
            result[matchIndex] = { ...result[matchIndex], isExpanded: true, children: updateMenuItemToOpenRequired(result[matchIndex].children!, resourcePath) };
            return result;
        }
    }
    return menu;
}

export type DataChangeEntry = {
    index: number;
    dataChange: (existingData: IAccountDataResource) => Partial<IAccountDataResource> | undefined;
};

export function updateUserData(state: FormationUserData, changes: (DataChangeEntry | null)[]) {
    let updated = false;
    let newState = state; // Par défaut, on retourne l'état actuel sans modification
    const appliedChanges: boolean[] = [];
    // Parcourir les objets {index, dataChange}
    changes.forEach((dataChangeEntry, index) => {
        if (dataChangeEntry && dataChangeEntry.index >= 0 && dataChangeEntry.index < state.userDatas.length) {
            const existingData = state.userDatas[dataChangeEntry.index].data as IAccountDataResource;
            const newData = dataChangeEntry.dataChange(existingData);
            appliedChanges[index] = newData !== existingData;
            // Vérifier si de nouvelles données sont retournées et sont différentes
            if (newData && newData !== existingData) {
                // Si c'est la première modification, cloner l'état et le tableau userDatas
                if (!updated) {
                    newState = { ...state };
                    newState.userDatas = [...state.userDatas];
                    updated = true;
                }

                // Mettre à jour l'élément modifié
                newState.userDatas[dataChangeEntry.index] = {
                    ...state.userDatas[dataChangeEntry.index],
                    data: { id: state.userDatas[dataChangeEntry.index].data?.id ?? 0, ...newData } as IAccountDataResource,
                };
            }
        }
    });

    // Si aucune mise à jour n'a été faite, retourner l'état d'origine
    return { userData: updated ? newState : state, appliedChanges };
}

export function markItemStarted(index: number): DataChangeEntry {
    return {
        index,
        dataChange: (existingUserData) => {
            if (!existingUserData?.startedOn) {
                return {
                    ...existingUserData,
                    startedOn: new Date(Date.now()),
                };
            }
            return existingUserData;
        },
    };
}

export function markItemCompleted(index: number): DataChangeEntry {
    return {
        index,
        dataChange: (existingUserData) => {
            if (!existingUserData?.completedOn) {
                return {
                    ...existingUserData,
                    completedOn: new Date(Date.now()),
                };
            }
            return existingUserData;
        },
    };
}

export function checkIfCanComplete(state: IFormationContextState, currentIndex: number): boolean {
    const currentItem = state.userData.userDatas[currentIndex];

    if (currentItem.resourceType === ResourceTypeEnum.Page) {
        return true;
    }
    if (currentItem.resourceType === ResourceTypeEnum.Module) {
        const childrenPaths = Object.keys(state.userData.userDataIndex).filter((key) => key.startsWith(currentItem.resourcePath) && key !== currentItem.resourcePath);

        for (let i = 0; i < childrenPaths.length; i++) {
            const itemIndex = state.userData.userDataIndex[childrenPaths[i]];
            if (!state.userData.userDatas[itemIndex].data?.completedOn) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export function getParentCompletion(state: IFormationContextState, currentIndex: number): (DataChangeEntry | null)[] {
    const currentItem = state.userData.userDatas[currentIndex];

    const parentPath = getParentPath(currentItem.resourcePath);
    if (parentPath) {
        const childrenPaths = Object.keys(state.userData.userDataIndex).filter((key) => key.startsWith(parentPath) && key !== parentPath && key !== currentItem.resourcePath);
        for (let i = 0; i < childrenPaths.length; i++) {
            const itemIndex = state.userData.userDataIndex[childrenPaths[i]];
            if (!state.userData.userDatas[itemIndex].data?.completedOn) {
                return [null];
            }
        }
        const parentIndex = state.userData.userDataIndex[parentPath];
        return [markItemCompleted(parentIndex), ...getParentCompletion(state, parentIndex)];
    }
    return [null];
}
