import { AppDispatch } from '../../../../Redux/store';
import { IProtectedValueInfo, QuestionnarySubmission, QuestionnarySubmissionAnonymous } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { IQuestionaryState } from '../reducer/state';

export function saveQuestionnaryResponses(
    mode: 'User' | 'Prospect' | 'anonymous',
    appDispatch: AppDispatch,
    questionnaryState: IQuestionaryState,
    showResult?: IProtectedValueInfo,
    email?: string,
    firstName?: string,
    lastName?: string,
    enablePersitance?: boolean,
) {
    const elapsedTimeInSec = questionnaryState.elapsedTimeInSec + questionnaryState.currentSessionElapsedTimeInSec;
    if (mode === 'User') {
        return performApiCall(async (client, cancelToken) => {
            const request = new QuestionnarySubmission();
            request.init({
                elapsedTimeInSec,
                startTime: questionnaryState.startTime,
                endTime: questionnaryState.endTime,
                items: questionnaryState.answers,
                questionnaryResourceId: questionnaryState.resourceId,
            });
            return await client.saveQuestionnaryResponses(!enablePersitance, request, cancelToken);
        }, appDispatch);
    } else {
        return performApiCall(
            async (client, cancelToken) => {
                const request = new QuestionnarySubmissionAnonymous();
                request.init({
                    email: mode === 'Prospect' ? email : '',
                    firstName: mode === 'Prospect' ? firstName : '',
                    lastName: mode === 'Prospect' ? lastName : '',
                    elapsedTimeInSec,
                    startTime: questionnaryState.startTime,
                    endTime: questionnaryState.endTime,
                    items: questionnaryState.answers,
                    questionnaryResourceId: questionnaryState.resourceId,
                });
                return await client.saveQuestionnaryResponsesForAnonymouseUser(showResult?.protected, request, cancelToken);
            },
            appDispatch,
            { anonymous: true },
        );
    }
}
