import { $isParagraphNode, ElementNode, ParagraphNode, TextNode } from 'lexical';

export function getParentParagraph(anchorNode: TextNode | ElementNode): ParagraphNode | null {
    let node: TextNode | ElementNode | null = anchorNode;
    while (node !== null) {
        if ($isParagraphNode(node)) {
            return node;
        }
        node = node.getParent();
    }
    return null;
}
