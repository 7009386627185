import { FC } from 'react';

import RichTextLexicalViewer from '../../../../../common/components/RichTextLexical/Viewer';
import { IPageElementRichText } from '../../../../../Services/SakuraApiClient';

export interface ElementRichTextViewProps {
    data: IPageElementRichText;
}

export const ElementRichTextView: FC<ElementRichTextViewProps> = (props: ElementRichTextViewProps) => {
    const { data } = props;

    // const style = useMemo<CSSProperties>(() => {
    //     return { width: '-webkit-fill-available', alignSelf: 'stretch' };
    // }, []);
    return (
        <div>
            <RichTextLexicalViewer jsonState={data.content} />
        </div>
    );
};
