import { PrimaryButton } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { useDynamicData } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { IResourceData, IResourceDataPage, IResourcePackage } from '../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../common/components/ExpressionField/Hook/useBracketResolver';
import { ResourceViewer } from '../../../common/components/ResourcePackage/ResourceViewer';
import { ResourcePageContent, getResourcesInfo } from '../Page/ResourcePageContent';

export interface StartPageProps {
    title: string;
    subTitle?: string;
    buttonText: string;
    imageResourceId?: number;
    customFirstPage: IResourcePackage | undefined;
    onStart: () => void;
}
export const StartPage: FC<StartPageProps> = (props: StartPageProps) => {
    const { title, subTitle, imageResourceId, customFirstPage, onStart, buttonText } = props;
    const dynData = useDynamicData();
    const resolvedTitle = useBracketResolver(title, dynData);
    const resolvedSubTitle = useBracketResolver(subTitle, dynData);
    const resolvedButtonText = useBracketResolver(buttonText, dynData);

    const innerResources = useMemo(() => {
        return customFirstPage ? getResourcesInfo(customFirstPage.innerResourcePackages ?? {}) : null;
    }, [customFirstPage]);
    return (
        <div className='startPage'>
            {customFirstPage && innerResources ? (
                <ResourcePageContent
                    innerResources={innerResources}
                    resourceAccountData={undefined}
                    parentResourcePathId={undefined}
                    pageResourceId={customFirstPage?.id}
                    pageData={customFirstPage?.data.content.page as IResourceDataPage}
                    resourceData={customFirstPage?.data as IResourceData}
                />
            ) : (
                <>
                    <h1>{resolvedTitle}</h1>
                    <div className='description'>
                        {imageResourceId ? (
                            <div className='pic'>
                                <ResourceViewer resourceId={imageResourceId} />
                            </div>
                        ) : (
                            <></>
                        )}
                        {resolvedSubTitle ? (
                            <div>
                                <p>{resolvedSubTitle}</p>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}

            <PrimaryButton className='sakuraButton' text={resolvedButtonText ?? ''} onClick={onStart} />
        </div>
    );
};
