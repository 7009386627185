import { FC, useCallback, useMemo, useState } from 'react';
import { IResource } from '../../../../Services/SakuraApiClient';
import { useNavigationEntity } from '../../../../common/Hooks/useNavigationEntity';
import { QuestionnaryDataContextProvider } from './DataContext/QuestionnaryDataContextProvider';

import { ResourceQuestionnaryDetailsContentForm } from './ResourceQuestionnaryDetailsContentForm';

export const ResourceQuestionnaryDetails: FC = () => {
    const [id, resource] = useNavigationEntity((id, client) => client.getResource(id as number));
    return id && !resource ? <></> : <QuestionnaryDetailContent resource={resource}></QuestionnaryDetailContent>;
};
interface QuestionnaryDetailContentProps {
    resource: IResource | undefined;
}

export const QuestionnaryDetailContent: FC<QuestionnaryDetailContentProps> = (props: QuestionnaryDetailContentProps) => {
    const { resource } = props;
    const [currentVersion, setCurrentVersion] = useState<IResource | undefined>(resource);
    const [originalResourceState, setOriginalResourceState] = useState<Partial<IResource> | undefined>(resource);
    const onSelectVersion = useCallback(
        (version: IResource) => {
            setCurrentVersion(version);
        },
        [setCurrentVersion],
    );

    const initialContext = useMemo(() => {
        return { categories: currentVersion?.data?.content?.questionnary?.categoriesDesc?.map((c) => ({ name: c.name ?? 'sans nom', id: c.id ?? '' })) ?? [] };
    }, [currentVersion]);
    const onChangeActiveVersion = useCallback(
        (version: IResource | null) => {
            setOriginalResourceState({ ...originalResourceState, versionId: version?.id });
        },
        [originalResourceState, setOriginalResourceState],
    );
    const onSave = useCallback(
        (version: IResource) => {
            if (version?.id === currentVersion?.id) {
                setCurrentVersion(version);
            }
            if (version?.id === originalResourceState?.id) {
                setOriginalResourceState(version);
            }
        },
        [currentVersion?.id, originalResourceState?.id],
    );
    return (
        <QuestionnaryDataContextProvider key={`QDA_${currentVersion?.id ?? 'New'}`} initialContext={initialContext}>
            <ResourceQuestionnaryDetailsContentForm
                key={`QDAF_${currentVersion?.id ?? 'New'}`}
                resource={currentVersion}
                onSelectVersion={onSelectVersion}
                originalResource={originalResourceState as IResource}
                onChangeActiveVersion={onChangeActiveVersion}
                onSave={onSave}
            />
        </QuestionnaryDataContextProvider>
    );
};
