import { useEffect, useMemo } from 'react';
import { ProductTileGrid, ProductTileWhishlistGrid } from '../../../../../../Redux/Reducers/DataSet/ApiCall/Product';
import { ResourceTileFavoriteGrid, ResourceTileGrid } from '../../../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { useDataSetSelector } from '../../../../../../Redux/Reducers/DataSet/reducer';
import { patchScope } from '../../../../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../../../../Redux/hook';
import { IProductTile, IResourceTile } from '../../../../../../Services/SakuraApiClient';
import { removeDiatrics } from '../../../../../../common/helpers/string';
import { contentSourceLabels, ContentSourceType, DataType } from './ContentSourceType';

export type DataTile = IProductTile | IResourceTile;
export interface IDataTile {
    type: DataType;
    data: DataTile;
}
export function useThumbnailSource(contentSource: ContentSourceType | undefined, sectionName?: string) {
    const productTileGridData = useDataSetSelector('ProductTileGrid');
    const productTileWhishlistGrid = useDataSetSelector('ProductTileWhishlistGrid');
    const resourceTileGridData = useDataSetSelector('ResourceTileGrid');
    const resourceTileFavoriteGrid = useDataSetSelector('ResourceTileFavoriteGrid');
    const appDispatch = useAppDispatch();
    useEffect(() => {
        if (!contentSource) {
            ResourceTileGrid.init();
            return;
        }
        switch (contentSource) {
            case 'ProductTile':
                ProductTileGrid.init();
                break;
            case 'ResourceTileOwnByAccount':
                ResourceTileGrid.init();
                break;
            case 'ResourceTileOwnByAccountAndProductTile':
                ProductTileGrid.init();
                ResourceTileGrid.init();
                break;
            case 'FavoriteResource':
                ResourceTileFavoriteGrid.init();
                break;
            case 'WhishlistProduct':
                ProductTileWhishlistGrid.init();
                break;
            case 'FavResAndWhishPrd':
                ResourceTileFavoriteGrid.init();
                ProductTileWhishlistGrid.init();
                break;
        }
    }, [contentSource]);

    const dataset = useMemo(() => {
        let result: IDataTile[] = [];
        if (!contentSource) {
            result = [];
        } else {
            switch (contentSource) {
                case 'ProductTile':
                    result = (productTileGridData.data as IProductTile[]).map<IDataTile>((data) => ({ type: 'Product', data }));
                    break;
                case 'ResourceTileOwnByAccount':
                    result = (resourceTileGridData.data as IResourceTile[]).map<IDataTile>((data) => ({ type: 'Resource', data }));
                    break;
                case 'ResourceTileOwnByAccountAndProductTile':
                    {
                        result = (resourceTileGridData.data as IResourceTile[]).map<IDataTile>((data) => ({ type: 'Resource', data }));
                        const res = (productTileGridData.data as IProductTile[]).map<IDataTile>((data) => ({ type: 'Product', data }));
                        result.push(...res);
                    }
                    break;
                case 'FavoriteResource':
                    result = (resourceTileFavoriteGrid.data as IResourceTile[]).map<IDataTile>((data) => ({ type: 'Resource', data }));
                    break;
                case 'WhishlistProduct':
                    result = (productTileWhishlistGrid.data as IProductTile[]).map<IDataTile>((data) => ({ type: 'Product', data }));
                    break;
                case 'FavResAndWhishPrd':
                    {
                        result = (resourceTileFavoriteGrid.data as IResourceTile[]).map<IDataTile>((data) => ({ type: 'Resource', data }));
                        const res = (productTileWhishlistGrid.data as IProductTile[]).map<IDataTile>((data) => ({ type: 'Product', data }));
                        result.push(...res);
                    }
                    break;
            }
        }
        if (sectionName && sectionName !== '') {
            result = result?.filter((item) => item.data.uxData?.section === sectionName);
        }

        if (result) {
            return result.sort((a, b) => {
                return (b.data.sortDate?.getTime() ?? 0) - (a.data.sortDate?.getTime() ?? 0);
            });
        }

        return [];
    }, [contentSource, sectionName, productTileGridData.data, resourceTileGridData.data, resourceTileFavoriteGrid.data, productTileWhishlistGrid.data]);

    useEffect(() => {
        if (contentSource) {
            appDispatch(
                patchScope({
                    scope: 'page',
                    contentToMerge: {
                        thumbnail: {
                            [`${removeDiatrics(contentSourceLabels[contentSource].replace(/ /g, '_').replace(/\./g, ''))}_count`]: dataset.length,
                        },
                    },
                }),
            );
        }
    }, [dataset, contentSource, appDispatch]);
    return dataset;
}
