import { DatePicker, IStyle } from '@fluentui/react';
import { FC, useMemo, useState } from 'react';
import { DatetimePicker } from '../../../../../common/components/DatetimePicker/DatetimePicker';
import { displayDate } from '../../../../../common/helpers/DateTimeHelper';
import { IPageElementDatetimeField } from '../../../../../Services/SakuraApiClient';
import { textStyleBuilder, toIStyleContainer, toIStyleLabel } from '../Label/textStyleBuilder';

export interface ElementDatetimeFieldProps {
    data: IPageElementDatetimeField;
}

export const ElementDatetimeField: FC<ElementDatetimeFieldProps> = (props: ElementDatetimeFieldProps) => {
    const { data } = props;
    const [date, setDate] = useState<Date | undefined>();

    const style = useMemo(() => {
        return textStyleBuilder(data.style);
    }, [data.style]);

    const labelStyleFU = useMemo(() => {
        const root = toIStyleLabel(style);
        return root;
    }, [style]);
    const containerStyleFU = useMemo<IStyle>(() => {
        return toIStyleContainer(style);
    }, [style]);
    return (
        <>
            {data.dateOnly ? (
                <DatePicker
                    styles={{
                        wrapper: labelStyleFU,
                        root: containerStyleFU,
                        readOnlyTextField: {
                            padding: '0px 32px 0px 8px !important',
                        },
                    }}
                    label={data.label}
                    isRequired={data.mandatory}
                    value={date}
                    formatDate={displayDate}
                    onSelectDate={(val) => {
                        setDate(val === null ? undefined : val);
                    }}
                />
            ) : (
                <DatetimePicker
                    styles={{
                        wrapper: labelStyleFU,
                        readOnlyTextField: {
                            padding: '0px 32px 0px 8px !important',
                        },
                        root: containerStyleFU,
                    }}
                    label={data.label}
                    required={data.mandatory}
                    value={date}
                    onSelectDate={(val) => {
                        setDate(val === null ? undefined : val);
                    }}
                />
            )}
        </>
    );
};
