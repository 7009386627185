import { Label, TextField, Toggle } from '@fluentui/react';
import { FC } from 'react';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { FluentIconSelectorDialog } from '../../../../common/components/Icons/FluentIconSelectorDialog';
import { GetResourceTypeIcon, GetResourceTypeTextOne } from '../../../../common/components/Icons/ResourceTypeIcon';
import { emptyAsUndefined } from '../../../../common/helpers/string';
import { useForm } from '../../../../common/Hooks/Form';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import * as validators from '../../../../common/Hooks/Form/validators';
import { IResource, IResourceData, IWorkflow, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { WorkflowConfigurator } from '../Questionnary/WorkflowConfigurator';

export interface DialogResourcePropertyData {
    resourceData?: DeepPartial<IResourceData>;
    resourcePart?: {
        name?: string;
        reusable?: boolean | undefined;
    };

    title?: string | undefined;
    icon?: string | undefined;
}
export interface DialogResourcePropertyProps {
    data: DialogResourcePropertyData;
    resourceType: ResourceTypeEnum;
    onChange: (data: DialogResourcePropertyData) => void;
    onClose: () => void;
}
export const DialogResourceEditProperties: FC<DialogResourcePropertyProps> = (props: DialogResourcePropertyProps) => {
    const { data, onChange, onClose, resourceType } = props;

    const form = useForm<DialogResourcePropertyData>(
        {
            initialState: data,
            validators: {
                fields: {
                    resourcePart: {
                        name: { displayName: 'Le nom', validators: [validators.required] },
                    },
                },
            },
        },
        false,
    );

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose();
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            form.commit();
            onChange({
                ...form.state,
            });
        }
    };

    return (
        <EditDialog
            id={'updateProperties'}
            icon={{ iconName: GetResourceTypeIcon(resourceType) }}
            mode={'Edit'}
            title={'Modification des propriétés'}
            show={true}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField
                    label='Nom'
                    errorMessage={form.fieldErrors.resourcePart?.name}
                    required
                    value={form.state.resourcePart?.name ?? ''}
                    onChange={(_, v) => form.update({ resourcePart: { name: v } })}
                />
                {resourceType !== ResourceTypeEnum.Formation ? (
                    <TextField label='Titre' errorMessage={form.fieldErrors.title} value={form.state.title ?? ''} onChange={(_, v) => form.update({ title: emptyAsUndefined(v) })} />
                ) : null}
                {resourceType === ResourceTypeEnum.Page ? (
                    <FluentIconSelectorDialog
                        label='Icon'
                        icon={form.state.icon}
                        onChange={(icon) => {
                            form.update({ icon });
                        }}
                    />
                ) : null}
                {resourceType !== ResourceTypeEnum.Formation ? (
                    <Toggle
                        label='Partageable'
                        title={`${GetResourceTypeTextOne(resourceType)} partageable peut étre référencé dans different module de formation.`}
                        offText='Non'
                        onText='Oui'
                        checked={form.state.resourcePart?.reusable}
                        onChange={(_, val) => form.update({ resourcePart: { reusable: val } })}
                    />
                ) : null}
                <FormGroup title='Workflows' icon={GetResourceTypeIcon(ResourceTypeEnum.Workflow)}>
                    <Label>{`Executé au démarrage ${resourceType === ResourceTypeEnum.Page ? 'de la page' : 'du module'}`}</Label>
                    <WorkflowConfigurator workflow={form.state.resourceData?.workflowOnStart as IWorkflow} onChangeWorkflow={(workflowOnStart) => form.update({ resourceData: { workflowOnStart } })} />
                    <Label>{`Executé à la fin ${resourceType === ResourceTypeEnum.Page ? 'de la page' : 'du module'}`}</Label>
                    <WorkflowConfigurator
                        workflow={form.state.resourceData?.workflowOnCompleted as IWorkflow}
                        onChangeWorkflow={(workflowOnCompleted) => form.update({ resourceData: { workflowOnCompleted } })}
                    />
                </FormGroup>
            </div>
        </EditDialog>
    );
};
