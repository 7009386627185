import { TextField, Toggle } from '@fluentui/react';
import { FC } from 'react';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { useForm } from '../../../../common/Hooks/Form';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import * as validators from '../../../../common/Hooks/Form/validators';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';

export interface ResourceQuestionnaryInfoDialogProps {
    resource: DeepPartial<IResource>;
    onChange: (resource: DeepPartial<IResource>) => void;
    onClose: () => void;
}
export const ResourceQuestionnaryInfoDialog: FC<ResourceQuestionnaryInfoDialogProps> = (props: ResourceQuestionnaryInfoDialogProps) => {
    const { resource, onChange, onClose } = props;

    const form = useForm<IResource>(
        {
            initialState: resource,
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                },
            },
        },
        resource !== undefined,
    );

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onChange(form.initialState);
        onClose();
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            form.commit();
            onChange({
                name: form.state.name,
                enabled: form.state.enabled,
                description: form.state.description,
                category: form.state.category,
            });
        }
    };
    return (
        <EditDialog
            id={'Informations'}
            icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Questionnary) }}
            mode={resource ? 'Edit' : 'Create'}
            title={'Informations'}
            show={true}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                <TextField label='Category' value={form.state.category ?? ''} onChange={(_, v) => form.update({ category: v })} />
                <TextField label='Description' value={form.state.description ?? ''} onChange={(_, v) => form.update({ description: v })} />
                <ResourceLookupField
                    type='ResourceImageVignetteLookup'
                    label='Vignette'
                    selectedResourceId={form.state.thumbnailResourceId}
                    onSelectResource={(resource) => form.update({ thumbnailResourceId: resource?.id })}
                />
                <Toggle label='Actif' offText='Non' onText='Oui' checked={form.state.enabled} onChange={(_, val) => form.update({ enabled: val })} />
            </div>
        </EditDialog>
    );
};
