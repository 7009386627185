import { FC, PropsWithChildren, useEffect } from 'react';
import { useNav } from '../common/Hooks/useNav';
import { getMetaContentByName } from '../common/helpers/documentMetadata';

export const WebRedirect: FC<PropsWithChildren<unknown>> = (props: PropsWithChildren<unknown>) => {
    const nav = useNav();
    useEffect(() => {
        const path = getMetaContentByName('webPath');
        if (path) {
            nav.navigateGlobal(path, undefined);
        }
    }, [nav]);
    return <>{props.children}</>;
};
