// export function getTriggerRange(selectionContext: RangeSelection, shouldTrigger: boolean) {
//     const { blockOffset, focusOffset, contentText } = selectionContext;

import { $isTextNode, ElementNode, LexicalNode, RangeSelection, TextNode } from 'lexical';
import { Intelisense, IntelisenseInfo } from '../../../../../Redux/Reducers/DynamiqueData/state';
import { IntelisenseData, IntelisenseEntry } from './IntelisensePopup';

export interface TriggerRange {
    end: number;
    start: number;
    text: string;
}
export interface FunctionInfo {
    triggerRange: TriggerRange;
    info: {
        info: IntelisenseInfo;
        argumentIndex: number;
    };
}

export interface ExpressionText {
    text: string;
    selectionIndex: number;
    inBracket: boolean;
}
function findDelimiterIndex(text: string, delimiter: string, fromEnd: boolean = false) {
    const index = fromEnd ? text.lastIndexOf(delimiter) : text.indexOf(delimiter);
    return index !== -1 ? index : null;
}
function getTextFromNode(node: LexicalNode, start: number, end: number) {
    if (!$isTextNode(node)) return '';
    const textContent = node.getTextContent();
    return textContent.substring(start, end);
}

export function getTextExpression(selection: RangeSelection): ExpressionText | null {
    if (!selection || !selection.anchor || !selection.focus) return null;

    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    const startOffset = selection.anchor.offset;
    const endOffset = selection.focus.offset;

    // Variables pour le texte et les positions de délimiteurs.
    let textBeforeSelection = '';
    let textAfterSelection = '';
    let selectionIndex = 0;
    let hasOpenDelimiter = false;
    let hasCloseDelimiter = false;

    // Parcours vers l'arrière pour trouver le délimiteur ouvrant {{
    let currentNode: TextNode | ElementNode | null = anchorNode;
    while (currentNode && !hasOpenDelimiter) {
        const textContent = currentNode === anchorNode ? getTextFromNode(currentNode, 0, startOffset) : currentNode.getTextContent();
        const openIndex = findDelimiterIndex(textContent, '{{', true);
        const closeIndex = findDelimiterIndex(textContent, '}}', true);
        if (closeIndex !== null && (openIndex === null || closeIndex > openIndex)) {
            return null;
        }
        if (openIndex !== null) {
            textBeforeSelection = textContent.substring(openIndex) + textBeforeSelection;
            selectionIndex = textBeforeSelection.length; // Position de début de sélection dans la chaîne totale
            hasOpenDelimiter = true;
        } else {
            textBeforeSelection = textContent + textBeforeSelection;
            selectionIndex += textContent.length; // Ajoute la longueur au début
        }
        currentNode = currentNode.getPreviousSibling();
    }

    // Parcours vers l'avant pour trouver le délimiteur fermant }}
    currentNode = focusNode;
    while (currentNode && !hasCloseDelimiter) {
        const textContent = currentNode === focusNode ? getTextFromNode(currentNode, endOffset, currentNode.getTextContentSize()) : currentNode.getTextContent();
        const closeIndex = findDelimiterIndex(textContent, '}}');
        const openIndex = findDelimiterIndex(textContent, '{{');
        if (openIndex !== null && (closeIndex === null || openIndex < closeIndex)) {
            return null;
        }
        if (closeIndex !== null) {
            textAfterSelection += textContent.substring(0, closeIndex + 2);
            hasCloseDelimiter = true;
        } else {
            textAfterSelection += textContent;
        }
        currentNode = currentNode.getNextSibling();
    }

    const fullText = textBeforeSelection + textAfterSelection;
    console.table({
        text: fullText,
        selectionIndex,
        inBracket: hasOpenDelimiter && hasCloseDelimiter,
    });
    return {
        text: fullText,
        selectionIndex,
        inBracket: hasOpenDelimiter && hasCloseDelimiter,
    };
}

function hasChildProperty(info: IntelisenseInfo): boolean {
    if (info.items) {
        return Object.keys(info.items).length > 0;
    }
    return false;
}

export function getTextToInsert(data: IntelisenseData, entry: IntelisenseEntry) {
    let text = entry.info.fullName;
    if (data.searchText) {
        if (entry.info.fullName.startsWith(data.searchText)) {
            text = entry.info.fullName.substring(data.searchText.length);
        }
    }

    let final = true;
    if (hasChildProperty(entry.info) && entry.info.type !== 'func') {
        text = text + '.';
        final = false;
    } else if (entry.info.type === 'func') {
        text = text + '(';
        final = false;
    }
    return { text, final };
}

// function indexOfBracketStart(text: string, position: number) {
//     if (position >= 1) {
//         for (let index = position; index >= 0; index--) {
//             if (text[index] === '{') {
//                 if (index > 0) {
//                     if (text[index - 1] === '{') {
//                         return index + 1;
//                     }
//                 }
//             }
//             if (text[index] === '}' && index !== position) {
//                 return -1;
//             }
//         }
//     }
//     return -1;
// }
// function indexOfBracketEnd(text: string, position: number) {
//     if (position < text.length - 1) {
//         for (let index = position; index < text.length; index++) {
//             if (text[index] === '}') {
//                 if (index < text.length - 1) {
//                     if (text[index + 1] === '}') {
//                         return Math.max(0, index - 1);
//                     }
//                 }
//             }
//             if (text[index] === '{' && index !== position) {
//                 return -1;
//             }
//         }
//     }
//     return -1;
// }

function indexOfStartFunction(text: string, position: number) {
    if (position > 1) {
        let parentheseCounter = 0;
        for (let index = position - 1; index >= 0; index--) {
            if (text[index] === '(') {
                if (parentheseCounter === 0) {
                    return index;
                } else {
                    parentheseCounter--;
                }
            }
            if (text[index] === ')') {
                parentheseCounter++;
            }
        }
    }
    return -1;
}
function indexWhenMatch(text: string, position: number, reg: RegExp) {
    if (position > 1) {
        for (let index = position; index >= 0; index--) {
            if (text[index] && text[index].match(reg)) {
                return index;
            }
        }
    }
    return -1;
}
function skipFunction(text: string, position: number) {
    if (position > 1) {
        let parentheseCounter = 0;
        for (let index = position - 1; index < text.length; index++) {
            if (text[index] === '(') {
                parentheseCounter++;
            }
            if (text[index] === ')') {
                parentheseCounter--;
                if (parentheseCounter === 0) {
                    return index;
                }
            }
        }
    }
    return text.length - 1;
}
function findArgumentIndex(text: string, startIndex: number, focusIndex: number) {
    let argumentIndex = 0;

    if (startIndex <= text.length - 1) {
        for (let index = startIndex; index < text.length; index++) {
            if (text[index] === '(') {
                index = skipFunction(text, index);
            }
            if (index >= focusIndex) {
                break;
            }
            if (text[index] === ',') {
                argumentIndex++;
            }
        }
    }
    return argumentIndex;
}
function selectionIsInVariableName(contentText: string, focusIndex: number) {
    if (contentText && contentText.length > 0) {
        let beginIndex = 0;
        for (let index = Math.max(focusIndex - 1, 0); index >= 0; index--) {
            if (contentText[index] && !contentText[index].match(/[a-zA-Z_.0-9]/)) {
                beginIndex = index + 1;
                break;
            }
        }
        let endIndex = contentText.length;

        if (focusIndex < contentText.length) {
            for (let index = focusIndex; index < contentText.length; index++) {
                if (contentText[index] && !contentText[index].match(/[a-zA-Z_.0-9]/)) {
                    endIndex = index;
                    break;
                }
            }
        }
        //  const variableName = contentText.substring(beginIndex, endIndex);
        // if (variableName.match(/[a-zA-Z]+\.\w*/)) {
        //     return { beginIndex, endIndex };
        // }

        const bi = beginIndex === 0 ? 0 : beginIndex - 1;
        const ei = endIndex === contentText.length ? endIndex - 1 : endIndex;
        if ((bi > 0 && contentText[bi] === "'") || (ei <= contentText.length - 1 && contentText[ei] === "'")) {
            return { beginIndex, endIndex, insideLitteral: true };
        }
        return { beginIndex, endIndex };
    }
    return undefined;
}

function findFunction(contentText: string, blockOffset: number) {
    let funcName: string | undefined = undefined;
    let argumentIndex = 0;

    const firstParentheseIndex = indexOfStartFunction(contentText, blockOffset);
    if (firstParentheseIndex !== -1) {
        const startIndex = indexWhenMatch(contentText, firstParentheseIndex - 1, /[\s,{]/);

        if (firstParentheseIndex - 1 !== startIndex) {
            funcName = contentText.substring(startIndex + 1, firstParentheseIndex);
            argumentIndex = findArgumentIndex(contentText, firstParentheseIndex + 1, blockOffset);
        }
    }
    return {
        funcName,
        argumentIndex,
    };
}

export function checkInsideFunction(expression: ExpressionText, intelisense: Intelisense): FunctionInfo | undefined {
    if (expression.text && expression.text.length > 0) {
        const { funcName, argumentIndex } = findFunction(expression.text, expression.selectionIndex);
        if (funcName) {
            const info = intelisense[funcName];
            if (info) {
                return {
                    triggerRange: {
                        end: expression.selectionIndex,
                        start: expression.selectionIndex,
                        text: info.fullName,
                    },
                    info: {
                        info,
                        argumentIndex,
                    },
                };
            }
        }
    }
    return undefined;
}

export function getTriggerRange(expression: ExpressionText, shouldTrigger: boolean): TriggerRange | undefined {
    const varNamePosition = selectionIsInVariableName(expression.text, expression.selectionIndex);

    if (varNamePosition?.insideLitteral) {
        return undefined;
    }
    if (varNamePosition) {
        const newText = expression.text.slice(varNamePosition.beginIndex, varNamePosition.endIndex);
        if ((newText.length > 1 && newText.indexOf('.') !== -1) || shouldTrigger) {
            return {
                end: varNamePosition.endIndex - expression.selectionIndex,
                start: varNamePosition.beginIndex - expression.selectionIndex,
                text: newText,
            };
        }
    }
    if (shouldTrigger) {
        return {
            end: expression.selectionIndex,
            start: expression.selectionIndex,
            text: '',
        };
    }
    return undefined;
}

export function filterEntries(dataSource: Intelisense, searchText: string | undefined) {
    let results: Intelisense | undefined = dataSource;
    let localFilter = null;
    if (searchText) {
        const filterParts = searchText.split('.');
        let dsLevel: Intelisense | undefined = dataSource;
        filterParts.forEach((varName, index) => {
            if (index !== filterParts.length - 1) {
                dsLevel = dsLevel ? dsLevel[varName]?.items : undefined;
                results = dsLevel;
            } else if (dsLevel) {
                localFilter = varName;
            }
        });
    }
    return { results, localFilter };
}
