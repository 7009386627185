import { TextField } from '@fluentui/react';
import { FC } from 'react';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { FluentIconSelectorDialog } from '../../../../common/components/Icons/FluentIconSelectorDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { useForm } from '../../../../common/Hooks/Form';
import * as validators from '../../../../common/Hooks/Form/validators';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../../Redux/hook';
import { datasetCollections } from '../../../../Redux/Reducers/DataSet/DataSetName';
import { IResource, PageElement, PageElementContainer, Resource, ResourceContent, ResourceData, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';

export interface PageBlockAddDialogProps {
    element: PageElement;
    onClose: () => void;
}
const blankPageBlock = new Resource();
blankPageBlock.init({
    resourceType: ResourceTypeEnum.PageBlock,
    reusable: false,
    enabled: true,
});

export const PageBlockAddDialog: FC<PageBlockAddDialogProps> = (props: PageBlockAddDialogProps) => {
    const { element, onClose } = props;

    const appDispatch = useAppDispatch();
    const form = useForm<IResource>(
        {
            initialState: blankPageBlock,
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                },
            },
        },
        false,
    );

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose();
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client, ct) => {
                    const data = new Resource();
                    data.init(form.state);
                    data.enabled = true;
                    data.resourceType = ResourceTypeEnum.PageBlock;
                    data.data = new ResourceData();
                    data.data.content = new ResourceContent();
                    data.data.content.pageBlock = new PageElementContainer();
                    data.data.content.pageBlock.init(element);

                    await client.createResource(data, ct);
                    form.commit();
                    datasetCollections['ResourcePageBlockGrid'].refresh();
                    onClose();
                },
                appDispatch,
                { contextId: 'addToLibrary' },
            );
        }
    };
    return (
        <EditDialog
            id={'addToLibrary'}
            icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.PageBlock) }}
            mode={'Add'}
            title={'Ajouter le block à la bibliothéque '}
            show={true}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                <FluentIconSelectorDialog
                    label='Icon'
                    icon={form.state?.icon}
                    onChange={(icon) => {
                        form.update({ icon });
                    }}
                />
            </div>
        </EditDialog>
    );
};
