import { CommandBarButton, IButtonStyles, IOverflowSetItemProps, Icon, Label, MessageBarType, OverflowSet } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import { setMessage } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { EntityState, IResourceHierarchy, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon, GetResourceTypeTextOneSpecific2 } from '../../../../common/components/Icons/ResourceTypeIcon';
import { DialogNewFormationItem } from './DialogNewFormationItem';
import { DialogResourceEditProperties, DialogResourcePropertyData } from './DialogResourceProperty';
import { PropertyUpdate, ResourceHierarchyAction } from './FormResourceHierarchySlicer';
import { TableOfContentDnd } from './TableOfContentDnd';

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    if (item.onRender) {
        return item.onRender(item);
    }
    return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems: IOverflowSetItemProps[] | undefined): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 0,
            padding: '0 4px',
            alignSelf: 'stretch',
            height: 'auto',
        },
    };
    return <CommandBarButton ariaLabel='More items' styles={buttonStyles} menuIconProps={{ iconName: 'MoreVertical' }} menuProps={{ items: overflowItems ?? [] }} />;
};

export interface TableOfContentItemProps {
    root: boolean;
    resourceItem: DeepPartial<IResourceHierarchy>;
    updateForm: (action: ResourceHierarchyAction) => string | undefined;
    selectionPath: string | undefined;
    onSelect: (resourcePath: string | undefined) => void;
}

export const TableOfContentItem: FC<TableOfContentItemProps> = (props: TableOfContentItemProps) => {
    const { resourceItem, updateForm, selectionPath, onSelect, root } = props;
    const [showNewItemDialog, setShowNewItemDialog] = useState<'Module' | 'Page' | undefined>(undefined);
    const [showRenameItemDialog, setShowRenameItemDialog] = useState<DialogResourcePropertyData | undefined>(undefined);
    const [showDeleteItemDialog, setShowDeleteItemDialog] = useState<boolean>(false);
    const appDispatch = useAppDispatch();
    const isSelected = useMemo(() => {
        return selectionPath === resourceItem.resourcePath;
    }, [selectionPath, resourceItem]);
    const menuItems = useMemo<IOverflowSetItemProps[]>(() => {
        const items: IOverflowSetItemProps[] = [];
        if (resourceItem.resource?.resourceType !== ResourceTypeEnum.Formation) {
            items.push({
                key: 'UpdateProperties',
                text: 'Propriétes',
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                    setShowRenameItemDialog({
                        resourcePart: { name: resourceItem.resource?.name, reusable: resourceItem.resource?.reusable },
                        title: resourceItem.overrideName,
                        icon: resourceItem.overrideIcon,
                        resourceData: resourceItem.resource?.data,
                    });
                },
            });
        }
        if (root || resourceItem.resource?.resourceType === ResourceTypeEnum.Module) {
            items.push(
                {
                    key: 'AddModule',
                    text: 'Ajouter un module',
                    iconProps: { iconName: 'Dictionary' },
                    onClick: () => {
                        setShowNewItemDialog('Module');
                    },
                },
                {
                    key: 'AddPage',
                    text: 'Ajouter une page',
                    iconProps: { iconName: 'Page' },
                    onClick: () => {
                        setShowNewItemDialog('Page');
                    },
                },
            );
        }
        if (!root) {
            items.push({
                key: 'Del',
                text: 'Supprimer cet élément',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setShowDeleteItemDialog(true);
                },
            });
        }
        return items;
    }, [root, resourceItem, setShowNewItemDialog]);

    const childItems = useMemo(() => {
        if (resourceItem.children) {
            return resourceItem.children.filter((i) => i.linkState !== EntityState.Delete);
        }
        return [];
    }, [resourceItem.children]);

    return (
        <TableOfContentDnd
            resourceItem={resourceItem}
            orientation='Vertical'
            onDropItem={
                root
                    ? undefined
                    : (item, destination, position) => {
                          updateForm({
                              type: 'MoveItem',
                              payload: {
                                  item,
                                  destination,
                                  position: position === 'Start' ? 'before' : position === 'End' ? 'after' : 'inside',
                              },
                          });
                      }
            }
            noDrag={root}
        >
            <div
                className={clsx('tocitem', root ? '' : 'shift')}
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onSelect(resourceItem.resourcePath);
                }}
            >
                <div className={clsx('header', isSelected ? 'selected' : null)}>
                    <div>
                        <Icon
                            iconName={
                                resourceItem?.overrideIcon ??
                                resourceItem?.resource?.icon ??
                                (resourceItem?.resource?.resourceType ? GetResourceTypeIcon(resourceItem.resource.resourceType) : undefined)
                            }
                        />
                        <Label>{resourceItem.overrideName ?? resourceItem?.resource?.name}</Label>
                    </div>
                    <div className='menu'>
                        <OverflowSet overflowItems={menuItems} onRenderItem={onRenderItem} onRenderOverflowButton={onRenderOverflowButton} />
                        <DialogNewFormationItem
                            type={showNewItemDialog}
                            id='DialogNewFormationItem'
                            parentResource={resourceItem as IResourceHierarchy}
                            onClose={(item) => {
                                if (item && resourceItem.resourcePath) {
                                    const error = updateForm({ type: 'addItem', payload: { resourcePath: resourceItem.resourcePath, item } });
                                    if (error) {
                                        appDispatch(setMessage({ contextId: 'DialogNewFormationItem', message: { Severity: MessageBarType.error, text: error } }));
                                        return;
                                    } else {
                                        appDispatch(setMessage({ contextId: 'DialogNewFormationItem', message: undefined }));
                                    }
                                }
                                setShowNewItemDialog(undefined);
                            }}
                        />
                        {resourceItem.resource && showRenameItemDialog ? (
                            <DialogResourceEditProperties
                                data={showRenameItemDialog}
                                onClose={() => setShowRenameItemDialog(undefined)}
                                resourceType={resourceItem.resource.resourceType ?? ResourceTypeEnum.Page}
                                onChange={(data) => {
                                    if (resourceItem.resourcePath) {
                                        updateForm({ type: 'updateProperties', payload: { resourcePath: resourceItem.resourcePath, propertyChanged: data as PropertyUpdate } });
                                    }
                                    setShowRenameItemDialog(undefined);
                                }}
                            />
                        ) : null}

                        <ConfirmationDialog
                            id='ConfirmationDialogDeleteItem'
                            show={showDeleteItemDialog}
                            icon={{ iconName: resourceItem?.resource?.resourceType ? GetResourceTypeIcon(resourceItem.resource.resourceType) : undefined }}
                            title='Suppression'
                            subText={`Etes-vous sur de vouloir supprimer ${GetResourceTypeTextOneSpecific2(resourceItem?.resource?.resourceType ?? ResourceTypeEnum.Module)}?`}
                            onReply={(reply) => {
                                if (reply && resourceItem.resourcePath) {
                                    updateForm({ type: 'deleteItem', payload: { resourcePath: resourceItem.resourcePath } });
                                }
                                setShowDeleteItemDialog(false);
                            }}
                        />
                    </div>
                </div>

                {childItems.map((item) => {
                    return <TableOfContentItem root={false} selectionPath={selectionPath} onSelect={onSelect} key={item.resourcePath} resourceItem={item} updateForm={updateForm} />;
                })}
            </div>
        </TableOfContentDnd>
    );
};
