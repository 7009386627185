import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ResourcePageGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { getVariablesNames } from '../../../../Redux/Reducers/DynamiqueData/helpers';
import { useIntelisenseWritableDataOfScope } from '../../../../Redux/Reducers/DynamiqueData/reducer';
import { contextualMobileDisplaySelector, setContextualMobileDisplay, setPopupMessage } from '../../../../Redux/Reducers/System/reducer';
import { PopupMessage } from '../../../../Redux/Reducers/System/state';
import { useRootPathSelector } from '../../../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../../../Redux/hook';
import { screenPaths } from '../../../../Routing/ScreenPaths';
import { IAccountDataResource, IResource, IResourceData, IResourceDataPage, Resource, ResourceContent, ResourceData, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { useForm } from '../../../../common/Hooks/Form';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { keyboardEvents, useKeyBoardEvent } from '../../../../common/Hooks/useKeyboardEvent';
import { useNav } from '../../../../common/Hooks/useNav';
import { useNavigationEntity } from '../../../../common/Hooks/useNavigationEntity';
import { useRefLink } from '../../../../common/Hooks/useRefLink';
import { SimpleDialog } from '../../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { Panel, PanelCommand } from '../../../../common/components/Panel/Panel';
import { openNewTab } from '../../../../common/helpers/uri';
import { ExecutionDataContextProvider, deletePersistantExecutionDataContext } from '../../../Common/Page/ExecutionContext/ExecutionDataContextProvider';
import { IResourceInfo } from '../../../Common/Page/ExecutionContext/IExecutionContextState';
import { getResourcesInfo } from '../../../Common/Page/ResourcePageContent';
import { PageEditorStudio } from '../../../Common/PageEditor/PageEditorStudio';
import { PagePreviewView } from '../../../Common/PageEditor/PagePreviewView';
import { SaveTemplateDialog } from '../../../Common/PageEditor/PageTemplate/SaveTemplateDialog';
import { ResourcePageInfoDialog } from './ResourcePageInfoDialog';
import { buildNewPage } from './utils';

export interface ResourcePageEditorProps {
    resource: IResource | undefined;
    innerResources: Record<number, IResourceInfo>;
    resourceAccountData: IAccountDataResource | undefined;
}

export const getNewResourcePageData = (reusable: boolean) => {
    const newResource = new Resource();
    newResource.resourceType = ResourceTypeEnum.Page;
    newResource.reusable = reusable;
    newResource.enabled = true;
    newResource.name = 'Nouvelle Page';
    newResource.data = new ResourceData();
    newResource.data.content = new ResourceContent();
    newResource.data.content.page = buildNewPage();
    return newResource;
};
const confirmationSavePopup: PopupMessage = { icon: 'SkypeCircleCheck', title: 'Sauvegarde', subTitle: 'Page sauvegardé avec succés.', timeout: 1000 };

export const ResourcePageEditor: FC = () => {
    const [id, resourcePackageFull] = useNavigationEntity((id, client) => client.getResourcePackageFull(id as number));
    return id && !resourcePackageFull ? (
        <></>
    ) : (
        <ResourcePageEditorContent
            resource={resourcePackageFull?.resource}
            innerResources={getResourcesInfo(resourcePackageFull?.resourcePackage?.innerResourcePackages ?? {})}
            resourceAccountData={resourcePackageFull?.accountResourceData}
        ></ResourcePageEditorContent>
    );
};
export const ResourcePageEditorContent: FC<ResourcePageEditorProps> = (props: ResourcePageEditorProps) => {
    const { resource, innerResources, resourceAccountData } = props;
    const nav = useNav();
    const appDispatch = useAppDispatch();
    const urlRootPath = useAppSelector(useRootPathSelector);
    const contextualMobileDisplay = useAppSelector(contextualMobileDisplaySelector);
    const [preview, setPreview] = useState<boolean>(false);
    const [savedContextualMobileDisplay, setSavedContextualMobileDisplay] = useState<boolean>(false);
    const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
    const [showEditInfo, setShowEditInfo] = useState<boolean>(false);

    const form = useForm<IResource>(
        {
            initialState: resource ?? getNewResourcePageData(true),
        },
        true,
        undefined,
        `Page_${resource?.id ? resource.id : 'new'}`,
    );
    const formRef = useRefLink(form);

    const goBack = useCallback(
        (refresh: boolean) => {
            if (nav.from?.pathname.endsWith(screenPaths.resourceList(ResourceTypeEnum.Page))) {
                nav.navigate(screenPaths.resourceList(ResourceTypeEnum.Page), refresh ? { refresh: true } : undefined);
            } else if (nav.from) {
                nav.goBack();
            }
        },
        [nav],
    );
    const onSave = useCallback(() => {
        const error = form.validate();
        if (!error) {
            performApiCall(async (client, ct) => {
                if (resource?.id) {
                    const resourceUpdated = new Resource();
                    resourceUpdated.init(form.state);
                    await client.updateResource(resource?.id, resourceUpdated, ct);
                    form.commit();
                    // goBack(true);
                    ResourcePageGrid.refresh();
                    deletePersistantExecutionDataContext(resourceUpdated.id.toString());
                    appDispatch(setPopupMessage({ message: confirmationSavePopup }));
                } else {
                    const data = new Resource();
                    data.init(form.state);
                    await client.createResource(data);
                    deletePersistantExecutionDataContext('New');
                    form.commit();
                    goBack(true);
                }
            }, appDispatch);
        }
    }, [appDispatch, form, goBack, resource?.id]);

    useKeyBoardEvent(keyboardEvents.save, onSave);

    const pageCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: resource?.id ? 'Sauvegarder' : 'Créer',
                icon: 'Save',
                onClick: () => {
                    onSave();
                },
            },
            {
                text: 'Sauvegarder en tant que modéle',
                icon: 'SaveTemplate',
                onClick: () => {
                    setSaveTemplate(true);
                },
            },
            {
                text: 'Prévisualiser la page',
                icon: 'View',
                onClick: () => {
                    setSavedContextualMobileDisplay(contextualMobileDisplay);
                    setPreview(true);
                },
            },
            {
                text: 'Voir la page',
                icon: 'EntryView',
                disabled: form.isModified,
                onClick: () => {
                    if (form.state.id) {
                        openNewTab(urlRootPath + screenPaths.resourceView(ResourceTypeEnum.Page, form.state.id));
                    }
                },
            },
            {
                text: 'Propriétés',
                icon: 'Edit',
                onClick: () => {
                    setShowEditInfo(true);
                },
            },
        ];
    }, [contextualMobileDisplay, form.isModified, form.state.id, onSave, resource?.id, urlRootPath]);

    const intelisenseWritable = useIntelisenseWritableDataOfScope('page');
    useEffect(() => {
        if (intelisenseWritable) {
            const variableNames = getVariablesNames(intelisenseWritable, 'page.data.');
            formRef.current.update({ data: { variableNames } });
        }
    }, [formRef, intelisenseWritable]);
    return (
        <>
            <Panel
                isContentModified={form.isModified}
                revertContentModification={form.rollback}
                backButtonCommand={() => (nav.from ? goBack(false) : nav.navigate(screenPaths.resourceList(ResourceTypeEnum.Page)))}
                title={`Edition de '${form.state?.name}'`}
                icon='PageHeaderEdit'
                commands={pageCommand}
            >
                <ExecutionDataContextProvider
                    key={resource?.id}
                    pageResourceId={resource?.id}
                    parentResourcePathId={undefined}
                    resources={innerResources}
                    serializationIdentifier={resource?.id ? resource.id.toString() : 'new'}
                >
                    <PageEditorStudio
                        resourceData={form.state.data as IResourceData}
                        pageData={form.state.data?.content?.page as IResourceDataPage}
                        onChangePageData={(newData) => {
                            form.update({ data: { content: { page: newData } } });
                        }}
                        resourceAccountData={resourceAccountData}
                    />
                    {preview && form.state.data?.content?.page && (
                        <SimpleDialog
                            id={'previewPage'}
                            title={'Prévisualisation'}
                            show={preview}
                            icon={{ iconName: 'PreviewLink' }}
                            onClose={function (): void {
                                setPreview(false);
                                appDispatch(setContextualMobileDisplay({ mobile: savedContextualMobileDisplay }));
                            }}
                        >
                            <PagePreviewView data={form.state.data?.content?.page as IResourceDataPage} />
                        </SimpleDialog>
                    )}
                </ExecutionDataContextProvider>
            </Panel>
            {showEditInfo ? (
                <ResourcePageInfoDialog
                    resource={form.state}
                    onChange={(change) => {
                        form.update(change);
                        setShowEditInfo(false);
                    }}
                    onClose={() => {
                        setShowEditInfo(false);
                    }}
                />
            ) : (
                <></>
            )}
            {saveTemplate ? (
                <SaveTemplateDialog
                    page={form.state as IResource}
                    onClose={() => {
                        setSaveTemplate(false);
                    }}
                />
            ) : null}
        </>
    );
};
