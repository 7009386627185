import { DefaultButton, DialogFooter, IIconProps, PrimaryButton } from '@fluentui/react';
import { FC, PropsWithChildren } from 'react';
import { useAppDispatch } from '../../../../Redux/hook';
import { clearContext, setPreventLostData, useLoadingSelector } from '../../../../Redux/Reducers/System/reducer';
import { store } from '../../../../Redux/store';
import { CustomDialog } from '../CustomDialog';

export interface EditDialogProps {
    id: string;
    title: string;
    subText?: string;
    icon?: IIconProps;
    show: boolean;
    mode: 'Create' | 'Edit' | 'Add';
    canSubmit?: () => boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export const EditDialog: FC<PropsWithChildren<EditDialogProps>> = (props: PropsWithChildren<EditDialogProps>) => {
    const { children, title, subText, id, show, mode, onClose, onSubmit, canSubmit, icon } = props;
    const loadingIndicator = useLoadingSelector(id);
    const dispatch = useAppDispatch();
    const onCloseDialog = () => {
        if (store.getState().system.dirtyForm[id]) {
            dispatch(
                setPreventLostData({
                    callback: () => {
                        dispatch(clearContext({ contextId: id }));
                        onClose();
                    },
                    contextId: id,
                }),
            );
        } else {
            dispatch(clearContext({ contextId: id }));
            onClose();
        }
    };
    return (
        <CustomDialog id={id} hidden={!show} onDismiss={onCloseDialog} subText={subText} title={title} icon={icon}>
            <>
                {children}
                <DialogFooter>
                    <PrimaryButton
                        disabled={loadingIndicator?.isLoading || (canSubmit ? !canSubmit() : false)}
                        onClick={onSubmit}
                        text={mode === 'Create' ? 'Créer' : mode === 'Add' ? 'Ajouter' : 'Modifier'}
                    />
                    <DefaultButton disabled={loadingIndicator?.isLoading} onClick={onCloseDialog} text='Annuler' />
                </DialogFooter>
            </>
        </CustomDialog>
    );
};
