import { Callout, IconButton, mergeStyleSets } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { ExpressionField, ExpressionKind } from '../../../../common/components/ExpressionField/ExpressionField';
import { generateUniqueId } from '../../../../common/helpers/uniqueId';

export interface VisibilityComponentProps {
    visibleIf: string | undefined;
    onChange: (visibilityIf: string | undefined) => void;
}

const styles = mergeStyleSets({
    callout: {
        width: 620,
        padding: '20px 24px',
    },
});

export const VisibilityComponent: FC<VisibilityComponentProps> = (props: VisibilityComponentProps) => {
    const { visibleIf, onChange } = props;
    const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>();
    const buttonId = useRef(generateUniqueId('targetButton'));
    return (
        <>
            <IconButton
                id={buttonId.current}
                title={visibleIf ? 'Visible sous condition' : 'Visible'}
                style={{ color: visibleIf ? 'red' : 'green' }}
                iconProps={{ iconName: visibleIf ? 'VisuallyImpaired' : 'RedEye' }}
                onClick={() => setIsCalloutVisible(true)}
            />
            {isCalloutVisible && (
                <Callout
                    className={clsx(styles.callout, 'sakuraCallout')}
                    ariaLabelledBy={'Visibility change'}
                    ariaDescribedBy={'Visibility change'}
                    role='dialog'
                    gapSpace={0}
                    target={`#${buttonId.current}`}
                    onDismiss={() => {
                        setIsCalloutVisible(false);
                    }}
                    setInitialFocus
                >
                    <div>
                        <ExpressionField
                            kind={ExpressionKind.Condition}
                            label='Visible si'
                            value={visibleIf}
                            minVisibleLines={1}
                            maxVisibleLines={5}
                            multilines
                            onChange={(value) => onChange(value === '' ? undefined : value)}
                        />
                    </div>
                </Callout>
            )}
        </>
    );
};
