import { IObjectWithKey, Selection } from '@fluentui/react';
import { MutableRefObject, useEffect, useRef } from 'react';

function createSelection<T>(
    gridSelection: MutableRefObject<Selection<IObjectWithKey> | undefined>,
    getKey: ((item: T) => string | number) | undefined,
    onSelect: ((item: T | undefined) => void) | undefined,
) {
    return new Selection({
        onSelectionChanged: () => {
            console.log('onSelectionChanged');
            const selected = gridSelection.current?.getSelection();
            if (onSelect) {
                if (selected && selected.length > 0) {
                    onSelect(selected[0] as T);
                } else {
                    onSelect(undefined);
                }
            }
        },
        getKey: (item: IObjectWithKey) => {
            return getKey ? getKey(item as T) : item.key ?? '';
        },
    });
}
export function useGridSelection<T>(getKey: ((item: T) => string | number) | undefined, onSelect: ((item: T | undefined) => void) | undefined) {
    const gridSelection = useRef<Selection<IObjectWithKey>>();

    useEffect(() => {
        gridSelection.current = createSelection<T>(gridSelection, getKey, onSelect);
        return () => {
            gridSelection.current = undefined;
        };
    }, [getKey, onSelect]);

    if (getKey) {
        return gridSelection;
    } else {
        return undefined;
    }
}
