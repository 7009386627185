import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { getParent } from '../../../../../../common/helpers/Element';

import { Icon } from '@fluentui/react';
import { SelectionStatus } from '../../../../../../Redux/Reducers/System/state';
import { ThumbnailImage } from './ThumbnailImage';
import { ThumbnailItemDetails } from './ThumbnailItemDetails/ThumbnailItemDetails';
import './ThumbnailMobileItem.scss';
import { IDataTile } from './useThumbnailSource';

interface Select {
    status: SelectionStatus;
    origin: 'Click' | 'MouseOver';
}
export interface ThumbnailItemMobileProps {
    index: number;
    position: 'first' | 'last' | 'middle';
    width: number;
    height: number;
    item: IDataTile;
    onSelect?: (selected: Select) => void;
    onOpen: () => Promise<void>;
    selection?: Select;
    disableActions?: boolean;
}

export const ThumbnailItemMobile: FC<ThumbnailItemMobileProps> = (props: ThumbnailItemMobileProps) => {
    const { item, onSelect, onOpen, width, height, position, selection, disableActions } = props;

    const refTile = useRef<HTMLDivElement>(null);
    const refDetailTile = useRef<HTMLDivElement>(null);
    const refTileOver = useRef<HTMLDivElement>(null);
    const refTileFadeOver = useRef<HTMLDivElement>(null);
    const refTimeout = useRef<NodeJS.Timeout | null>();
    const [showDetail, setShowDetail] = useState<boolean>();

    useLayoutEffect(() => {
        if (selection?.status === 'Selected') {
            const rect = refTile.current?.parentElement?.getBoundingClientRect();
            const root = getParent(refTile.current, (e) => e.classList.contains('nuka-container'))?.getBoundingClientRect();
            const page = getParent(refTile.current, (e) => e.id === 'layoutRoot')?.getBoundingClientRect();

            if (refTileOver.current?.style && refTileFadeOver.current?.style && refDetailTile.current?.style && rect && root && page) {
                const left = rect.left - root.left;
                const top = rect.top - root.top;

                refTileOver.current.style.left = `${left}px`;
                refTileOver.current.style.top = `${top}px`;
                refTileOver.current.style.height = `${rect.height}px`;
                refTileOver.current.style.width = `${rect.width}px`;

                refDetailTile.current.style.left = `${page.left}px`;
                refDetailTile.current.style.top = `${page.top}px`;
                refDetailTile.current.style.height = `${page.height}px`;
                refDetailTile.current.style.width = `${page.width}px`;

                refTileFadeOver.current.style.left = `${page.left}px`;
                refTileFadeOver.current.style.top = `${page.top}px`;
                refTileFadeOver.current.style.height = `${page.height}px`;
                refTileFadeOver.current.style.width = `${page.width}px`;

                const tileCenter = { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };
                const pageCenter = { x: page.width / 2, y: page.height / 2 };
                const scaleX = page.width / rect.width;
                const scaleY = page.height / rect.height;
                const translateX = pageCenter.x - tileCenter.x;
                const translateY = pageCenter.y - tileCenter.y + page.top;
                document.body.style.setProperty('--thumbnailMobileScaleX', scaleX.toString());
                document.body.style.setProperty('--thumbnailMobileScaleY', scaleY.toString());
                document.body.style.setProperty('--thumbnailMobileTranslateX', `${translateX}px`);
                document.body.style.setProperty('--thumbnailMobileTranslateY', `${translateY}px`);
            }
            refTimeout.current = setTimeout(() => {
                setShowDetail(true);
                refTimeout.current = null;
            }, 100);
        } else if (selection?.status === 'UnSelecting') {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
            setShowDetail(false);
            setTimeout(() => {
                if (onSelect) {
                    onSelect({ status: 'UnSelected', origin: 'Click' });
                }
            }, 500);
        }
    }, [onSelect, selection]);
    const close = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        if (onSelect) {
            onSelect({ status: 'UnSelecting', origin: 'Click' });
        }
    };
    const overClassname = useMemo(() => {
        if (selection?.status === 'Selected') {
            return 'show';
        }
        if (selection?.status === 'UnSelecting') {
            return 'hide';
        }
        return undefined;
    }, [selection?.status]);
    return (
        <div
            className={clsx('thumbnailItemMobile', position)}
            style={{ width, height }}
            onClick={(ev) => {
                if (!disableActions) {
                    if (onSelect) {
                        onSelect({ status: 'Selected', origin: 'Click' });
                    }
                }
                ev.stopPropagation();
            }}
            onDoubleClick={(ev) => {
                if (!disableActions) {
                    if (onOpen) {
                        onOpen();
                    }
                }
                ev.stopPropagation();
            }}
        >
            <div ref={refTile}>
                <ThumbnailImage width={width} height={height} isMobileView thumbnailPath={item.data.thumbnailPath} />
            </div>
            <div className={clsx('thumbnailItemMobileOver', overClassname)} ref={refTileOver} onClick={close}>
                <ThumbnailImage width={width} height={height} isMobileView thumbnailPath={item.data.thumbnailPath} />
            </div>
            <div className={clsx('thumbnailItemMobileFadeOver', overClassname)} ref={refTileFadeOver} onClick={close}></div>
            <div
                ref={refDetailTile}
                className={clsx('detailMobilePanel', showDetail ? 'show' : 'hide')}
                onTouchStart={(ev) => ev.stopPropagation()}
                onTouchMove={(ev) => ev.stopPropagation()}
                onTouchStartCapture={(ev) => ev.stopPropagation()}
                onClick={(ev) => ev.stopPropagation()}
            >
                <Icon className='close' iconName='Cancel' onClick={close} />
                <ThumbnailImage thumbnailPath={item.data.thumbnailPath} />
                <ThumbnailItemDetails type={item.type} data={item.data} onOpen={onOpen} />
            </div>
        </div>
    );
};
