import { IProgressIndicatorStyleProps, IProgressIndicatorStyles, IStyleFunctionOrObject, PrimaryButton, ProgressIndicator } from '@fluentui/react';
import BigNumber from 'bignumber.js';
import { FC, useCallback, useMemo } from 'react';
import { ResourceViewer } from '../../../common/components/ResourcePackage/ResourceViewer';

import clsx from 'clsx';
import { useDynamicData } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { IQuestionnaryQuestion, IQuestionnarySubmissionItem, QuestionTypeEnum } from '../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../common/components/ExpressionField/Hook/useBracketResolver';
import { imageEffectPredefined } from '../../../common/components/ImageViewer/ImageEffectPreDefined';
import { QuestionChoice } from './QuestionType/QuestionChoice';
import { QuestionFreeText } from './QuestionType/QuestionFreeText';
import { QuestionYesNo } from './QuestionType/QuestionYesNo';

const indicatorStyle: IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles> | undefined = {
    progressBar: { borderRadius: 10 },
    progressTrack: { borderRadius: 10 },
    itemName: { textAlign: 'center', fontStyle: 'italic' },
};
export interface QuestionPageProps {
    questionnaryTitle: string;
    questionNumber: number;
    nbQuestions: number;
    question: IQuestionnaryQuestion;
    answer: IQuestionnarySubmissionItem | undefined;
    onAnswer: (answer: Partial<IQuestionnarySubmissionItem>) => void;
    onNext: () => void;
    onBack: () => void;

    canGoNext: boolean;
}
export const QuestionPage: FC<QuestionPageProps> = (props: QuestionPageProps) => {
    const { question, onAnswer, onNext, onBack, canGoNext, nbQuestions, questionNumber, answer, questionnaryTitle } = props;

    const percentage = useMemo(() => {
        return new BigNumber(questionNumber).dividedBy(nbQuestions).toNumber();
    }, [questionNumber, nbQuestions]);

    const imageEffect = useMemo(() => {
        if (question.imageEffect?.preDefined) {
            return { ...imageEffectPredefined[question.imageEffect.preDefined], shape: question.imageEffect?.shape };
        }
        return undefined;
    }, [question.imageEffect]);
    const dynData = useDynamicData();
    const resolvedQuestionnaryTitle = useBracketResolver(questionnaryTitle, dynData);
    const resolvedQuestionText = useBracketResolver(question.text, dynData);

    const onClickAnswer = useCallback((freeText: string | undefined) => onAnswer({ freeText }), [onAnswer]);
    const onClickAnswers = useCallback((answers: string[] | undefined) => onAnswer({ answers }), [onAnswer]);
    const onClickAnswerBoolean = useCallback((answerYes: boolean | undefined) => onAnswer({ answerYes }), [onAnswer]);

    return (
        <div className='questionPage'>
            <div className='header'>
                <span>{resolvedQuestionnaryTitle}</span>
                <ProgressIndicator styles={indicatorStyle} barHeight={10} description={`Question ${questionNumber} sur ${nbQuestions}`} percentComplete={percentage} />
            </div>
            <div className={clsx('content')}>
                <h2>{resolvedQuestionText}</h2>
                {question.imageResourceId ? (
                    <div className='questionPicture'>
                        <ResourceViewer resourceId={question.imageResourceId} />
                    </div>
                ) : (
                    <></>
                )}
                {question.type === QuestionTypeEnum.YesNo ? <QuestionYesNo answer={answer?.answerYes} onAnswer={onClickAnswerBoolean} /> : <></>}
                {question.type === QuestionTypeEnum.Choice ? (
                    <QuestionChoice imageEffect={imageEffect} questionId={question.id ?? ''} answerList={question.answers ?? []} answer={answer?.answers} onAnswer={onClickAnswers} />
                ) : (
                    <></>
                )}
                {question.type === QuestionTypeEnum.MultiChoice ? (
                    <QuestionChoice multiple imageEffect={imageEffect} questionId={question.id ?? ''} answerList={question.answers ?? []} answer={answer?.answers} onAnswer={onClickAnswers} />
                ) : (
                    <></>
                )}
                {question.type === QuestionTypeEnum.FreeText ? <QuestionFreeText answer={answer?.freeText} onAnswer={onClickAnswer} /> : <></>}
            </div>
            <div className='footer'>
                <PrimaryButton className='sakuraButton' iconProps={{ iconName: 'ChevronLeftSmall' }} text={'Précédent'} onClick={onBack} />
                <PrimaryButton
                    className='sakuraButton'
                    disabled={!canGoNext}
                    menuIconProps={{ iconName: 'ChevronRightSmall' }}
                    text={questionNumber !== nbQuestions ? 'Suivant' : 'Terminer'}
                    onClick={onNext}
                />
            </div>
        </div>
    );
};
