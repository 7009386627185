import { FC, Suspense, lazy } from 'react';

import { Pivot, PivotItem, Spinner, SwatchColorPicker } from '@fluentui/react';

import { mobileViewSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { IAvatar } from '../../../Services/SakuraApiClient';
import './AvatarEditor.scss';
import { accessoriesTypes, clothTypes, clotheColors, eyeBrowTypes, eyeTypes, facialHairColors, facialHairTypes, hairColors, mouthTypes, skinColors, topTypes } from './Constantes';

export interface AvatarEditorProps {
    avatar: IAvatar;
    onChangeAvatar: (avatar: IAvatar) => void;
}

const Avatar = lazy(() => import('avataaars'));
const SelectionPanel = lazy(() => import('./SelectionPanel'));
export const AvatarEditor: FC<AvatarEditorProps> = (props: AvatarEditorProps) => {
    const { avatar, onChangeAvatar } = props;

    const isMobileView = useAppSelector(mobileViewSelector);

    return (
        <div className='avatarEditor'>
            <Suspense fallback={<Spinner label='Chargement...' />}>
                <Avatar
                    className='avatarView'
                    avatarStyle='Circle'
                    topType={avatar.topType}
                    accessoriesType={avatar.accessoriesType}
                    hairColor={avatar.hairColor}
                    facialHairType={avatar.facialHairType}
                    facialHairColor={avatar.facialHairColor}
                    clotheType={avatar.clotheType}
                    clotheColor={avatar.clotheColor}
                    eyeType={avatar.eyeType}
                    eyebrowType={avatar.eyebrowType}
                    mouthType={avatar.mouthType}
                    skinColor={avatar.skinColor}
                ></Avatar>

                <Pivot style={{ marginBottom: 5, marginTop: 10 }} overflowBehavior={'menu'} overflowAriaLabel='Plus de catégories'>
                    <PivotItem headerText='Cheveux'>
                        <SelectionPanel
                            pieceType='top'
                            collection={topTypes}
                            selectedValue={avatar.topType ?? 'LongHairBigHair'}
                            onChangeValue={(val) => onChangeAvatar({ ...avatar, topType: val })}
                            pieceSize='100'
                            color={avatar.hairColor}
                            colorCollection={hairColors}
                            onChangeColor={(colorId) => onChangeAvatar({ ...avatar, hairColor: colorId ?? '' })}
                        />
                    </PivotItem>
                    <PivotItem headerText='Peau'>
                        <div className='selectionPanel'>
                            <SwatchColorPicker
                                columnCount={skinColors.length}
                                cellShape={'circle'}
                                colorCells={skinColors.map((c) => ({
                                    id: c.name,
                                    label: c.name,
                                    color: c.value,
                                }))}
                                onChange={(_, colorId) => onChangeAvatar({ ...avatar, skinColor: colorId ?? '' })}
                                selectedId={avatar.skinColor ?? 'Light'}
                            />
                        </div>
                    </PivotItem>
                    <PivotItem headerText='Yeux'>
                        <SelectionPanel
                            pieceType='eyes'
                            collection={eyeTypes}
                            selectedValue={avatar?.eyeType ?? 'Happy'}
                            onChangeValue={(val) => onChangeAvatar({ ...avatar, eyeType: val })}
                            pieceSize='100'
                            viewBox='0 0 120 50'
                        />
                    </PivotItem>
                    <PivotItem headerText='Sourcils'>
                        <SelectionPanel
                            pieceType='eyebrows'
                            collection={eyeBrowTypes}
                            selectedValue={avatar?.eyebrowType ?? 'Default'}
                            onChangeValue={(val) => onChangeAvatar({ ...avatar, eyebrowType: val })}
                            pieceSize='100'
                            viewBox='0 0 132 30'
                        />
                    </PivotItem>
                    <PivotItem headerText='Bouches'>
                        <SelectionPanel
                            pieceType='mouth'
                            collection={mouthTypes}
                            selectedValue={avatar?.mouthType ?? 'Smile'}
                            onChangeValue={(val) => onChangeAvatar({ ...avatar, mouthType: val })}
                            pieceSize='100'
                            viewBox='0 0 132 140'
                        />
                    </PivotItem>
                    <PivotItem headerText='Barbe'>
                        <SelectionPanel
                            pieceType='facialHair'
                            collection={facialHairTypes}
                            selectedValue={avatar?.facialHairType ?? 'Blank'}
                            onChangeValue={(val) => onChangeAvatar({ ...avatar, facialHairType: val })}
                            pieceSize='100'
                            color={avatar?.facialHairColor ?? 'Black'}
                            colorCollection={facialHairColors}
                            onChangeColor={(colorId) => onChangeAvatar({ ...avatar, facialHairColor: colorId ?? '' })}
                        />
                    </PivotItem>

                    <PivotItem headerText='Vétements'>
                        <SelectionPanel
                            pieceType='clothe'
                            collection={clothTypes}
                            selectedValue={avatar?.clotheType ?? 'Hoodie'}
                            onChangeValue={(val) => onChangeAvatar({ ...avatar, clotheType: val })}
                            pieceSize='100'
                            color={avatar.clotheColor}
                            colorCollection={clotheColors}
                            onChangeColor={(colorId) => onChangeAvatar({ ...avatar, clotheColor: colorId ?? '' })}
                            colorColumnCount={isMobileView ? 10 : undefined}
                        />
                    </PivotItem>
                    <PivotItem headerText='Lunettes'>
                        <SelectionPanel
                            pieceType='accessories'
                            collection={accessoriesTypes}
                            selectedValue={avatar?.accessoriesType ?? 'Blank'}
                            onChangeValue={(val) => onChangeAvatar({ ...avatar, accessoriesType: val })}
                            pieceSize='100'
                        />
                    </PivotItem>
                </Pivot>
            </Suspense>
        </div>
    );
};
