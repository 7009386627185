//note that we don't use export default here
//create context here, use context in others

import { createContext } from '@fluentui/react-context-selector';
import { FC, PropsWithChildren, useMemo, useReducer } from 'react';
import { IAccountDataResource, IResourceHierarchyView } from '../../../../Services/SakuraApiClient';
import { INavMenuEntry } from '../../../../common/components/NavMenu';
import { jsonParse } from '../../../../common/helpers/Json';
import { FormationContent } from '../helper';
import { defaultAction, FormationActions, useActions } from './FormationContextActions';
import { formationContextReducer, FormationContextReducerType } from './FormationContextReducer';
import { IFormationContextState } from './IFormationContextState';
import { ServerSyncAction } from './ServerSync/ServerSync';
import { buildFormationUserData, FormationUserData } from './helper';

const emptyContext: IFormationContextState = { serializationIdentifier: undefined, menu: [], userData: { userDataIndex: {}, userDatas: [] }, selectedContentPath: undefined, serverSyncActions: [] };

//this DataContext will be shared by all the components
export interface FormationContextValue {
    menu: INavMenuEntry<IResourceHierarchyView>[] | undefined;
    userData: FormationUserData | undefined;
    currentUserData: IAccountDataResource | undefined;
    selectedContentPath: string | undefined;
    selectedContent: IResourceHierarchyView | undefined;
    actions: FormationActions;
    serverSyncActions: ServerSyncAction[];
}
const formationContextDefaultValue: FormationContextValue = {
    menu: undefined,
    userData: undefined,
    currentUserData: undefined,
    selectedContentPath: undefined,
    selectedContent: undefined,
    actions: defaultAction,
    serverSyncActions: [],
};

export const FormationDataContext = createContext<FormationContextValue>(formationContextDefaultValue);

export interface FormationContextProviderProps {
    formationContent: FormationContent;
    serializationIdentifier: string | undefined;
}

export const FormationContextProvider: FC<PropsWithChildren<FormationContextProviderProps>> = (props: PropsWithChildren<FormationContextProviderProps>) => {
    const { children, serializationIdentifier, formationContent } = props;

    const [state, dispatch] = useReducer<FormationContextReducerType, IFormationContextState>(
        formationContextReducer,
        { ...emptyContext, serializationIdentifier, menu: formationContent.menu, userData: buildFormationUserData(formationContent.userData, formationContent.content) },
        (arg) => {
            arg.selectedContentPath = arg.userData.userDatas.length > 0 ? arg.userData.userDatas[0].resourcePath : undefined;
            if (arg.selectedContentPath) {
                arg.serverSyncActions = [{ type: 'TrackAccess', payload: { resourcePath: arg.selectedContentPath } }];
            }
            if (serializationIdentifier) {
                const json = localStorage.getItem(`ExecData_${serializationIdentifier}`);
                if (json) {
                    const existingState = jsonParse(json) as IFormationContextState;
                    return existingState;
                }
            }
            return { ...arg, serializationIdentifier };
        },
    );
    const actions = useActions(dispatch);
    const value = useMemo((): FormationContextValue => {
        let currentUserData: IAccountDataResource | undefined = undefined;
        if (state.selectedContentPath) {
            const index = state.userData.userDataIndex[state.selectedContentPath];
            currentUserData = state.userData.userDatas[index]?.data;
        }

        return {
            menu: state.menu,
            userData: state.userData,
            currentUserData,
            selectedContentPath: state.selectedContentPath,
            selectedContent: state.selectedContentPath ? formationContent.content[state.selectedContentPath] : undefined,
            actions,
            serverSyncActions: state.serverSyncActions,
        };
    }, [state, formationContent.content, actions]);

    return <FormationDataContext.Provider value={value}>{children}</FormationDataContext.Provider>;
};

export function deletePersistantExecutionDataContext(identifier: string) {
    localStorage.removeItem(`ExecData_${identifier}`);
}
