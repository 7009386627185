import { FC, useState } from 'react';
import { ResourceWorkflowGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { setPopupMessage } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { ResourceList } from '../ResourceList';
import { ResourceWorkflowDetails } from './ResourceWorkflowDetails';

export const ResourceWorkflowList: FC = () => {
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<'New' | 'Edit' | undefined>();

    const appDispatch = useAppDispatch();

    return (
        <Panel
            title='Liste des workflows'
            icon={GetResourceTypeIcon(ResourceTypeEnum.Workflow)}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        setShowEditDialog('New');
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditDialog('Edit');
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedResource,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'SetAction',
                    text: 'Tester',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            performApiCall(async (client, cancelToken) => {
                                await client.runWorkflow(selectedResource?.id);
                                appDispatch(
                                    setPopupMessage({
                                        message: {
                                            title: 'Workflow',
                                            buttonText: 'ok',
                                            subTitle: 'workflow executé.',
                                        },
                                    }),
                                );
                            }, appDispatch);
                        }
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ResourceWorkflowGrid.refresh();
                    },
                },
            ]}
        >
            <ResourceList
                datasetName={'ResourceWorkflowGrid'}
                onSelect={(selection) => setSelectedResource(selection)}
                extraColumns={[
                    {
                        key: 'Identifiant',
                        name: 'Identifiant',
                        fieldName: 'id',
                        minWidth: 100,
                        maxWidth: 100,
                        allowSorting: true,
                        isResizable: true,
                        onRender: (item: IResource) => {
                            return <span>{item.id}</span>;
                        },
                    },
                    {
                        key: 'nbActions',
                        name: 'Actions',
                        fieldName: 'actions',
                        minWidth: 100,
                        maxWidth: 100,
                        allowSorting: true,
                        isResizable: true,
                        onRender: (item: IResource) => {
                            return <span>{item.data.content.workflow?.actions?.length}</span>;
                        },
                    },
                ]}
                onRowDoubleClick={(item) => {
                    setSelectedResource(item);
                    setShowEditDialog('Edit');
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Workflow) }}
                show={showDeleteDialog}
                title='Suppression du workflow'
                subText='Etes-vous sur de vouloir supprimer ce workflow?'
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedResource?.id) {
                                    await client.deleteResource(selectedResource.id);
                                    setShowDeleteDialog(false);
                                    ResourceWorkflowGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                    }
                }}
            />
            {showEditDialog && (
                <ResourceWorkflowDetails
                    resource={showEditDialog === 'Edit' ? selectedResource : undefined}
                    show={showEditDialog !== undefined}
                    onClose={(res) => {
                        setShowEditDialog(undefined);
                        if (res) {
                            ResourceWorkflowGrid.refresh();
                        }
                    }}
                />
            )}
        </Panel>
    );
};
