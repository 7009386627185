import { MenuEntryType, MenuListName, RoleEnum } from '../../../../Services/SakuraApiClient';
import { generateUniqueId } from '../../../../common/helpers/uniqueId';

export enum BuildinPageName {
    Account = 'account',
    Data = 'data',
}
export const listOfBuildinPageName = [BuildinPageName.Account];

export interface IMenuSetting {
    id: string;
    children: MenuSettingEntry[];
}
export interface MenuSettingEntry {
    id: string;
    type: MenuEntryType;
    key: string;
    title: string;
    visibleIf?: string | undefined;
    icon?: string | undefined;
    svgPath?: string | undefined;
    externalUrl?: string | undefined;
    page?: PageSetting | undefined;
    children?: MenuSettingEntry[] | undefined;
    buildinPageName?: BuildinPageName | undefined;
    listname?: MenuListName | undefined;
}
export interface PageSetting {
    defaultPageId?: number;
    pages: Record<RoleEnum, ConfiguredPage[]>;
}
export interface ConfiguredPage {
    pageId: number;
    criteriaDateFrom: Date | undefined;
    criteriaDateTo: Date | undefined;
}

export function menuSetting(data: any): IMenuSetting {
    return menuSettingInternal(data, 'root');
}

function menuSettingInternal(data: any, parentId: string): IMenuSetting {
    if (data?.children) {
        return {
            id: parentId,
            children: data.children.map((entry: MenuSettingEntry) => {
                return converItem(entry, parentId);
            }),
        };
    }

    return { id: parentId, children: [] };
}

function converItem(entry: MenuSettingEntry, parentId: string): MenuSettingEntry {
    const entryId = generateUniqueId(`${parentId}/`);
    const newItem = { ...entry, id: entryId };
    if (entry.page) {
        const pages = { ...entry.page.pages } as Record<string, ConfiguredPage[]>;
        Object.keys(pages).forEach((key) => {
            pages[key] = pages[key]?.map((m: ConfiguredPage) => ({
                ...m,
                criteriaDateFrom: m.criteriaDateFrom ? new Date(Date.parse(m.criteriaDateFrom as unknown as string)) : undefined,
                criteriaDateTo: m.criteriaDateTo ? new Date(Date.parse(m.criteriaDateTo as unknown as string)) : undefined,
            }));
        });
        newItem.page = { ...entry.page, pages };
    }
    if (newItem.children) {
        newItem.children = newItem.children.map((child) => converItem(child, entryId));
    }
    return newItem;
}
