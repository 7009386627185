import { ChoiceGroup, Dropdown, IChoiceGroupOption, TextField } from '@fluentui/react';
import { FC, useCallback, useState } from 'react';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource, Resource, ResourcePackage, ResourcePackageFull, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { useForm } from '../../../../common/Hooks/Form';
import * as validators from '../../../../common/Hooks/Form/validators';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';

import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { TemplateList } from '../../../Common/PageEditor/PageTemplate/TemplateList';
import { PageCategories, PageCategory } from './PageCategories';
import { getNewResourcePageData } from './ResourcePageEditor';

export interface NewPageDialogProps {
    createRecord: boolean;
    presetCategory?: PageCategory;
    onClose: (resPackage: ResourcePackageFull | undefined) => void;
}

const options: IChoiceGroupOption[] = [
    { key: 'blank', text: 'Page vide' },
    { key: 'template', text: "A partir d'un modéle" },
];

export const NewPageDialog: FC<NewPageDialogProps> = (props: NewPageDialogProps) => {
    const { createRecord, presetCategory, onClose } = props;
    const appDispatch = useAppDispatch();
    const [template, setTemplate] = useState<{ template: IResource | undefined; resPackage: ResourcePackageFull | undefined }>();
    const [choice, setChoice] = useState<'blank' | 'template' | string>('blank');
    const form = useForm<IResource>(
        {
            initialState: getNewResourcePageData(false),
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                },
            },
        },
        false,
        'createTemplate',
    );

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose(undefined);
    };
    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            const page = form.state;
            if (choice === 'template' && template) {
                page.data = template?.template?.data;
            }
            const resource = new Resource();
            resource.init(page);
            if (presetCategory) {
                resource.category = presetCategory;
            }
            if (createRecord) {
                performApiCall(
                    async (client, cancelToken) => {
                        const response = await client.createResource(resource, cancelToken);
                        const resPackage = new ResourcePackageFull();
                        resPackage.resource = response;
                        resPackage.resourcePackage = new ResourcePackage();
                        resPackage.resourcePackage.innerResourcePackages = template?.resPackage?.resourcePackage.innerResourcePackages;
                        onClose(resPackage);
                    },
                    appDispatch,
                    {
                        contextId: 'newPageDialog',
                    },
                );
            } else {
                const resPackage = new ResourcePackageFull();
                resPackage.resource = resource;
                resPackage.resourcePackage = new ResourcePackage();
                resPackage.resourcePackage.innerResourcePackages = template?.resPackage?.resourcePackage.innerResourcePackages;
                onClose(resPackage);
            }
        }
    };

    const onSelect = useCallback(
        (selection: IResource | undefined, selectionPackageFull: ResourcePackageFull | undefined) => {
            setTemplate({ template: selection, resPackage: selectionPackageFull });
            if (selection) {
                setChoice('template');
            } else {
                setChoice('blank');
            }
        },
        [setTemplate, setChoice],
    );
    return (
        <EditDialog
            id={'newPageDialog'}
            icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Page) }}
            mode={'Create'}
            title={'Créer une nouvelle page'}
            show={true}
            onSubmit={onSubmit}
            canSubmit={() => {
                return choice === 'blank' || (choice === 'template' && template?.template !== undefined);
            }}
            onClose={() => close(undefined)}
        >
            <span>{'Spécifier le nom de la page'}</span>
            <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
            <Dropdown
                label='Category'
                disabled={presetCategory !== undefined}
                defaultSelectedKey={presetCategory}
                options={PageCategories}
                selectedKey={form.state.category}
                onChange={(_, v) => form.update({ category: v?.key?.toString() })}
            ></Dropdown>
            <ChoiceGroup
                options={options}
                selectedKey={choice}
                onChange={(_, option) => {
                    setChoice(option?.key as string);
                    if (option?.key === 'blank') {
                        setTemplate(undefined);
                    }
                }}
            />
            <TemplateList contextId='fromTemplate' datasetName={'ResourcePageTemplateGrid'} selection={template?.template} onSelect={onSelect} />
        </EditDialog>
    );
};
