import { AssignmentResult } from './AssignmentResult';
import { ExpressionBlock } from './ExpressionBlock';
import { ExpressionComparator } from './ExpressionComparator';
import { ExpressionFunction } from './ExpressionFunction';
import { ExpressionItem } from './ExpressionItem';
import { ExpressionVariable } from './ExpressionVariable';
import { Value, ValueTyping } from './Value';

export interface BracketItemResolved {
    beginIndex: number;
    endIndex: number;
    shift: number;
    expression: Expression;
    text: string;
}
export interface BracketResolved {
    newContent: string;
    items: BracketItemResolved[];
}

export class Expression {
    content: ExpressionItem[];

    constructor(expression: string, variableResolver: (variableName: string[]) => ValueTyping) {
        this.content = Expression.parse(expression, variableResolver);
    }
    static parse(text: string, variableResolver: ((variableName: string[]) => ValueTyping) | undefined): ExpressionItem[] {
        const expressions: ExpressionItem[] = [];
        const blocks = ExpressionBlock.extractBlocks(text);
        let previous: ExpressionBlock | null = null;
        for (const block of blocks) {
            const itemBlock = new ExpressionBlock(block, variableResolver);

            if (previous !== null) {
                previous.attachTo(itemBlock);
                previous.items[previous.items.length - 1].attachTo(itemBlock);
            }

            previous = itemBlock;
            expressions.push(itemBlock);
        }

        return expressions;
    }
    static validate(expressionString: string, checkAssignment: boolean): string | undefined {
        if (!expressionString) {
            return undefined;
        }

        const parentheses = Expression.checkParenthesesBalance(expressionString);
        if (parentheses === 'OpenIssue') {
            return 'Trop de parenthèse ouvrante.';
        }
        if (parentheses === 'closeIssue') {
            return 'Trop de parenthèse fermante.';
        }
        if (parentheses === 'Invalid') {
            return 'Erreur de parenthèse.';
        }
        if (checkAssignment) {
            const exp = new Expression(expressionString, (a) => {
                //FAKE
                return a.join('.');
            });
            const err = exp.validateAssignExpression();
            return err;
        }
        return undefined;
    }

    private static checkParenthesesBalance(input: string): 'OK' | 'OpenIssue' | 'closeIssue' | 'Invalid' {
        let openParenthesesCount = 0;
        let closeParenthesesCount = 0;

        for (const c of input) {
            if (c === '(') {
                openParenthesesCount++;
            } else if (c === ')') {
                closeParenthesesCount++;
            }
        }

        if (closeParenthesesCount > openParenthesesCount) {
            return 'closeIssue'; // Parenthèse fermante sans correspondance.
        }
        if (openParenthesesCount > closeParenthesesCount) {
            return 'OpenIssue'; // Parenthèse fermante sans correspondance.
        }

        return openParenthesesCount === closeParenthesesCount ? 'OK' : 'Invalid';
    }

    evalCondition(): boolean {
        if (this.content && this.content.length > 0) {
            const resolvedExpression = this.content[0].resolve();
            const value = resolvedExpression.getValue();

            if (value && value.type === 'boolean' && value.valueObject !== null) {
                return value.valueObject as boolean;
            }
        }

        return false;
    }
    public validateAssignExpression() {
        if (this.content && this.content.length > 0) {
            const firstBlock = this.content[0] as ExpressionBlock;

            if (firstBlock && firstBlock.items.length > 1) {
                const variableItem = firstBlock.items[0] as ExpressionVariable;
                const assignment = firstBlock.items[1] as ExpressionComparator;

                if (!variableItem) {
                    return "l'expression doit commencer par un nom de variable. (data.var1 =)";
                }

                if (!assignment || assignment.text !== '=') {
                    return "l'expression doit contenir un '=' apres le nom de variable";
                }

                let expression = assignment.next;

                if (!expression) {
                    if (this.content.length > 1) {
                        expression = this.content[1]; // Next block
                    } else {
                        return "l'expression doit contenir une valeur apres le '='.";
                    }
                }
                return undefined;
            }
        }
        return "l'expression doit contenir 3 parties. [variable] = [value]";
    }
    evalAssignment(): AssignmentResult {
        if (this.content && this.content.length > 0) {
            const firstBlock = this.content[0] as ExpressionBlock;

            if (firstBlock && firstBlock.items.length > 1) {
                const variableItem = firstBlock.items[0] as ExpressionVariable;
                const assignment = firstBlock.items[1] as ExpressionComparator;

                if (!variableItem) {
                    throw new Error('Assignment Expression must start with a variable.');
                }

                if (!assignment || assignment.text !== '=') {
                    throw new Error("Assignment Expression must contain an '='.");
                }

                let expression = assignment.next;

                if (!expression) {
                    if (this.content.length > 1) {
                        expression = this.content[1]; // Next block
                    } else {
                        throw new Error("Assignment Expression must contain content after '='.");
                    }
                }
                if (expression) {
                    expression.previous = undefined; // Break the chain.
                }

                const resolvedExpression = expression.resolve();
                const value = resolvedExpression.getValue();

                return {
                    variableName: variableItem.variableName,
                    value,
                };
            }
        }
        throw new Error('Assignment Expression must contain at least 3 parts.');
    }
    evalText(): string {
        if (this.content && this.content.length > 0) {
            const resolvedExpression = this.content[0].resolve();
            const value = resolvedExpression.getValue();
            if (value && value.valueObject !== null) {
                const result = Value.Transtype(value, 'string');
                return result.valueObject as string;
            }
        }
        return '';
    }
    getListOfVariableUsed(): ExpressionVariable[] {
        const result: ExpressionVariable[] = [];
        this.getAllItems(this.content, (item: ExpressionItem) => {
            if (item instanceof ExpressionVariable) {
                const variable = item as ExpressionVariable;
                result.push(variable);
            }
        });
        return result;
    }
    private getAllItems(items: ExpressionItem[], callback: (item: ExpressionItem) => void) {
        items.forEach((c) => {
            callback(c);
            if (c instanceof ExpressionBlock) {
                const block = c as ExpressionBlock;
                this.getAllItems(block.items, callback);
            } else if (c instanceof ExpressionFunction) {
                const func = c as ExpressionFunction;
                this.getAllItems(func.Parameters, callback);
            }
        });
    }
    public static getExpressionInBrackets(contentText: string) {
        return Expression.getExpressionStringInBrackets(contentText).map((e) => new Expression(e, () => undefined));
    }
    public static getExpressionStringInBrackets(contentText: string) {
        const items: string[] = [];

        let beginIndex = 0;
        for (let index = 0; index < contentText.length; index++) {
            if (index > 0 && contentText[index] === '{' && contentText[index - 1] === '{') {
                //start expression;
                beginIndex = index + 1;
            }
            if (index + 1 < contentText.length && contentText[index] === '}' && contentText[index + 1] === '}') {
                const expressionText = contentText.substring(beginIndex, index);
                items.push(expressionText);
            }
        }
        return items;
    }
    public static validateAllExpressionInBrackets(contentText: string) {
        const expressions = Expression.getExpressionStringInBrackets(contentText);
        for (let i = 0; i < expressions.length; i++) {
            const err = Expression.validate(expressions[i], false);
            if (err) {
                return err;
            }
        }
        return undefined;
    }
    public static resolveExpressionsInBrackets(contentText: string, variableResolver: (variableName: string[]) => ValueTyping): BracketResolved {
        const items: BracketItemResolved[] = [];
        let newContent = '';
        let beginIndex = 0;
        for (let index = 0; index < contentText.length; index++) {
            if (index > 0 && contentText[index] === '{' && contentText[index - 1] === '{') {
                //start expression;
                beginIndex = index + 1;
            }
            if (index + 1 < contentText.length && contentText[index] === '}' && contentText[index + 1] === '}') {
                const expressionText = contentText.substring(beginIndex, index);
                const expression = new Expression(expressionText, variableResolver);
                const text = expression.evalText();
                items.push({
                    beginIndex,
                    endIndex: index,
                    shift: text.length - (expressionText.length + 4),
                    text,
                    expression,
                });
            }
        }
        let startIndex = 0;
        items.forEach((item) => {
            newContent += contentText.substring(startIndex, item.beginIndex - 2);
            newContent += item.text;
            startIndex = item.endIndex + 2;
        });
        if (startIndex !== contentText.length) {
            newContent += contentText.substring(startIndex, contentText.length);
        }
        return {
            items,
            newContent,
        };
    }
}
