import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleDialog } from '../../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { ResourceConsumerPackage } from '../../../../Services/SakuraApiClient';
import { QuestionnaryUI } from '../../../Common/Questionnary/QuestionnaryUI';

export interface QuestionnaryTesterProps {
    questionnaryResourceId: number;
    onClose: () => void;
}

export const QuestionnaryTester: FC<QuestionnaryTesterProps> = (props: QuestionnaryTesterProps) => {
    const { questionnaryResourceId, onClose } = props;

    const [resourcePackage, setResourcePackage] = useState<ResourceConsumerPackage | undefined>();
    const dispatch = useDispatch();
    useEffect(() => {
        performApiCall(async (apiCall, c) => {
            const resPackage = await apiCall.getResourceConsumerPackage(questionnaryResourceId, c);
            setResourcePackage(resPackage);
        }, dispatch);
    }, [questionnaryResourceId, dispatch]);

    return (
        <>
            {resourcePackage?.questionnary ? (
                <SimpleDialog id={'previewQuestionnary'} title={'Prévisualisation'} show={true} icon={{ iconName: 'PreviewLink' }} onClose={onClose}>
                    <div className='questionnaryTester'>
                        <QuestionnaryUI
                            questionnary={resourcePackage.questionnary}
                            resourceId={questionnaryResourceId}
                            accountDataResource={resourcePackage.accountResourceData}
                            enablePersitance={false}
                            onClosed={() => onClose()}
                        />
                    </div>
                </SimpleDialog>
            ) : (
                <></>
            )}
        </>
    );
};
