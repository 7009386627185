import { Pivot, PivotItem } from '@fluentui/react';
import { FC } from 'react';
import { IPageElementLabel } from '../../../../../Services/SakuraApiClient';
import { ExpressionField, ExpressionKind } from '../../../../../common/components/ExpressionField/ExpressionField';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { CssCommonField } from '../Common/CssCommonField';
import { FontStyle } from '../Common/FontStyle';
import { TextAlign } from '../Common/TextAlign';

export interface LabelElementPanelProps {
    data: IPageElementLabel;
    onChangeData: (data: DeepPartial<IPageElementLabel>) => void;
}

export const LabelElementPanel: FC<LabelElementPanelProps> = (props: LabelElementPanelProps) => {
    const { onChangeData, data } = props;

    return (
        <>
            <Pivot>
                <PivotItem headerText='Propriétés'>
                    <ExpressionField
                        kind={ExpressionKind.TextWithExpression}
                        label='Texte'
                        value={data.value}
                        onChange={(value) => {
                            onChangeData({ value });
                        }}
                        showExpandButton
                    />
                    <TextAlign label='Alignement du texte' value={data.style?.textAlignement} onChange={(textAlignement) => onChangeData({ style: { textAlignement } })} />
                </PivotItem>
                <PivotItem headerText='Style'>
                    <FontStyle
                        font={data.style?.font}
                        onChangeFont={(font) => {
                            onChangeData({ style: { font } });
                        }}
                    />
                    <CssCommonField data={data.style} onChange={(style) => onChangeData({ style })} />
                </PivotItem>
            </Pivot>
        </>
    );
};
