export interface GradiantData {
    deg: number;
    color1: string;
    color2: string;
}

export function isGradiantColor(color: string) {
    return color.startsWith('linear-gradient');
}
const regExpGradiant =
    /linear-gradient\((?<deg>[0-9]+)deg,(?<color1>(#[0-9a-f]{3,6})|(rgba\([0-9. ]+,[0-9. ]+,[0-9. ]+,[0-9. ]+\))),(?<color2>(#[0-9a-f]{3,6})|(rgba\([0-9. ]+,[0-9. ]+,[0-9. ]+,[0-9. ]+\)))\)/;
export function getGradiantDataFromString(gradiant: string) {
    const match = gradiant.match(regExpGradiant);

    if (match?.groups) {
        const deg = match.groups['deg'];
        const color1 = match.groups['color1'];
        const color2 = match.groups['color2'];
        return {
            deg: Number.parseInt(deg, 10),
            color1,
            color2,
        };
    }
    return undefined;
}

export function getStringFromGradiantData(gradiant: GradiantData) {
    return `linear-gradient(${gradiant.deg}deg,${gradiant.color1},${gradiant.color2})`;
}
