import { FC } from 'react';
import { ResourceLookUp } from '../../../Redux/Reducers/DataSet/DataSetName';
import { IResource } from '../../../Services/SakuraApiClient';
import { ResourceAudioDetails } from './Audio/ResourceAudioDetails';
import { ResourceDocumentDetails } from './Document/ResourceDocumentDetails';
import { ResourceImageDetails } from './Image/ResourceImageDetails';
import { ResourceVideoDetails } from './Video/ResourceVideoDetails';

export interface ResourceDetailsProps {
    resource?: IResource;
    type: ResourceLookUp;
    show: boolean;
    category?: string;
    onClose: (resource: IResource | undefined) => void;
}

export const ResourceDetails: FC<ResourceDetailsProps> = (props: ResourceDetailsProps) => {
    const { resource, type, show, onClose, category } = props;

    switch (type) {
        case 'ResourceImageLookup':
        case 'ResourceImageContentLookup':
        case 'ResourceImageVignetteLookup':
        case 'ResourceImagePubliciteLookup':
            return <ResourceImageDetails resource={resource} category={category} show={show} onClose={onClose} />;
        case 'ResourceAudioLookup':
            return <ResourceAudioDetails resource={resource} category={category} show={show} onClose={onClose} />;
        case 'ResourceDocumentLookup':
            return <ResourceDocumentDetails resource={resource} category={category} show={show} onClose={onClose} />;
        case 'ResourceVideoLookup':
            return <ResourceVideoDetails resource={resource} category={category} show={show} onClose={onClose} />;
    }
    return <></>;
};
