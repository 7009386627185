import { FC } from 'react';
import { setPopupMessage, usePopupMessage } from './Redux/Reducers/System/reducer';
import { useAppDispatch } from './Redux/hook';
import { SimpleDialog } from './common/components/Dialog/SimpleDialog/SimpleDialog';

export const SystemPopup: FC = () => {
    const appDispatch = useAppDispatch();
    const popUpMessage = usePopupMessage();

    return (
        <>
            {popUpMessage ? (
                <SimpleDialog
                    id='PopupMessageSystem'
                    show={true}
                    title={popUpMessage.title}
                    subText={popUpMessage.subTitle}
                    timeout={popUpMessage.timeout}
                    icon={popUpMessage.icon ? { iconName: popUpMessage.icon } : undefined}
                    closeButtonText={popUpMessage.buttonText ?? 'Ok'}
                    onClose={() => {
                        appDispatch(setPopupMessage({ message: undefined }));
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );
};
