import { FC, useCallback, useMemo, useRef } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { $getRoot, EditorState, SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { generateUniqueId } from '../../../helpers/uniqueId';
import ExpressionPlugin, { ExpressionPluginProps } from '../Plugins/ExpressionPlugin';
import OnChangePlugin from '../Plugins/OnChangePlugin';
import ReadOnlyPlugin from '../Plugins/ReadOnlyPlugin';
import ToolbarPlugin from '../Plugins/ToolBarPlugin';
import TreeViewDebuggerPlugin from '../Plugins/TreeViewDebuggerPlugin';
import { commonTheme } from '../Theming/commonTheme';
import '../Theming/lexical.scss';
import '../Theming/LexicalDebug.scss';
import './lexicalEditor.scss';

const theme = {
    ...commonTheme,
    paragraph: 'lexical-editor-paragraph',
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
    console.error(error);
}
export interface EditorContent {
    getPlainText: () => string;
    getJson: () => SerializedEditorState<SerializedLexicalNode>;
}
export interface RichTextLexicalEditorProps {
    content?: string | undefined;
    jsonState?: SerializedEditorState<SerializedLexicalNode>;
    expressionOptions?: ExpressionPluginProps;
    showTabBar?: boolean;
    readonly?: boolean;
    onChange: (content: EditorContent) => void;
    debugMode?: boolean;
    placeholder?: string;
}

const RichTextLexicalEditor: FC<RichTextLexicalEditorProps> = (props: RichTextLexicalEditorProps) => {
    const { content, onChange, expressionOptions, showTabBar, debugMode, readonly, placeholder, jsonState } = props;
    const editorID = useRef(generateUniqueId('lexicalEditor'));
    const initialConfig = useMemo(() => {
        return {
            namespace: 'SakuraLexicalEditor',
            theme,
            onError,
        };
    }, []);
    const onChangeStateHandler = useCallback(
        (editorState: EditorState) => {
            editorState.read(() => {
                onChange({
                    getJson: () => {
                        const editorStateJSON = editorState.toJSON();
                        return editorStateJSON;
                    },
                    getPlainText: () => {
                        const rootNode = $getRoot();
                        return rootNode.getTextContent();
                    },
                });
            });
        },
        [onChange],
    );
    const blockDeleteKey = useCallback((ev: React.KeyboardEvent<HTMLDivElement>) => {
        if (ev.key === 'Delete') {
            ev.stopPropagation();
        }
    }, []);
    const blockDrag = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        ev.stopPropagation();
        ev.preventDefault();
    }, []);
    return (
        <LexicalComposer initialConfig={initialConfig}>
            {showTabBar ? <ToolbarPlugin /> : null}
            <div className='lexical-editor-root' draggable={true} onDragStart={blockDrag} onKeyUp={blockDeleteKey}>
                <RichTextPlugin
                    contentEditable={<ContentEditable readOnly={readonly} />}
                    placeholder={placeholder ? <div className='lexical-editor-placeholder'>{placeholder}</div> : undefined}
                    ErrorBoundary={LexicalErrorBoundary}
                />
            </div>
            <ReadOnlyPlugin readonly={readonly ?? false} />
            <HistoryPlugin />

            {expressionOptions ? (
                <ExpressionPlugin
                    intelisenseDataSource={expressionOptions.intelisenseDataSource}
                    disableReadOnlyData={expressionOptions.disableReadOnlyData}
                    enabledBracketExpression={expressionOptions.enabledBracketExpression}
                />
            ) : null}

            <OnChangePlugin key={editorID.current} jsonState={jsonState} content={content} onChange={onChangeStateHandler} />
            {debugMode ? <TreeViewDebuggerPlugin /> : null}
        </LexicalComposer>
    );
};

export default RichTextLexicalEditor;
