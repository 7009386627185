import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import { FC, useCallback } from 'react';
import { MenuEntryType, MenuListName } from '../../../../Services/SakuraApiClient';
import { DeepPartial, FormError } from '../../../../common/Hooks/Form/useForm';
import { ExpressionField, ExpressionKind } from '../../../../common/components/ExpressionField/ExpressionField';
import { FluentIconSelectorDialog } from '../../../../common/components/Icons/FluentIconSelectorDialog';
import { PageSettingEdit } from './PageSettingEdit';
import { MenuHierarchyAction } from './formMenuHierarchySlicer';
import { BuildinPageName, MenuSettingEntry } from './menuSetting';

export interface MenuItemDetailProps {
    error: FormError<MenuSettingEntry> | undefined;
    data: MenuSettingEntry | undefined;
    updateForm: (action: MenuHierarchyAction) => void;
}
const actionOptions: IDropdownOption[] = [
    {
        key: MenuEntryType.Page,
        text: 'Naviguer vers une page',
    },
    {
        key: MenuEntryType.BuildinPage,
        text: 'Naviguer vers une page préconçu',
    },
    {
        key: MenuEntryType.Url,
        text: 'Lien web',
    },
    {
        key: MenuEntryType.ListOf,
        text: 'Menu list',
    },
];
const listOptions: IDropdownOption[] = [
    {
        key: MenuListName.InProgressFormation,
        text: 'Formation en cours',
    },
];
const buildInPageOptions: IDropdownOption[] = [
    {
        key: BuildinPageName.Account,
        text: 'Page du profile utilisateur',
    },
];
export const MenuItemDetail: FC<MenuItemDetailProps> = (props: MenuItemDetailProps) => {
    const { error, data, updateForm } = props;
    const updateData = useCallback(
        (propertyChanged: DeepPartial<MenuSettingEntry>) => {
            updateForm({ type: 'updateProperties', payload: { idPath: data?.id ?? '', propertyChanged } });
        },
        [updateForm, data],
    );
    return (
        <div className='MenuItemDetail'>
            {data ? (
                <>
                    <div className='row'>
                        <TextField required label='Libellé' errorMessage={error?.title} value={data?.title} onChange={(_, title) => updateData({ title })} />
                        {(data.children?.length ?? 0) === 0 ? (
                            <Dropdown
                                required
                                options={actionOptions}
                                errorMessage={error?.type}
                                label='Type'
                                selectedKey={data.type}
                                onChange={(_, key) => {
                                    updateData({ type: key?.key as MenuEntryType | undefined });
                                }}
                            />
                        ) : null}
                    </div>

                    <ExpressionField
                        kind={ExpressionKind.Condition}
                        showExpandButton
                        label='Visible si'
                        value={data?.visibleIf}
                        onChange={(visibleIf) => {
                            updateData({ visibleIf });
                        }}
                    />
                    {data.type === MenuEntryType.Page || data.type === MenuEntryType.ListOf || data.type === MenuEntryType.ViewResource ? (
                        <TextField required label='chemin' errorMessage={error?.key} value={data?.key} onChange={(_, key) => updateData({ key })} />
                    ) : null}

                    {(data.children === undefined || data.children.length === 0) && data.type !== MenuEntryType.ListOf ? (
                        <FluentIconSelectorDialog
                            label='Icon'
                            icon={data?.icon}
                            onChange={(icon) => {
                                updateData({ icon });
                            }}
                        />
                    ) : null}

                    {data.type === MenuEntryType.Url ? (
                        <TextField required label='Lien web' errorMessage={error?.externalUrl} value={data?.externalUrl} onChange={(_, externalUrl) => updateData({ externalUrl })} />
                    ) : null}
                    {data.type === MenuEntryType.ListOf ? (
                        <Dropdown
                            required
                            errorMessage={error?.listname}
                            options={listOptions}
                            label='Nom de la liste'
                            selectedKey={data.listname}
                            onChange={(_, key) => {
                                updateData({ listname: key?.key as MenuListName | undefined });
                            }}
                        />
                    ) : null}
                    {data.type === MenuEntryType.BuildinPage ? (
                        <Dropdown
                            required
                            errorMessage={error?.buildinPageName}
                            options={buildInPageOptions}
                            label='Page préconçu'
                            selectedKey={data.buildinPageName}
                            onChange={(_, key) => {
                                const buildinPageName = key?.key as BuildinPageName | undefined;
                                updateData({ key: buildinPageName, buildinPageName });
                            }}
                        />
                    ) : null}
                    {data.type === MenuEntryType.Page ? (
                        <PageSettingEdit
                            data={data.page ?? {}}
                            onChange={(page) => {
                                updateData({ page });
                            }}
                        />
                    ) : null}
                </>
            ) : null}
            <div></div>
        </div>
    );
};
