import { ActionButton } from '@fluentui/react';
import { FC } from 'react';
import './ErrorPanel.scss';

export interface ErrorPanelProps {
    errorMessage?: string;
    onchangeFilter?: () => void;
}

export const ErrorPanel: FC<ErrorPanelProps> = (props: ErrorPanelProps) => {
    const { errorMessage, onchangeFilter } = props;
    return (
        <div className='errorPanel'>
            <span className='title'>aucun résultat</span>
            <p>
                <span className='subtitle bold'>Erreur : </span>
                <span className='subtitle'>{errorMessage}</span>
            </p>

            <ActionButton
                className='actionButtonLink'
                onClick={() => {
                    if (onchangeFilter) {
                        onchangeFilter();
                    }
                }}
            >
                {'Change de filtre'}
            </ActionButton>
        </div>
    );
};
