//note that we don't use export default here
//create context here, use context in others

import { createContext, FC, PropsWithChildren } from 'react';
import { IResourcePackage } from '../../../Services/SakuraApiClient';
import { IResourcePackageContextState } from './IResourcePackageContextState';
//this DataContext will be shared by all the components
export const ResourcePackageDataContext = createContext<IResourcePackageContextState>({ resourcePackages: {} });

export interface ResourcePackageContextProviderProps {
    resourcePackages: Record<string, IResourcePackage> | undefined;
}

export const ResourcePackageContextProvider: FC<PropsWithChildren<ResourcePackageContextProviderProps>> = (props: PropsWithChildren<ResourcePackageContextProviderProps>) => {
    const { children, resourcePackages } = props;

    return <ResourcePackageDataContext.Provider value={{ resourcePackages: resourcePackages ?? {} }}>{children}</ResourcePackageDataContext.Provider>;
};
