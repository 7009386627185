import { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IAccountDataResource, IResourceData, IResourceDataPage } from '../../../Services/SakuraApiClient';
import { EditorDataContextProvider } from './EditorContext/EditorDataContextProvider';
import { PageEditorElementPanel } from './ElementPanel/PageEditorElementPanel';
import { PageEdition } from './PageEdition';
import { PageEditorToolBox } from './ToolBox/PageEditorToolBox';

import { DeepPartial } from '../../../common/Hooks/Form/useForm';
import { useLoadScopeDynamicData } from '../Page/hook/useLoadScopeDynamicData';
import './PageEditorStudio.scss';
import { PageEditorStudioLayer } from './PageEditorStudioLayer';

export interface PageEditorStudioProps {
    resourceData: IResourceData;
    pageData: IResourceDataPage | undefined;
    onChangePageData?: (data: DeepPartial<IResourceDataPage>) => void;
    resourceAccountData: IAccountDataResource | undefined;
}

export const PageEditorStudio: FC<PageEditorStudioProps> = (props: PageEditorStudioProps) => {
    const { resourceData, pageData, onChangePageData, resourceAccountData } = props;
    useLoadScopeDynamicData('page', resourceData, resourceAccountData, true);
    return (
        <EditorDataContextProvider>
            <DndProvider backend={HTML5Backend}>
                <PageEditorStudioLayer>
                    <PageEditorToolBox pageData={pageData} />
                    <PageEdition pageData={pageData} onChangePageData={onChangePageData} />
                    <PageEditorElementPanel />
                </PageEditorStudioLayer>
            </DndProvider>
        </EditorDataContextProvider>
    );
};
