import { DefaultButton, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { performApiCall, useApiCall } from '../../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource } from '../../../../Services/SakuraApiClient';

export interface VersionSelectorProps {
    resource: IResource;
    selectedVersion: IResource | null;
    onSelectVersion: (version: IResource) => void;
    onSetActiveVersion: (version: IResource | null) => void;
}
const selected = {
    iconName: 'CheckboxComposite',
};
const noSelected = {
    iconName: 'Checkbox',
};

export const VersionSelector: FC<VersionSelectorProps> = (props: VersionSelectorProps) => {
    const { resource, selectedVersion, onSelectVersion, onSetActiveVersion } = props;
    const [versions, setVersions] = useState<IResource[]>();
    const dispatch = useAppDispatch();
    useApiCall(
        useCallback(
            async (client, ct) => {
                const result = await client.getResourceVersions(resource.id, ct);
                setVersions(result);
            },
            [resource.id],
        ),
    );
    const onClickItem = useCallback(
        (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
            onSelectVersion(item?.data ?? resource);
        },
        [onSelectVersion, resource],
    );

    const createNewVersion = useCallback(() => {
        performApiCall(async (client, ct) => {
            const newVersion = await client.createResourceVersion(selectedVersion?.id ?? resource.id, ct);
            setVersions((v) => [...(v ?? []), newVersion]);
            onSelectVersion(newVersion);
        }, dispatch);
    }, [resource, selectedVersion, onSelectVersion, setVersions, dispatch]);

    const menuProps = useMemo<IContextualMenuProps>(() => {
        const items: IContextualMenuItem[] = [];
        items.push({
            key: '_newItem',
            text: 'Créer une nouvelle version',
            data: undefined,

            iconProps: {
                iconName: 'Add',
            },
            onClick: createNewVersion,
        });

        if (selectedVersion?.id !== resource.versionId) {
            items.push({
                key: '_setActive',
                text: `Définir la ${selectedVersion?.name ?? 'Version 1'} comme active.`,
                data: undefined,
                iconProps: {
                    iconName: 'View',
                },
                onClick: () => {
                    onSetActiveVersion(selectedVersion);
                },
            });
        }
        items.push({
            key: '0',
            text: 'Version 1',
            data: undefined,

            iconProps: (selectedVersion?.id ?? 0) === 0 ? selected : noSelected,
            secondaryText: (resource.versionId ?? 0) === 0 ? 'Active' : '',
            onClick: onClickItem,
        });
        const versionItems =
            versions?.map((v) => ({
                key: v.id.toString(),
                text: v.name ?? '',
                data: v,

                iconProps: (selectedVersion?.id ?? 0) === v.id ? selected : noSelected,
                secondaryText: (resource.versionId ?? 0) === v.id ? 'Active' : '',
                onClick: onClickItem,
            })) ?? [];
        items.push(...versionItems);
        return {
            items,
        };
    }, [versions, onClickItem, selectedVersion, resource.versionId, createNewVersion, onSetActiveVersion]);

    return (
        <div style={{ marginLeft: '1rem' }}>
            <DefaultButton text={selectedVersion?.name ?? 'Version 1'} primary split aria-roledescription='Select version' menuProps={menuProps} />
        </div>
    );
};
