import { Reducer } from 'react';
import { FormationContextAction } from './FormationContextActions';
import { checkIfCanComplete, getParentCompletion, markItemCompleted, markItemStarted, updateMenuItem, updateMenuItemToOpenRequired, updateUserData } from './helper';
import { IFormationContextState } from './IFormationContextState';

export type FormationContextReducerType = Reducer<IFormationContextState, FormationContextAction>;
export const formationContextReducer = (state: IFormationContextState, action: FormationContextAction): IFormationContextState => {
    let newState = state;
    switch (action.type) {
        case 'selectFormationItem':
            {
                const resourcePath = action.payload.resourcePath;
                const index = state.userData.userDataIndex[resourcePath];
                const serverSyncActions = [...state.serverSyncActions];
                const { userData, appliedChanges } = updateUserData(state.userData, [markItemStarted(index)]);

                if (appliedChanges[0]) {
                    serverSyncActions.push({ type: 'TrackAccess', payload: { resourcePath } });
                }
                newState = { ...state, selectedContentPath: action.payload.resourcePath, userData, menu: updateMenuItemToOpenRequired(state.menu, action.payload.resourcePath), serverSyncActions };
            }
            break;
        case 'movePrev':
            {
                let index = 0;
                if (state.selectedContentPath) {
                    index = state.userData.userDataIndex[state.selectedContentPath];
                    if (index > 0) {
                        index--;
                    }
                }
                const selectedContentPath = state.userData.userDatas[index].resourcePath;
                newState = { ...state, selectedContentPath, menu: updateMenuItemToOpenRequired(state.menu, selectedContentPath) };
            }
            break;
        case 'moveNext':
            {
                let index = 0;
                if (state.selectedContentPath) {
                    const resourcePath = state.selectedContentPath;
                    index = state.userData.userDataIndex[resourcePath];

                    const canComplete = checkIfCanComplete(state, index);
                    console.log('canComplete = ' + canComplete);
                    if (index < state.userData.userDatas.length - 1) {
                        index++;
                    } else {
                        break;
                    }

                    const selectedContentPath = state.userData.userDatas[index].resourcePath;
                    console.log('selectedContentPath = ' + selectedContentPath);
                    const { userData, appliedChanges } = updateUserData(state.userData, [
                        canComplete ? markItemCompleted(index - 1) : null,
                        ...getParentCompletion(state, index - 1),
                        markItemStarted(index),
                    ]);
                    const serverSyncActions = [...state.serverSyncActions];

                    if (appliedChanges[0]) {
                        serverSyncActions.push({ type: 'TrackCompleted', payload: { resourcePath, data: userData.userDatas[index - 1].data } });
                    }
                    if (appliedChanges[1]) {
                        serverSyncActions.push({ type: 'TrackAccess', payload: { resourcePath: selectedContentPath } });
                    }
                    newState = {
                        ...state,
                        userData,
                        selectedContentPath,
                        menu: updateMenuItemToOpenRequired(state.menu, selectedContentPath),
                        serverSyncActions,
                    };
                }
            }
            break;
        case 'completeItem':
            {
                let index = 0;
                const resourcePath = action.payload.resourcePath ?? state.selectedContentPath;
                if (resourcePath) {
                    index = state.userData.userDataIndex[resourcePath];
                    const canComplete = checkIfCanComplete(state, index);
                    if (canComplete) {
                        const serverSyncActions = [...state.serverSyncActions];
                        const selectedContentPath = state.userData.userDatas[index].resourcePath;

                        const { userData, appliedChanges } = updateUserData(state.userData, [markItemCompleted(index), ...getParentCompletion(state, index)]);

                        if (appliedChanges[0]) {
                            serverSyncActions.push({ type: 'TrackCompleted', payload: { resourcePath, data: userData.userDatas[index].data } });
                        }
                        newState = {
                            ...state,
                            userData,
                            menu: updateMenuItemToOpenRequired(state.menu, selectedContentPath),
                            serverSyncActions,
                        };
                    }
                }
            }
            break;
        case 'expandItem':
            newState = {
                ...state,
                menu: updateMenuItem(state.menu, action.payload.resourcePath, (item) => {
                    return { ...item, isExpanded: action.payload.isExpanded };
                }),
            };

            break;
        case 'clearServerSyncActions': {
            console.log('clear all');
            newState = { ...state, serverSyncActions: [] };
        }
    }
    if (state.serializationIdentifier) {
        localStorage.setItem(`ExecData_${state.serializationIdentifier}`, JSON.stringify(newState));
    }
    return newState;
};
