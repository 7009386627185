import { FC } from 'react';
import { PageView } from '../Page/PageView';
import { getResourcesInfo } from '../Page/ResourcePageContent';
import { useFormationContextSelector } from './FormationContext/FormationContextSelector';
import { getParentPath } from './helper';

export const FormationContentPage: FC = () => {
    const selectedContent = useFormationContextSelector((context) => context.selectedContent);

    return (
        <>
            {selectedContent ? (
                <PageView
                    key={selectedContent.resourcePath}
                    pageResourceId={selectedContent.resource.id}
                    parentResourcePathId={getParentPath(selectedContent.resourcePath)}
                    resourceAccountData={selectedContent.accountDataResource}
                    pageData={selectedContent.resource.data.content.page}
                    resourceData={selectedContent.resource.data}
                    resources={getResourcesInfo(selectedContent.innerResourcePackages ?? {})}
                />
            ) : null}
        </>
    );
};
