import { FC, useCallback, useMemo, useState } from 'react';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { useForm } from '../../../../common/Hooks/Form/useForm';
import { useNav } from '../../../../common/Hooks/useNav';
import { useNavigationEntity } from '../../../../common/Hooks/useNavigationEntity';
import { useAppDispatch, useAppSelector } from '../../../../Redux/hook';
import { screenPaths } from '../../../../Routing/ScreenPaths';
import { EntityState, IResourceHierarchy, ResourceHierarchy, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { TableOfContent } from './TableOfContent';

import { openNewTab } from '../../../../common/helpers/uri';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { setPopupMessage } from '../../../../Redux/Reducers/System/reducer';
import { PopupMessage } from '../../../../Redux/Reducers/System/state';
import { useRootPathSelector } from '../../../../Redux/Reducers/User/reducer';
import { deletePersistantExecutionDataContext } from '../../../Common/Page/ExecutionContext/ExecutionDataContextProvider';
import { IResourceInfo } from '../../../Common/Page/ExecutionContext/IExecutionContextState';
import './Formation.scss';
import { FormationContentView } from './FormationContentView';
import { ResourceHierarchyAction, formResourceHierarchySlicer } from './FormResourceHierarchySlicer';
import { buildInnerResourcePackages, getItemByPath } from './Utils';

export interface ResourceFormationDetailsContentProps {
    resourceHierachy: IResourceHierarchy;
    innerResourcePackages: Record<number, IResourceInfo>;
}
export const ResourceFormationDetails: FC = () => {
    const [id, resourceHierachy] = useNavigationEntity((id, client) => client.getResourceHierarchyById(id as number));

    return id && resourceHierachy ? (
        <ResourceFormationDetailsContent resourceHierachy={resourceHierachy} innerResourcePackages={buildInnerResourcePackages(resourceHierachy)}></ResourceFormationDetailsContent>
    ) : (
        <></>
    );
};
const confirmationSavePopup: PopupMessage = { icon: 'SkypeCircleCheck', title: 'Sauvegarde', subTitle: 'Formation sauvegardé avec succés.', timeout: 1000 };

export const ResourceFormationDetailsContent: FC<ResourceFormationDetailsContentProps> = (props: ResourceFormationDetailsContentProps) => {
    const { resourceHierachy, innerResourcePackages } = props;
    const [selectionPath, setSelectionPath] = useState<string | undefined>(undefined);

    const urlRootPath = useAppSelector(useRootPathSelector);

    const appDispatch = useAppDispatch();
    const nav = useNav();
    const form = useForm<IResourceHierarchy>(
        {
            initialState: resourceHierachy,
        },
        true,
        undefined,
        `Formation_${resourceHierachy ? resourceHierachy.resource.id : 'new'}`,
    );

    const goBack = useCallback(
        (refresh: boolean) => {
            if (nav.from?.pathname.endsWith(screenPaths.resourceList(ResourceTypeEnum.Formation))) {
                nav.navigate(screenPaths.resourceList(ResourceTypeEnum.Formation), refresh ? { refresh: true } : undefined);
            } else if (nav.from) {
                nav.goBack();
            }
        },
        [nav],
    );

    const updateForm = useCallback(
        (action: ResourceHierarchyAction) => {
            const { newState, error } = formResourceHierarchySlicer(form.state, action);
            if (!error) {
                form.update(newState);
                return undefined;
            } else {
                return error;
            }
        },
        [form],
    );
    const selection = useMemo(() => {
        if (selectionPath) {
            const selectedItem = getItemByPath(selectionPath, form.state as IResourceHierarchy);
            if (selectedItem?.linkState !== EntityState.Delete) {
                return selectedItem;
            }
        }
        return undefined;
    }, [form.state, selectionPath]);
    const onSave = useCallback(() => {
        const error = form.validate();
        if (!error) {
            performApiCall(async (client, ct) => {
                if (resourceHierachy.resource.id) {
                    const resourceUpdated = new ResourceHierarchy();
                    resourceUpdated.init(form.state);
                    const data = await client.updateResourceHierarchy(resourceHierachy.resource.id, resourceUpdated, ct);
                    form.commit(data);
                    // goBack(true);
                    deletePersistantExecutionDataContext(resourceHierachy.resource.id.toString());
                    appDispatch(setPopupMessage({ message: confirmationSavePopup }));
                }
            }, appDispatch);
        }
    }, [appDispatch, form, resourceHierachy.resource.id]);
    return (
        <Panel
            isContentModified={form.isModified}
            revertContentModification={form.rollback}
            backButtonCommand={() => (nav.from ? goBack(false) : nav.navigate(screenPaths.resourceList(ResourceTypeEnum.Formation)))}
            title={`Edition de '${form.state?.resource?.name}'`}
            icon={GetResourceTypeIcon(ResourceTypeEnum.Formation)}
            commands={[
                {
                    text: 'Voir la formation',
                    icon: 'EntryView',
                    disabled: form.isModified,
                    onClick: () => {
                        if (form.state.resource?.id) {
                            openNewTab(urlRootPath + screenPaths.resourceView(ResourceTypeEnum.Formation, form.state.resource.id));
                        }
                    },
                },
                {
                    text: resourceHierachy.resource.id ? 'Sauvegarder' : 'Créer',
                    icon: 'Save',
                    onClick: () => {
                        onSave();
                    },
                },
            ]}
        >
            <div className='formation'>
                <TableOfContent resourceHierachy={form.state} updateForm={updateForm} selectionPath={selectionPath} onSelect={(resourcePath) => setSelectionPath(resourcePath)} />
                <FormationContentView
                    formationItem={selection}
                    updateForm={updateForm}
                    innerResourcePackages={innerResourcePackages}
                    executionContextSerializationIdentifier={resourceHierachy.resource.id.toString()}
                />
            </div>
        </Panel>
    );
};
