import { FC, useCallback, useLayoutEffect, useMemo, useRef } from 'react';

import clsx from 'clsx';
import { mobileViewInContextSelector } from '../../../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../../../Redux/hook';
import { getParent } from '../../../../../../common/helpers/Element';

import { useCarousel } from 'nuka-carousel';
import { SelectionStatus } from '../../../../../../Redux/Reducers/System/state';
import { useState2 } from '../../../../../../common/Hooks/useState2';
import { Rectangle, Scale } from '../../../../../../common/helpers/CssTransform';
import { ThumbnailImage } from './ThumbnailImage';
import './ThumbnailItem.scss';
import { ThumbnailItemDetails } from './ThumbnailItemDetails/ThumbnailItemDetails';
import { IDataTile } from './useThumbnailSource';

interface Select {
    status: SelectionStatus;
    origin: 'Click' | 'MouseOver';
}
export interface ThumbnailItemProps {
    index: number;
    position: 'first' | 'last' | 'middle';
    width: number;
    height: number;
    item: IDataTile;
    onSelect?: (selected: Select) => void;
    onOpen: () => Promise<void>;
    selection?: Select;
    disableActions?: boolean;
}

export const ThumbnailItem: FC<ThumbnailItemProps> = (props: ThumbnailItemProps) => {
    const { item, onSelect, onOpen, width, height, position, selection, disableActions } = props;

    const { currentPage, totalPages, wrapMode } = useCarousel();

    const allowWrap = wrapMode === 'wrap';
    const enablePrevNavButton = allowWrap || currentPage > 0;
    const enableNextNavButton = allowWrap || currentPage < totalPages - 1;

    const refTile = useRef<HTMLDivElement>(null);
    const refTileOver = useRef<HTMLDivElement>(null);
    const refTilDetails = useRef<HTMLDivElement>(null);

    const isMobileView = useAppSelector(mobileViewInContextSelector);
    const [showDetail, setShowDetail, showDetailRef] = useState2<boolean | undefined>(undefined);
    const refTimeout = useRef<NodeJS.Timeout | null>();
    const onMouseEnter = useCallback(() => {
        if (!disableActions) {
            if (onSelect && selection?.status !== 'Selected') {
                onSelect({ status: 'Selected', origin: 'MouseOver' });
            }
        }
    }, [disableActions, onSelect, selection?.status]);
    const onMouseLeave = useCallback(() => {
        if (!disableActions) {
            if (onSelect && !showDetail) {
                onSelect({ status: 'UnSelected', origin: 'MouseOver' });
            }
        }
    }, [disableActions, onSelect, showDetail]);
    const onMouseLeaveOfDetail = useCallback(() => {
        if (!disableActions) {
            if (onSelect) {
                onSelect({ status: 'UnSelected', origin: 'MouseOver' });
            }
        }
    }, [disableActions, onSelect]);
    const imageScale = useMemo(() => {
        const atScale = Scale({ left: 0, top: 0, width, height }, 1.15, 1.15);
        return { width: atScale.width, height: atScale.height };
    }, [width, height]);

    const getTransformXY = useCallback(() => {
        const rect = refTile.current?.parentElement?.getBoundingClientRect();
        const root = getParent(refTile.current, (e) => e.classList.contains('nuka-container'))?.getBoundingClientRect();
        const page = getParent(refTile.current, (e) => e.id === 'layoutRoot')?.getBoundingClientRect();
        let transformX = undefined;
        let transformY = -35;
        let rectScale: Rectangle | undefined = undefined;
        if (refTileOver.current?.style && rect && root) {
            const left = rect.left - root.left;
            const top = rect.top - root.top;

            rectScale = Scale({ left, top, width: rect.width, height: rect.height }, 1.15, 1.15);

            refTileOver.current.style.left = `${rectScale.left}px`;
            refTileOver.current.style.top = `${rectScale.top}px`;
            refTileOver.current.style.height = `${rectScale.height}px`;
            refTileOver.current.style.width = `${rectScale.width}px`;

            const scaleRight = rectScale.left + rectScale.width;

            if (rectScale.left < 0) {
                transformX = rectScale.left * -1;
                if (enablePrevNavButton) {
                    transformX += 50;
                }
            }
            if (scaleRight >= root.width) {
                transformX = root.width - scaleRight;
                if (enableNextNavButton) {
                    transformX -= 50;
                }
            }
            if (page) {
                const rectDetails = refTilDetails.current?.getBoundingClientRect();
                if (rectScale && rectDetails) {
                    const scalebottom = rectScale.top + rectScale.height + rectDetails.height;
                    const titleBottomReferentielPage = scalebottom + root.top;
                    if (titleBottomReferentielPage >= page.height) {
                        transformY = page.height - titleBottomReferentielPage;
                    }
                }
            }
        }
        return { transformX, transformY };
    }, [enableNextNavButton, enablePrevNavButton]);
    useLayoutEffect(() => {
        if (selection?.status === 'Selected') {
            const { transformX, transformY } = getTransformXY();

            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
            const startAnimation = () => {
                setShowDetail(true);

                if (transformX !== undefined) {
                    document.body.style.setProperty('--thumbnailTranslateX', `${transformX}px`);
                } else {
                    document.body.style.setProperty('--thumbnailTranslateX', `0px`);
                }
                document.body.style.setProperty('--thumbnailTranslateY', `${transformY}px`);
            };
            if (selection.origin === 'Click') {
                startAnimation();
            } else {
                refTimeout.current = setTimeout(() => {
                    startAnimation();
                    refTimeout.current = null;
                }, 500);
            }
        } else {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
            if (showDetailRef.current) {
                setShowDetail(false);
                setTimeout(() => {
                    setShowDetail(undefined);
                }, 300);
            }
        }
    }, [getTransformXY, selection, setShowDetail, showDetailRef]);
    const overClassname = useMemo(() => {
        if (showDetail) {
            return 'show';
        }
        if (showDetail === false) {
            return 'hide';
        }
        return undefined;
    }, [showDetail]);
    const updateYTransform = useCallback(() => {
        const rectDetails = refTilDetails.current?.getBoundingClientRect();
        if (rectDetails) {
            console.log('AArectDetails.height  = ' + rectDetails.height);
            const { transformY } = getTransformXY();
            document.body.style.setProperty('--thumbnailTranslateY', `${transformY}px`);
        }
    }, [getTransformXY]);

    const onClick = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!disableActions) {
                if (onSelect) {
                    onSelect({ status: 'Selected', origin: 'Click' });
                }
            }
            ev.stopPropagation();
        },
        [disableActions, onSelect],
    );
    const onDoubleClick = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!disableActions) {
                if (onOpen) {
                    onOpen();
                }
            }
            ev.stopPropagation();
        },
        [disableActions, onOpen],
    );
    return (
        <div className={clsx('thumbnailItem', position)} style={{ width, height }} onClick={onClick} onDoubleClick={onDoubleClick}>
            <div ref={refTile} onMouseEnter={isMobileView ? undefined : onMouseEnter} onMouseLeave={isMobileView ? undefined : onMouseLeave}>
                <ThumbnailImage width={width} height={height} thumbnailPath={item.data.thumbnailPath} />
            </div>
            <div
                className={clsx('thumbnailItemOver', overClassname)}
                ref={refTileOver}
                onMouseLeave={isMobileView ? undefined : onMouseLeaveOfDetail}
                onAnimationStart={updateYTransform}
                onAnimationEnd={updateYTransform}
            >
                <ThumbnailImage width={imageScale.width} height={imageScale.height} thumbnailPath={item.data.thumbnailPath} />
                <div className='details' ref={refTilDetails}>
                    <ThumbnailItemDetails type={item.type} data={item.data} onOpen={onOpen} />
                </div>
            </div>
        </div>
    );
};
