import { $patchStyleText } from '@lexical/selection';
import { $getSelection, LexicalEditor, RangeSelection, TextFormatType } from 'lexical';

export function applyStyleOnSelection(editor: LexicalEditor, styles: Record<string, string | null>, skipHistoryStack?: boolean) {
    editor.update(
        () => {
            const selection = $getSelection();
            if (selection !== null) {
                $patchStyleText(selection, styles);
            }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
    );
}

export function forceFormat(selection: RangeSelection, format: TextFormatType, enabled: boolean) {
    if (enabled) {
        if (!selection.hasFormat(format)) {
            selection.formatText(format);
        }
    } else {
        if (selection.hasFormat(format)) {
            selection.formatText(format);
        }
    }
}
