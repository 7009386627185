import { FC, useEffect } from 'react';
import { performApiCall } from '../../../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../../../Redux/hook';
import { AppDispatch } from '../../../../../Redux/store';
import { AccountResourceDataObject, IAccountDataResource } from '../../../../../Services/SakuraApiClient';
import { getParts } from '../../helper';
import { useFormationContextActions, useFormationContextSelector } from '../FormationContextSelector';
import { ServerSyncAction } from './ServerSync';

export const FormationContextServerSync: FC = () => {
    const formationContextActions = useFormationContextActions();
    const serverSyncActions = useFormationContextSelector((context) => context.serverSyncActions);
    const appDispatch = useAppDispatch();
    useEffect(() => {
        if (serverSyncActions && serverSyncActions.length > 0) {
            const promises: Promise<void>[] = [];
            console.log('execute');
            console.table(serverSyncActions);
            serverSyncActions.forEach((action) => {
                promises.push(processServerSyncActions(action, appDispatch));
            });
            Promise.all(promises).then(() => {
                formationContextActions.clearServerSyncActions();
            });
        }
    }, [serverSyncActions, appDispatch, formationContextActions]);

    return null;
};

async function processServerSyncActions(action: ServerSyncAction, appDispatch: AppDispatch): Promise<void> {
    switch (action.type) {
        case 'TrackAccess': {
            if (action.payload.resourcePath) {
                await trackAccess(action.payload.resourcePath, appDispatch);
            }
            break;
        }
        case 'TrackCompleted': {
            if (action.payload.resourcePath) {
                await trackCompleted(action.payload.resourcePath, action.payload.data, appDispatch);
            }
            break;
        }
    }
}

async function trackAccess(resourcePathId: string, dispatch: AppDispatch) {
    await performApiCall(async (client, ct) => {
        const parts = getParts(resourcePathId);
        if (parts) {
            await client.trackResourceAccess(Number.parseInt(parts.id), parts.parentPath, ct);
        }
    }, dispatch);
}

async function trackCompleted(resourcePathId: string, data: IAccountDataResource | undefined, dispatch: AppDispatch) {
    await performApiCall(async (client, ct) => {
        const parts = getParts(resourcePathId);
        if (parts) {
            const accountResourceDataObject = data ? new AccountResourceDataObject() : undefined;
            if (data && accountResourceDataObject) {
                accountResourceDataObject.init(data.data);
            }
            await client.trackResourceComplete(Number.parseInt(parts.id), parts.parentPath, accountResourceDataObject, ct);
        }
    }, dispatch);
}
