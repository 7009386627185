import { isFunction } from './JavascriptType';

export type FormFieldValue = string | number | Date | boolean | undefined;
export type DeepPartial<T> = {
    [Property in keyof Partial<T>]: T[Property] extends FormFieldValue
        ? T[Property]
        : T[Property] extends Array<infer TElement> | undefined
          ? DeepPartial<TElement>[] | undefined
          : DeepPartial<T[Property]> | undefined;
};
export type DeepPartialWithArrayFunc<T> = {
    [Property in keyof Partial<T>]: T[Property] extends FormFieldValue
        ? T[Property]
        : T[Property] extends Array<infer TElement> | undefined
          ? DeepPartialWithArrayFunc<TElement>[] | ((array: DeepPartialWithArrayFunc<TElement>[]) => DeepPartialWithArrayFunc<TElement>[] | undefined) | undefined
          : DeepPartialWithArrayFunc<T[Property]> | undefined;
};

export interface RecursiveObj {
    children?: RecursiveObj[];
}

export function isPrimitive(value: unknown) {
    return Object(value) !== value || value instanceof Date;
}

export abstract class ObjectOperation {
    private static objectDescription: Record<string, string[]> = {
        File: ['lastModified', 'lastModifiedDate', 'name', 'size', 'type', 'webkitRelativePath'],
    };
    public static jsonEquals<T>(objectA: T, objectB: T): boolean {
        if (objectA === objectB) {
            return true;
        }
        return JSON.stringify(objectA) === JSON.stringify(objectB);
    }
    public static equals<T>(objectA: T, objectB: T, excludeProps?: string[]): boolean {
        if (objectA === objectB) {
            return true;
        }
        if (objectA !== undefined && objectB !== undefined) {
            const indexDiffProperty = Object.keys(objectA as object).findIndex((propertyName) => {
                if (excludeProps === undefined || excludeProps.indexOf(propertyName) === -1) {
                    if (isPrimitive((objectA as Record<string, unknown>)[propertyName])) {
                        return (objectA as Record<string, unknown>)[propertyName] !== (objectB as Record<string, unknown>)[propertyName];
                    } else {
                        return this.equals((objectA as Record<string, unknown>)[propertyName], (objectB as Record<string, unknown>)[propertyName]);
                    }
                }
                return false;
            });
            return indexDiffProperty === -1;
        }
        return false;
    }

    public static merge<T extends object | undefined, Y extends object | undefined>(origin: T | undefined, change: Y | undefined): T {
        if (change !== undefined) {
            const result = origin !== undefined ? { ...origin } : {};
            for (const [key, value] of ObjectOperation.entries(change)) {
                if (isPrimitive(value)) {
                    (result as Record<string, FormFieldValue>)[key] = value as FormFieldValue;
                } else if (value) {
                    //if array
                    const isValueAnArray = Array.isArray(value);
                    if (isValueAnArray) {
                        const array = value as [];
                        (result as Record<string, []>)[key] = array;
                    } else {
                        const proto = Object.getPrototypeOf(value);

                        if (proto.constructor.name === 'File' || proto.constructor.name === 'Blob') {
                            (result as Record<string, object | undefined>)[key] = value;
                            continue;
                        }
                        if (isFunction(value)) {
                            const func = value as (array: object | undefined) => object | undefined;
                            (result as Record<string, object | undefined>)[key] = func((result as Record<string, object | undefined>)[key] ?? []);
                        } else {
                            const val = (result as Record<string, object | undefined>)[key];
                            (result as Record<string, object | undefined>)[key] = ObjectOperation.merge(val, value);
                        }
                    }
                }
            }
            return result as T;
        }

        return origin ?? ({} as T);
    }

    public static entries<T>(o: object): [string, unknown][] {
        const entries = Object.entries(o);

        if (entries.length === 0) {
            const proto = Object.getPrototypeOf(o);
            const description = ObjectOperation.objectDescription[proto.constructor.name];
            if (description) {
                return description.map<[string, T]>((d) => [d, (o as Record<string, T>)[d]]);
            }
        }
        return entries;
    }
}

export abstract class ArrayOperation {
    public static add<T>(array: T[], obj: T) {
        return [...array, obj];
    }
    public static update<T extends object>(array: T[], predicate: (obj: T) => boolean, object: Partial<T>) {
        const val = [...array];
        const index = val.findIndex(predicate);
        val[index] = ObjectOperation.merge<T, Partial<T>>(val[index], object);
        return val;
    }
    public static updateByIndex<T extends object>(array: T[], index: number, object: Partial<T>, mode: 'merge' | 'override' = 'merge') {
        const val = [...array];
        val[index] = mode === 'merge' ? ObjectOperation.merge<T, Partial<T>>(val[index], object) : ({ ...object } as T);
        return val;
    }

    public static updateValueByIndex<T>(array: T[], index: number, value: T) {
        const val = [...array];
        val[index] = value;
        return val;
    }
    public static remove<T extends object>(array: T[], predicate: (obj: T) => boolean) {
        return array.filter((val) => !predicate(val));
    }
    public static removeByIndex<T extends object>(array: T[], index: number) {
        return array.filter((_, cindex) => cindex !== index);
    }
    public static isChild<T extends object>(array: T[], instance: T, childPropertyName: string): boolean {
        if (array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === instance) {
                    return true;
                } else {
                    const childArray = (array[i] as Record<string, T[]>)[childPropertyName];
                    if (childArray) {
                        const isChild = ArrayOperation.isChild(childArray, instance, childPropertyName);
                        if (isChild) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    public static removeDeep<T extends object>(array: T[], instance: T, childPropertyName: string): { array: T[]; hasDeleted: boolean } {
        let hasDeleted = false;
        if (array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === instance) {
                    return { array: ArrayOperation.removeByIndex(array, i), hasDeleted: true };
                } else {
                    const childArray = (array[i] as Record<string, T[]>)[childPropertyName];
                    if (childArray) {
                        const operation = ArrayOperation.removeDeep(childArray, instance, childPropertyName);
                        hasDeleted = operation.hasDeleted;
                        if (operation.array !== childArray) {
                            const newArray = [...array];
                            (newArray[i] as Record<string, T[]>)[childPropertyName] = operation.array;
                        }
                    }
                }
            }
        }
        return { array, hasDeleted };
    }
    public static createWithEmptyObjects(length: number) {
        const array = Array(length);
        for (let i = 0; i < array.length; i++) {
            array[i] = {};
        }
        return array;
    }
    public static EnsureArraySize(arraySrc: Array<unknown> | undefined, length: number) {
        const array = Array(length);
        for (let i = 0; i < array.length; i++) {
            if (arraySrc && i < arraySrc.length) {
                array[i] = arraySrc[i];
            } else {
                array[i] = {};
            }
        }
        return array;
    }
}
