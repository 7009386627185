import { $createParagraphNode, $createTextNode, $getRoot, LexicalEditor, SerializedEditorState, SerializedLexicalNode } from 'lexical';

export function loadEditorState(editor: LexicalEditor, content: string | undefined, jsonState: SerializedEditorState<SerializedLexicalNode> | undefined) {
    if (jsonState) {
        const editorState = editor.parseEditorState(jsonState);

        editor.update(() => {
            editor.setEditorState(editorState);
        });
    }
    if (content) {
        editor.update(() => {
            const rootNode = $getRoot();
            rootNode.clear(); // Efface le contenu existant
            const paragraphNode = $createParagraphNode();
            const textNode = $createTextNode(content);
            paragraphNode.append(textNode);
            rootNode.append(paragraphNode);
        });
    }
}
