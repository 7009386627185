import { Dropdown, IDropdownOption } from '@fluentui/react';
import { FC } from 'react';
import { SocialNetworkNameShareable } from '../../../../SocialNetworkLinks/type';

const networkOptions: IDropdownOption[] = [
    {
        key: -1,
        text: 'Non défini',
    },
    {
        key: 'email',
        text: 'Email',
    },
    {
        key: 'facebook',
        text: 'Facebook',
    },
    {
        key: 'messenger',
        text: 'Messenger',
    },
    {
        key: 'whatsapp',
        text: 'WhatsApp',
    },
    {
        key: 'pinterest',
        text: 'Pinterest',
    },
    {
        key: 'x',
        text: 'X (Twitter)',
    },
];
export interface NetworkPickerProps {
    value: SocialNetworkNameShareable | undefined;
    onChangeData: (value: SocialNetworkNameShareable | undefined) => void;
}
export const NetworkPicker: FC<NetworkPickerProps> = (props: NetworkPickerProps) => {
    const { value, onChangeData } = props;
    return (
        <Dropdown
            label='Partagé via'
            options={networkOptions}
            selectedKey={value ?? -1}
            onChange={(_, option) => {
                const val = option?.key;
                onChangeData(val === -1 ? undefined : (val as SocialNetworkNameShareable));
            }}
        ></Dropdown>
    );
};
