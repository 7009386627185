import { Dropdown, Icon, IDropdownOption, IDropdownProps } from '@fluentui/react';
import { FC } from 'react';

export interface DropDownWithPictureWithPicProps {
    options: IDropdownOption[];
    disabled?: boolean;
    placeholder: string;
    selectedKey: string | number | string[] | number[] | null | undefined;
    onSelectKey: (key: string | number | string[] | number[] | null | undefined) => void;
}

const iconStyles = { marginRight: '8px', fontSize: 'Large' };
const onRenderOption = (option: IDropdownOption | undefined): JSX.Element => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {option?.data && option.data.icon && <Icon style={iconStyles} iconName={option.data.icon} aria-hidden='true' title={option.data.icon} />}
            <span>{option?.text}</span>
        </div>
    );
};

const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
    if (options) {
        const option = options[0];

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {option.data && option.data.icon && <Icon style={iconStyles} iconName={option.data.icon} aria-hidden='true' title={option.data.icon} />}
                <span>{option.text}</span>
            </div>
        );
    }
    return <></>;
};

const onRenderPlaceholder = (props?: IDropdownProps): JSX.Element => {
    return (
        <div className='dropdownExample-placeholder'>
            <Icon style={iconStyles} iconName={'MessageFill'} aria-hidden='true' />
            <span>{props?.placeholder}</span>
        </div>
    );
};
export const DropDownWithPictureWithPic: FC<DropDownWithPictureWithPicProps> = (props: DropDownWithPictureWithPicProps) => {
    const { placeholder, onSelectKey, options, selectedKey, disabled } = props;
    return (
        <Dropdown
            placeholder={placeholder}
            disabled={disabled}
            onRenderPlaceholder={onRenderPlaceholder}
            onRenderTitle={onRenderTitle}
            onRenderOption={onRenderOption}
            options={options}
            selectedKey={selectedKey}
            onChange={(_, option) => onSelectKey(option?.key)}
        />
    );
};
