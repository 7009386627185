import { DeepPartial, FormFieldValue, FormValidationFieldMethod } from './useForm';

const RequiredMessage = 'est obligatoire.';
const InvalidMessage = 'est invalide.';

export function evalRegEx(value: string, regEx: RegExp, displayName: string) {
    const match = (value ?? '').toString().match(regEx);
    if (match) {
        return undefined;
    }
    return `${displayName} ${InvalidMessage}`.trim();
}

export function required<TModel>(model: TModel, value: FormFieldValue, displayName: string): string | undefined {
    if (value === undefined || value === '') {
        return `${displayName} ${RequiredMessage}`.trim();
    }
    return undefined;
}

export function requiredIf<TModel>(condition: (model: DeepPartial<TModel> | undefined) => boolean): FormValidationFieldMethod<TModel> {
    const func = (model: DeepPartial<TModel> | undefined, value: FormFieldValue, displayName: string) => {
        if (condition(model)) {
            return required(model, value, displayName);
        }
        return undefined;
    };
    return func;
}

export function validEmail<TModel>(model: TModel, value: FormFieldValue, displayName: string): string | undefined {
    return evalRegEx((value ?? '').toString(), /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, displayName);
}

export function validLink<TModel>(model: TModel, value: FormFieldValue, displayName: string): string | undefined {
    if (value) {
        return evalRegEx((value ?? '').toString(), /^((http|https):\/\/)(.*)/, displayName);
    }
    return undefined;
}
export function NotIn<TModel>(condition: (model: DeepPartial<TModel> | undefined) => boolean, list: string[]): FormValidationFieldMethod<TModel> {
    const func = (model: DeepPartial<TModel> | undefined, value: FormFieldValue, displayName: string) => {
        if (condition(model) && value !== undefined && value !== '' && list.includes(value as string)) {
            return `${displayName} est invalide car '${value}' est une valeur reservé.`.trim();
        }
        return undefined;
    };
    return func;
}
