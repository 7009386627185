import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { IOffer, IProduct, Offer, OfferCreationModel, OfferUpdateModel } from '../../../Services/SakuraApiClient';

import { ActionButton, PrimaryButton, Separator, TextField, Toggle } from '@fluentui/react';
import { DatetimePicker } from '../../../common/components/DatetimePicker/DatetimePicker';
import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { FormGroup } from '../../../common/components/FormGroup/FormGroup';
import { NumberField } from '../../../common/components/NumberField/NumberField';
import { Panel, PanelCommand } from '../../../common/components/Panel/Panel';
import { ArrayOperation } from '../../../common/helpers/ObjectAndArray';
import { required, useForm } from '../../../common/Hooks/Form';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { useNav } from '../../../common/Hooks/useNav';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { useAppDispatch } from '../../../Redux/hook';
import { screenPaths } from '../../../Routing/ScreenPaths';

import { ConfirmationDialog } from '../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { useNavigationEntity } from '../../../common/Hooks/useNavigationEntity';
import { ProductLookupField } from '../../../common/LookupFields/ProductLookupField';
import { FilterChange } from '../../../Redux/Reducers/DataSet/ApiCall/apiCall';
import { ProductLookup, ProductsByOffer } from '../../../Redux/Reducers/DataSet/ApiCall/Product';
import { clearContext } from '../../../Redux/Reducers/System/reducer';
import { DatasetGrid } from '../../Common/DatasetGrid';
import { LocalDatasetGrid } from '../../Common/LocalDatasetGrid';
import { getProductTypeText } from '../Products/ProductType';
import './OfferDetail.scss';

const getNewOffer = () => {
    const newResource = new Offer();
    newResource.init({ enabled: true });
    return newResource;
};
export const OfferDetail: FC = () => {
    const [id, offer] = useNavigationEntity((id, client) => client.getOffer(id as number));
    return id && !offer ? <></> : <OfferDetailContent offer={offer}></OfferDetailContent>;
};
interface OfferDetailContentProps {
    offer: IOffer | undefined;
}
export const OfferDetailContent: FC<OfferDetailContentProps> = (props: OfferDetailContentProps) => {
    const { offer } = props;
    const nav = useNav();
    const appDispatch = useAppDispatch();
    const [products, setProducts] = useState<IProduct[]>([]); //use in creation mode
    const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>();
    const [productToAdd, setProductToAdd] = useState<IProduct | undefined>();
    const [showAddProductDialog, setShowAddProductDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const onSelect = (product: IProduct | undefined) => {
        setSelectedProduct(product);
    };
    const createMode = offer === undefined;
    const form = useForm<IOffer>(
        {
            initialState: offer ?? getNewOffer(),
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [required] },
                    price: { displayName: 'Le prix', validators: [required] },
                    data: {
                        marketing: {
                            _array: { displayName: 'publicité', validators: [] },
                            link: {
                                displayName: 'Le lien',
                                validators: [required],
                            },
                        },
                    },
                },
            },
        },
        offer !== undefined,
    );
    const goBack = useCallback(
        (refresh: boolean) => {
            if (nav.from?.pathname.endsWith(screenPaths.offerList)) {
                nav.navigate(screenPaths.offerList, refresh ? { refresh: true } : undefined);
            } else if (nav.from) {
                nav.goBack();
            }
        },
        [nav],
    );
    const onSave = useCallback(() => {
        const error = form.validate();
        if (!error) {
            performApiCall(async (client) => {
                if (offer) {
                    const dataUpdated = new OfferUpdateModel();
                    dataUpdated.init(form.state);
                    await client.updateOffer(offer.id, dataUpdated);
                } else {
                    const data = new OfferCreationModel();
                    data.init(form.state);
                    data.associatedProducts = products.map((p) => p.id);
                    await client.createOffer(data);
                }
                form.commit();
                goBack(true);
            }, appDispatch);
        }
    }, [appDispatch, form, goBack, offer, products]);
    const offerCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: offer ? 'Sauvegarder' : 'Créer',
                icon: 'Save',
                onClick: () => {
                    onSave();
                },
            },
        ];
    }, [offer, onSave]);
    const marketingCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: 'Ajouter',
                icon: 'AddTo',
                onClick: () => {
                    form.update({ data: { marketing: (mktArray) => ArrayOperation.add(mktArray, {}) } });
                },
            },
        ];
    }, [form]);

    const columns = [
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IProduct) => {
                return (
                    <ActionButton
                        onClick={() => {
                            nav.navigate(screenPaths.productUpdate(item.id), item);
                        }}
                    >
                        {item.name}
                    </ActionButton>
                );
            },
        },
        {
            key: 'type',
            name: 'Type',

            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IProduct) => {
                return <span>{getProductTypeText(item.productType)}</span>;
            },
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
        },
    ];
    const defaultProductGridCriteria = useMemo<FilterChange | undefined>(() => (offer?.id ? { mode: 'Full', change: { offerId: offer.id } } : undefined), [offer?.id]);
    return (
        <>
            <Panel
                isContentModified={form.isModified}
                revertContentModification={form.rollback}
                backButtonCommand={() => (nav.from ? goBack(false) : nav.navigate(screenPaths.offerList))}
                title='Offre'
                icon='ProductCatalog'
                commands={offerCommand}
            >
                <div className='SakuraPanelsGrid2'>
                    <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                    <TextField label='Description' errorMessage={form.fieldErrors.description} value={form.state.description ?? ''} onChange={(_, v) => form.update({ description: v })} />
                    <NumberField inputType='DecimalNumber' label='Prix' errorMessage={form.fieldErrors.price} value={form.state.price} onChange={(v) => form.update({ price: v })} />
                    <TextField
                        label='Référence externe'
                        errorMessage={form.fieldErrors.description}
                        value={form.state.externalSystemReference}
                        onChange={(_, val) => form.update({ externalSystemReference: val })}
                    />
                </div>
                <Toggle
                    label={'Offre actuelle'}
                    onText='Oui'
                    offText='Non'
                    checked={form.state.isCurrentOffer}
                    onChange={(_, checked) =>
                        form.update({
                            isCurrentOffer: checked,
                        })
                    }
                />
                <Separator />
                <FormGroup isCollapsable title={`Publicité (${form.state.data?.marketing?.length ?? 0})`} commands={marketingCommand}>
                    <div className='offerMarketing'>
                        {form.state.data?.marketing?.map((mkt, index) => {
                            const errorItem = form.fieldErrors.data?.marketing?.at(index);
                            return (
                                <Fragment key={`mkt ${index}`}>
                                    <div className='offerMarketingItem'>
                                        <div className='SakuraPanelsGrid3'>
                                            <ResourceLookupField
                                                required
                                                label='Image publicitaire'
                                                type='ResourceImagePubliciteLookup'
                                                selectedResourceId={mkt.imageResourceId}
                                                onSelectResource={(resource) =>
                                                    form.update({ data: { marketing: (mktArray) => ArrayOperation.updateByIndex(mktArray, index, { imageResourceId: resource?.id }) } })
                                                }
                                            />
                                            <TextField
                                                label='Lien du tunnel de vente'
                                                errorMessage={errorItem?.link}
                                                required
                                                value={mkt.link ?? ''}
                                                onChange={(_, v) => form.update({ data: { marketing: (mktArray) => ArrayOperation.updateByIndex(mktArray, index, { link: v }) } })}
                                            />
                                            <NumberField
                                                inputType='DecimalNumber'
                                                label='Prix Indicatif'
                                                errorMessage={errorItem?.price}
                                                value={mkt.price}
                                                onChange={(v) => form.update({ data: { marketing: (mktArray) => ArrayOperation.updateByIndex(mktArray, index, { price: v }) } })}
                                            />
                                            <Toggle
                                                onText='Permanent'
                                                offText='Evenementiel'
                                                checked={mkt.startDate === undefined}
                                                onChange={(_, checked) =>
                                                    form.update({
                                                        data: {
                                                            marketing: (mktArray) =>
                                                                ArrayOperation.updateByIndex(mktArray, index, { startDate: !checked ? new Date(Date.now()) : undefined, endDate: undefined }),
                                                        },
                                                    })
                                                }
                                            />
                                            {mkt.startDate !== undefined && (
                                                <>
                                                    <DatetimePicker
                                                        label='Date de début de diffusion'
                                                        value={mkt.startDate}
                                                        onSelectDate={(v) =>
                                                            form.update({ data: { marketing: (mktArray) => ArrayOperation.updateByIndex(mktArray, index, { startDate: v ?? undefined }) } })
                                                        }
                                                    />
                                                    <DatetimePicker
                                                        label='Date de fin de diffusion'
                                                        value={mkt.endDate}
                                                        onSelectDate={(v) =>
                                                            form.update({ data: { marketing: (mktArray) => ArrayOperation.updateByIndex(mktArray, index, { endDate: v ?? undefined }) } })
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <PrimaryButton
                                            className='button'
                                            text='Supprimer'
                                            onClick={() => {
                                                form.update({
                                                    data: {
                                                        marketing: (mktArray) => ArrayOperation.removeByIndex(mktArray, index),
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                </Fragment>
                            );
                        })}
                    </div>
                </FormGroup>
            </Panel>
            <Separator />
            <Panel
                icon='Product'
                title="Produits de l'offre"
                commands={[
                    {
                        icon: 'AddTo',
                        text: 'Ajouter',
                        onClick: () => {
                            setShowAddProductDialog(true);
                        },
                    },
                    {
                        icon: 'Delete',
                        text: 'Supprimer',
                        disabled: !selectedProduct,
                        onClick: () => {
                            setShowDeleteDialog(true);
                        },
                    },
                ]}
            >
                {createMode ? (
                    <LocalDatasetGrid data={products} columns={columns} getKey={(item) => item.id ?? ''} onSelect={onSelect} />
                ) : (
                    <DatasetGrid datasetName={'ProductsByOffer'} defaultCriteria={defaultProductGridCriteria} columns={columns} getKey={(item) => item.id ?? ''} onSelect={onSelect} />
                )}
            </Panel>
            <EditDialog
                id='SelectProductDialog'
                icon={{ iconName: 'Product' }}
                show={showAddProductDialog}
                title='Ajouter un produit'
                subText={''}
                mode={'Add'}
                onSubmit={() => {
                    performApiCall(
                        async (client) => {
                            if (createMode && productToAdd) {
                                setProducts([...products, productToAdd]);
                            } else if (offer) {
                                if (productToAdd?.id) {
                                    await client.addProductToOffer(offer.id, productToAdd.id);
                                    setShowAddProductDialog(false);
                                    ProductsByOffer.refresh();
                                    setProductToAdd(undefined);
                                    ProductLookup.clear();
                                }
                            }
                        },
                        appDispatch,
                        { contextId: 'SelectProductDialog' },
                    );
                }}
                onClose={() => {
                    setShowAddProductDialog(false);
                    setProductToAdd(undefined);
                    ProductLookup.clear();
                }}
            >
                <ProductLookupField label='Séléctionner un produit' selectedProduct={productToAdd} onSelectProduct={(p) => setProductToAdd(p)} />
            </EditDialog>
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: 'Product' }}
                show={showDeleteDialog}
                title="Suppression de l'offre"
                subText='Etes-vous sur de vouloir supprimer le produit de cette offre?'
                onReply={(reply) => {
                    if (reply) {
                        if (createMode && selectedProduct) {
                            setProducts(products.filter((p) => p.id !== selectedProduct.id));
                        } else if (offer) {
                            performApiCall(
                                async (client) => {
                                    if (selectedProduct?.id) {
                                        await client.deleteProductFromOffer(offer.id, selectedProduct.id);
                                        setShowDeleteDialog(false);
                                        ProductsByOffer.refresh();
                                    }
                                },
                                appDispatch,
                                { contextId: 'ConfirmationDialog' },
                            );
                        }
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
        </>
    );
};
