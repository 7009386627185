import { ContextSelector, useContextSelector } from '@fluentui/react-context-selector';
import { FormationContextValue, FormationDataContext } from './FormationContextProvider';

export const useFormationContextSelector = <T>(selector: ContextSelector<FormationContextValue, T>) => useContextSelector(FormationDataContext, selector);

export function useFormationContextActions() {
    return useFormationContextSelector((c) => c.actions);
}
export function useUserDataOfMenuItem(resourcePath: string) {
    return useFormationContextSelector((context) => {
        const index = context.userData?.userDataIndex[resourcePath];
        return context.userData?.userDatas[index ?? 0];
    });
}
