import { FC, useMemo } from 'react';
import { IAccountDataResource, IResource, IResourceHierarchyView, IResourcePackage } from '../../../Services/SakuraApiClient';

import clsx from 'clsx';
import { useAppSelector } from '../../../Redux/hook';
import { mobileViewSelector, tabletteViewSelector } from '../../../Redux/Reducers/System/reducer';
import './Formation.scss';
import { FormationContentPage } from './FormationContentPage';
import { FormationContextProvider } from './FormationContext/FormationContextProvider';
import { FormationContextServerSync } from './FormationContext/ServerSync/FormationContextServerSync';
import { FormationNavigation } from './FormationNavigation';
import { FormationNavigationMobile } from './FormationNavigationMobile';
import { buildFormationContent } from './helper';

export interface FormationViewProps {
    resource: IResource;
    resourcePackage: IResourcePackage;
    accountResourceData: IAccountDataResource;
    formationHierarchyView: IResourceHierarchyView;
}

export const FormationView: FC<FormationViewProps> = (props: FormationViewProps) => {
    const { formationHierarchyView } = props;
    const isMobileView = useAppSelector(mobileViewSelector);
    const isTabeletteView = useAppSelector(tabletteViewSelector);
    const formationContent = useMemo(() => {
        return buildFormationContent(formationHierarchyView);
    }, [formationHierarchyView]);

    const isMobile = useMemo(() => {
        return isMobileView || isTabeletteView;
    }, [isMobileView, isTabeletteView]);
    return (
        <div className={clsx('sakura-formation', isMobile ? 'mobile' : 'desktop')}>
            <FormationContextProvider formationContent={formationContent} serializationIdentifier={undefined}>
                <FormationContextServerSync />
                {isMobile ? <FormationNavigationMobile formationHierarchyView={formationHierarchyView} /> : <FormationNavigation formationHierarchyView={formationHierarchyView} />}
                <FormationContentPage />
            </FormationContextProvider>
        </div>
    );
};
