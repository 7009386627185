import { ProgressIndicator } from '@fluentui/react';
import { FC, PropsWithChildren } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/hook';
import { preventLostDataSelectorViewSelector, setPreventLostData, useLoadingSelector } from '../../Redux/Reducers/System/reducer';
import { userOrganizationSelector } from '../../Redux/Reducers/User/reducer';
import { SystemPopup } from '../../SystemPopup';
import { BadgeToastLayout } from '../components/Badge/BadgeToastLayout';
import { ConfirmationDialog } from '../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { ErrorMessageBar } from '../components/ErrorMessageBar/ErrorMessageBar';
import palette from '../styles/_variables.module.scss';
import { LayoutHeader } from './LayoutHeader';
import { MenuEntry } from './LayoutNavigation';
import { LayoutPage } from './LayoutPage';
interface LayoutProps {
    menu: MenuEntry[];
}
const heightLoading = 3;
const colorLoading = 'neutralLight';
export const Layout: FC<PropsWithChildren<LayoutProps>> = (props: PropsWithChildren<LayoutProps>) => {
    const { children, menu } = props;
    const loadingIndicator = useLoadingSelector();
    const appDispatch = useAppDispatch();
    const preventLostData = useAppSelector(preventLostDataSelectorViewSelector);
    const userOrga = useAppSelector(userOrganizationSelector);
    const rootPath = `/${userOrga === '' ? 'app' : userOrga}`;
    return (
        <>
            <LayoutHeader menu={menu} side='left' rootPath={rootPath} />
            {loadingIndicator?.isLoading ? (
                <ProgressIndicator
                    barHeight={heightLoading}
                    styles={{
                        itemProgress: { padding: 0 },
                        progressTrack: { backgroundColor: palette[colorLoading] },
                    }}
                />
            ) : (
                <div style={{ height: heightLoading, backgroundColor: palette[colorLoading] }} />
            )}
            <ErrorMessageBar contextId='default' />
            <LayoutPage menu={menu} rootPath={rootPath}>
                {children}
            </LayoutPage>
            <BadgeToastLayout />
            {preventLostData ? (
                <ConfirmationDialog
                    id='GlobalConfirmationDialogWithoutSave'
                    show={true}
                    title='Données non enregistrée'
                    subText='Etes-vous sur de vouloir quitter cet ecran sans sauvegarder?'
                    onReply={(reply) => {
                        if (reply) {
                            appDispatch(setPreventLostData({ callback: undefined, resetDirtyForm: true, contextId: 'default' }));
                            preventLostData();
                        } else {
                            appDispatch(setPreventLostData({ callback: undefined, contextId: 'default' }));
                        }
                    }}
                />
            ) : (
                <></>
            )}
            <SystemPopup />
        </>
    );
};
