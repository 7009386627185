import { FC } from 'react';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { IPageElementRichText } from '../../../../../Services/SakuraApiClient';

export interface RichTextElementPanelProps {
    data: IPageElementRichText;
    onChangeData: (data: DeepPartial<IPageElementRichText>) => void;
}

export const RichTextElementPanel: FC<RichTextElementPanelProps> = () => {
    return (
        <>
            <br />
            <span>{`Modifie directement le contenu et le style dans la zone d'affichage.`}</span>
        </>
    );
};
