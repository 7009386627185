import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState, SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { FC, useEffect, useRef } from 'react';
import { debounce } from '../../../../helpers/Debounce';
import { generateUniqueId } from '../../../../helpers/uniqueId';
import { loadEditorState } from '../../Helpers/EditorLoader';

export interface OnChangePluginProps {
    content: string | undefined;
    jsonState: SerializedEditorState<SerializedLexicalNode> | undefined;
    onChange: (editorState: EditorState) => void;
}
const OnChangePlugin: FC<OnChangePluginProps> = (props: OnChangePluginProps) => {
    const { onChange, content, jsonState } = props;
    const [editor] = useLexicalComposerContext();
    const changeIsInternal = useRef<boolean>(false);
    const name = useRef<string>(generateUniqueId('editorSave'));
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            debounce(
                name.current,
                () => {
                    console.log('Internal change');
                    changeIsInternal.current = true;
                    onChange(editorState);
                },
                250,
            )();
        });
    }, [editor, onChange]);

    useEffect(() => {
        if (changeIsInternal.current === false) {
            loadEditorState(editor, content, jsonState);
        } else {
            console.log('change but no reload');
            changeIsInternal.current = false;
        }
    }, [jsonState, content, editor]);

    return null;
};
export default OnChangePlugin;
