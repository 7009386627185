import { IStorageInformation } from '../../Services/SakuraApiClient';
import { ServiceCreator } from '../../Services/ServiceCreator';
import { combineUri } from '../helpers/uri';

export enum Container {
    Public = 'Public',
    Private = 'Private',
    User = 'User',
}
export interface BlobPath {
    container: string;
    path: string;
    orgId: number;
    accountId?: number;
    name: string;
    alternatives: string[] | undefined;
}

export function getAlternativePath(path: string, alternative: string) {
    const index = path.lastIndexOf('.');
    return `${path.substring(0, index)}_${alternative}${path.substring(index)}`;
}

export function parseBlobPath(containerAndblobPath: string | undefined): BlobPath | undefined {
    if (containerAndblobPath) {
        const parts = containerAndblobPath.split(':');
        if (parts.length === 1) {
            throw new Error('Container is missing');
        }
        const container = parts[0];
        const path = parts[1];
        const alternatives = parts.length > 2 ? parts[2] : null;
        let accountId: number | undefined;
        let name: string = path;
        const firstSlash = path.indexOf('/');
        if (firstSlash === -1) {
            throw new Error('Organization is missing');
        }
        const orgId = Number.parseInt(path.substring(0, firstSlash), 10);
        if (container === 'User') {
            const secondSlash = path.indexOf('/', firstSlash + 1);
            if (secondSlash === -1) {
                throw new Error('Account is missing');
            }
            accountId = Number.parseInt(path.substring(firstSlash + 1, secondSlash), 10);
            name = path.substring(secondSlash + 1);
        } else {
            name = path.substring(firstSlash + 1);
        }
        return {
            container,
            path,
            orgId,
            accountId,
            name,
            alternatives: alternatives != null ? alternatives.split(',') : undefined,
        };
    }
    return undefined;
}
export function blobPathToString(blobPath: BlobPath) {
    if (blobPath.alternatives) {
        return `${blobPath.container}:${blobPath.path}:${blobPath.alternatives.join(',')}`;
    } else {
        return `${blobPath.container}:${blobPath.path}`;
    }
}
export function ST(storage: IStorageInformation | undefined, relativeUrl: string | undefined, isMobile?: boolean | undefined): string | undefined {
    if (!relativeUrl) {
        return undefined;
    }
    if (!storage?.endpoints) {
        return relativeUrl;
    }

    const index = relativeUrl.indexOf(':');
    if (index === -1) {
        return relativeUrl;
    }

    const path = parseBlobPath(relativeUrl);
    if (path) {
        const endpoint = storage.endpoints[path.container];
        console.debug('endpoint = ' + endpoint + ' container = ' + path.container);
        if (endpoint) {
            const storageUrl = endpoint.startsWith('http') || endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
            const indexQueryString = storageUrl.indexOf('?');
            let resolvedPath = path.path;
            if (isMobile) {
                if (path.alternatives && path.alternatives.findIndex((a) => a === 'm') !== -1) {
                    resolvedPath = getAlternativePath(path.path, 'm');
                }
            }
            if (indexQueryString === -1) {
                console.debug('return  = ' + combineUri(storageUrl, resolvedPath));
                return combineUri(storageUrl, resolvedPath);
            } else {
                const storageUrlPath = storageUrl.substring(0, indexQueryString);
                return combineUri(storageUrlPath, storageUrl.substring(indexQueryString) + resolvedPath);
            }
        }
    }
    return undefined;
}
export async function getAccessLink(blobpath: string, blobPathKind: 'Value' | 'ProtectedValue'): Promise<string | undefined> {
    if (blobPathKind === 'Value') {
        const client = ServiceCreator.SakuraApiClient();
        const path = parseBlobPath(blobpath);
        if (path) {
            const blob = await client.getDownloadLink(path.container, path.path);
            const url = blob.baseUrl + encodeURIComponent(blob.blobReferenceProtected ?? '');
            return url;
        }
    }
    if (blobPathKind === 'ProtectedValue') {
        return '/api/Storage/P/access?blobPath=' + encodeURIComponent(blobpath);
    }

    return undefined;
}

export async function triggerDownloadBlob(relativeUrl: string | undefined, filename?: string, userFile?: boolean): Promise<void> {
    if (!relativeUrl) {
        return;
    }
    const index = relativeUrl.indexOf(':');
    if (index === -1) {
        return;
    }
    const path = parseBlobPath(relativeUrl);
    if (path) {
        const client = ServiceCreator.SakuraApiClient();
        const blob = await client.getDownloadLink(path.container, path.path);
        if (blob) {
            const url = blob.baseUrl + encodeURIComponent(blob.blobReferenceProtected ?? '');
            triggerDownload(url, filename ?? blob.metadata?.filename);
        }
    }
}

export function triggerDownload(url: string, downloadName?: string) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    if (downloadName) {
        element.setAttribute('download', downloadName);
    }
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
