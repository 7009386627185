import { Separator, TextField } from '@fluentui/react';
import { FC, useContext, useMemo } from 'react';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { PanelCommand } from '../../../../common/components/Panel/Panel';
import { ArrayOperation } from '../../../../common/helpers/ObjectAndArray';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ICategoryResultDescription } from '../../../../Services/SakuraApiClient';
import { QuestionnaryDataContext } from './DataContext/QuestionnaryDataContextProvider';
import { ResultDescription } from './ResultDescription';

export interface CategoryProps {
    item: DeepPartial<ICategoryResultDescription> | undefined;
    onUpdate: (items: DeepPartial<ICategoryResultDescription> | undefined) => void;
    hasResponse: boolean;
}
export const Category: FC<CategoryProps> = (props: CategoryProps) => {
    const { item, onUpdate, hasResponse } = props;

    const context = useContext(QuestionnaryDataContext);

    const categoryCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: 'Ajouter',
                icon: 'CircleAddition',
                disabled: hasResponse,
                onClick: () => {
                    onUpdate({ ...item, descriptions: [...(item?.descriptions ?? []), {}] });
                },
            },
        ];
    }, [hasResponse, item, onUpdate]);

    return (
        <>
            <TextField
                label='Nom'
                value={item?.name}
                onChange={(_, name) => {
                    if (name) {
                        context.upsertCategory({ name, id: item?.id ?? '' });
                        onUpdate({ ...item, name });
                    }
                }}
            ></TextField>
            <Separator />
            <FormGroup title='Description en fonction du score' commands={categoryCommand}>
                {(item?.descriptions ?? []).map((description, indexCategory: number) => {
                    return (
                        <ResultDescription
                            key={`category_${indexCategory}`}
                            item={description}
                            disableDelete={hasResponse}
                            onUpdate={(data) => {
                                if (item) {
                                    onUpdate({
                                        ...item,
                                        descriptions: ArrayOperation.updateByIndex(item?.descriptions ?? [], indexCategory, data),
                                    });
                                }
                            }}
                            onDelete={() => {
                                onUpdate({
                                    ...item,
                                    descriptions: ArrayOperation.removeByIndex(item?.descriptions ?? [], indexCategory),
                                });
                            }}
                        />
                    );
                })}
            </FormGroup>
        </>
    );
};
