import { useEffect } from 'react';

export function useKeyBoardEvent(eventSelector: (event: KeyboardEvent) => boolean, action: () => void) {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (eventSelector(event)) {
                event.preventDefault();
                action();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [eventSelector, action]);
}

export const keyboardEvents = {
    save: (event: KeyboardEvent) => (event.ctrlKey || event.metaKey) && event.key === 's',
};
