import { Pivot, PivotItem } from '@fluentui/react';
import { FC } from 'react';
import { IPageElementLink } from '../../../../../Services/SakuraApiClient';
import { ExpressionField, ExpressionKind } from '../../../../../common/components/ExpressionField/ExpressionField';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { ConfigureClickAction } from '../Common/ConfigureClickAction';
import { CssCommonField } from '../Common/CssCommonField';
import { FontStyle } from '../Common/FontStyle';

export interface LinkElementPanelProps {
    data: IPageElementLink;
    onChangeData: (data: DeepPartial<IPageElementLink>) => void;
}

export const LinkElementPanel: FC<LinkElementPanelProps> = (props: LinkElementPanelProps) => {
    const { onChangeData, data } = props;

    return (
        <>
            <Pivot>
                <PivotItem headerText='Propriétés'>
                    <ExpressionField
                        kind={ExpressionKind.TextWithExpression}
                        label='Texte du lien'
                        value={data.value}
                        onChange={(val) => {
                            onChangeData({ value: val });
                        }}
                        showExpandButton
                    ></ExpressionField>
                    <ConfigureClickAction label='Action a effectuée' data={data.onClick} onChange={(onClick) => onChangeData({ onClick })} />
                </PivotItem>
                <PivotItem headerText='Style'>
                    <FontStyle
                        font={data.style?.font}
                        onChangeFont={(font) => {
                            onChangeData({ style: { font } });
                        }}
                    />
                    <CssCommonField data={data.style} onChange={(style) => onChangeData({ style })} />
                </PivotItem>
            </Pivot>
        </>
    );
};
