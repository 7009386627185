import { ActionButton, Pivot, PivotItem, PrimaryButton } from '@fluentui/react';
import { FC, useMemo, useState } from 'react';
import { SimpleDialog } from '../Dialog/SimpleDialog/SimpleDialog';

import { storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { ST, parseBlobPath } from '../../Hooks/StorageResolver';
import './ButtonImageViewer.scss';
import { ImageViewerContent } from './ImageViewerContent';

export interface ButtonImageViewerprops {
    imageName?: string;
    imageDescription?: string;
    blobPath?: string;
    display: 'Action' | 'Button';
    disabled?: boolean;
}
const iconViewProps = { iconName: 'View' };

export const ButtonImageViewer: FC<ButtonImageViewerprops> = (props: ButtonImageViewerprops) => {
    const { blobPath, display, imageName, imageDescription, disabled } = props;
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const storage = useAppSelector(storageEndpointsSelector);

    const onShow = () => {
        setShowDialog(true);
    };
    const blobPathData = useMemo(() => {
        return parseBlobPath(blobPath);
    }, [blobPath]);
    const hasMobile = useMemo(() => {
        return blobPathData?.alternatives && blobPathData.alternatives.findIndex((s) => s === 'm') !== -1;
    }, [blobPathData]);
    return (
        <>
            {display === 'Button' && <PrimaryButton disabled={disabled} iconProps={iconViewProps} text={'voir'} onClick={onShow} />}
            {display === 'Action' && <ActionButton disabled={disabled} iconProps={iconViewProps} text={'voir'} onClick={onShow} />}
            {showDialog && (
                <SimpleDialog id='Dialog' show={showDialog} onClose={() => setShowDialog(false)} title={imageName ?? ''} subText={imageDescription ?? ''}>
                    <div className='imageModal'>
                        {hasMobile ? (
                            <Pivot>
                                <PivotItem headerText='Desktop'>
                                    <ImageViewerContent url={ST(storage, blobPath)} />
                                </PivotItem>
                                <PivotItem headerText='Mobile'>
                                    <ImageViewerContent url={ST(storage, blobPath, true)} />
                                </PivotItem>
                            </Pivot>
                        ) : (
                            <ImageViewerContent url={ST(storage, blobPath)} />
                        )}
                    </div>
                </SimpleDialog>
            )}
        </>
    );
};
