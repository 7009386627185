import { FC, useState } from 'react';
import { ProductEditDialog } from '../../Pages/Admin/Products/ProductEditDialog';
import { ProductLookup } from '../../Redux/Reducers/DataSet/ApiCall/Product';
import { IProduct } from '../../Services/SakuraApiClient';
import { DatasetLookupField } from './DatasetLookupField';

export interface ProductLookupFieldProps {
    selectedProduct: IProduct | undefined;
    onSelectProduct: (product: IProduct | undefined) => void;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
}
export const ProductLookupField: FC<ProductLookupFieldProps> = (props: ProductLookupFieldProps) => {
    const { label, selectedProduct, onSelectProduct, readOnly, required, contextId } = props;
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);

    return (
        <>
            <DatasetLookupField
                label={label}
                apiCall={ProductLookup}
                accessKeyProp={(i) => i.id.toString()}
                accessTextProp={(i) => i.name ?? ''}
                selectedItem={selectedProduct}
                onSelectItem={(item) => onSelectProduct(item)}
                readOnly={readOnly}
                required={required}
                contextId={contextId}
                onCreateNewItem={() => {
                    setShowCreateDialog(true);
                }}
            />
            {showCreateDialog && (
                <ProductEditDialog
                    show={showCreateDialog}
                    onClose={(newItem: IProduct | undefined) => {
                        if (newItem) {
                            onSelectProduct(newItem);
                        }
                        setShowCreateDialog(false);
                    }}
                />
            )}
        </>
    );
};
