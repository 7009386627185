//note that we don't use export default here
//create context here, use context in others

import { createContext, FC, PropsWithChildren, useCallback, useMemo, useReducer } from 'react';
import { PageElement } from '../../../../Services/SakuraApiClient';
import { EditorDataContextReducer, EditorDataContextReducerType } from './EditorDataContextReducer';
import { IEditorDataContextState, ViewType } from './IEditorDataContextState';

const emptyContext: IEditorDataContextState = { selectedElement: undefined, deleteSelectedElement: undefined, updateSelectedElement: undefined, viewType: 'Desktop' };
//this DataContext will be shared by all the components
export const EditorDataContext = createContext<IEditorDataContextProvider>({
    selectedElement: undefined,
    viewType: 'Desktop',
    select: (_) => {
        alert('Not Configured');
    },
    unSelect: () => {
        alert('Not Configured');
    },
    updateSelected: (_) => {
        alert('Not Configured');
    },
    deleteSelected: () => {
        alert('Not Configured');
    },
    assignedPlanSelection: () => {
        alert('Not Configured');
    },
    changeViewType: (_) => {
        alert('Not Configured');
    },
});

export interface IEditorDataContextProvider {
    selectedUIComponentId?: string | undefined;
    viewType: ViewType;
    selectedElement?: PageElement | undefined;
    planSelection?: PageElement | undefined;
    select: (uiComponentId: string, element: PageElement, updater: (data: PageElement) => PageElement, deleter: () => void) => void;
    unSelect: () => void;
    updateSelected: (element: PageElement) => void;
    deleteSelected: () => void;
    assignedPlanSelection: (element: PageElement) => void;
    changeViewType: (viewType: ViewType) => void;
}

export const EditorDataContextProvider: FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;
    const [state, dispatch] = useReducer<EditorDataContextReducerType, IEditorDataContextState>(EditorDataContextReducer, emptyContext, () => emptyContext);

    const select = useCallback(
        (uiComponentId: string, element: PageElement, updater: (data: PageElement) => PageElement, deleter: () => void) => {
            dispatch({ type: 'select', payload: { uiComponentId, element, updater, deleter } });
        },
        [dispatch],
    );
    const unSelect = useCallback(() => {
        dispatch({ type: 'unSelect' });
    }, [dispatch]);
    const updateSelected = useCallback(
        (element: PageElement) => {
            if (state.updateSelectedElement) {
                state.updateSelectedElement(element);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state.updateSelectedElement],
    );
    const deleteSelected = useCallback(() => {
        if (state.deleteSelectedElement) {
            state.deleteSelectedElement();
            dispatch({ type: 'unSelect' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, state.deleteSelectedElement]);

    const assignedPlanSelection = useCallback(
        (element: PageElement) => {
            dispatch({ type: 'planSelection', payload: { element } });
        },
        [dispatch],
    );
    const changeViewType = useCallback(
        (type: ViewType) => {
            dispatch({ type: 'changeView', payload: { type } });
        },
        [dispatch],
    );
    const value = useMemo<IEditorDataContextProvider>(() => {
        return {
            selectedUIComponentId: state.uiComponentId,
            viewType: state.viewType,
            selectedElement: state.selectedElement,
            planSelection: state.planSelection,
            select,
            unSelect,
            updateSelected,
            deleteSelected,
            assignedPlanSelection,
            changeViewType,
        };
    }, [state.uiComponentId, state.viewType, state.selectedElement, state.planSelection, select, unSelect, updateSelected, deleteSelected, assignedPlanSelection, changeViewType]);
    return <EditorDataContext.Provider value={value}>{children}</EditorDataContext.Provider>;
};
