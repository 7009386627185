import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../Redux/hook';
import { ISakuraApiClient } from '../../Services/SakuraApiClient';
import { performApiCall } from './useApiCall';
import { INavigationState } from './useNav';

export function useNavigationEntity<T>(resolverEntity: (entityId: number | string, client: ISakuraApiClient) => Promise<T>): [string | undefined, T | undefined] {
    const location = useLocation();
    const appDispatch = useAppDispatch();
    const entityFromNavState = location.state as INavigationState<T>;
    const [entity, setEntity] = useState<T>(entityFromNavState?.state);
    const { id } = useParams();
    useEffect(() => {
        if (entity === undefined && id !== undefined) {
            let idNumber: string | number = Number.parseInt(id, 10);
            if (Number.isNaN(idNumber)) {
                idNumber = id;
            }
            const resolver = async () => {
                await performApiCall(async (client) => {
                    setEntity(await resolverEntity(idNumber, client));
                }, appDispatch);
            };
            resolver();
        }
    }, [appDispatch, entity, id, resolverEntity]);
    return [id, entity];
}
