import { Dropdown, IDropdownOption, Label } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { IPageElementCustomComponent } from '../../../../../../Services/SakuraApiClient';
import { ExpressionField, ExpressionKind } from '../../../../../../common/components/ExpressionField/ExpressionField';
import { DeepPartial } from '../../../../../../common/helpers/ObjectAndArray';
import { ThumbnailSectionProps } from '../../../../Page/Elements/CustomComponent/ThumbnailsSection';
import { ContentSourceType, contentSourceLabels } from '../../../../Page/Elements/CustomComponent/ThumbnailsSection/ContentSourceType';

export interface ThumbnailsSectionPanelProps {
    data: IPageElementCustomComponent;
    onChangeData: (data: DeepPartial<IPageElementCustomComponent>) => void;
}

const dataSourceList: IDropdownOption[] = [
    {
        key: 'FormationActive',
        text: contentSourceLabels['FormationActive'],
    },
    {
        key: 'ResourceTileOwnByAccount',
        text: contentSourceLabels['ResourceTileOwnByAccount'],
    },
    {
        key: 'ProductTile',
        text: contentSourceLabels['ProductTile'],
    },
    {
        key: 'ResourceTileOwnByAccountAndProductTile',
        text: contentSourceLabels['ResourceTileOwnByAccountAndProductTile'],
    },
    {
        key: 'FavoriteResource',
        text: contentSourceLabels['FavoriteResource'],
    },
    {
        key: 'WhishlistProduct',
        text: contentSourceLabels['WhishlistProduct'],
    },
    {
        key: 'FavResAndWhishPrd',
        text: contentSourceLabels['FavResAndWhishPrd'],
    },
];

const ContentNeedSection = ['ResourceTileOwnByAccount', 'ProductTile', 'ResourceTileOwnByAccountAndProductTile'];

export const ThumbnailsSectionPanel: FC<ThumbnailsSectionPanelProps> = (props: ThumbnailsSectionPanelProps) => {
    const { data, onChangeData } = props;
    const cProps = data.props as ThumbnailSectionProps;
    const needSection = useMemo(() => {
        if (cProps?.contentSource) {
            return ContentNeedSection.includes(cProps.contentSource);
        }
        return false;
    }, [cProps?.contentSource]);
    return (
        <>
            <Label>{'Source de données'}</Label>
            <Dropdown
                options={dataSourceList}
                selectedKey={cProps?.contentSource ?? 'FormationActive'}
                onChange={(_, opt) => {
                    const contentSource = opt?.key as ContentSourceType;
                    onChangeData({ props: { contentSource } });
                }}
            />
            {needSection ? (
                <ExpressionField
                    kind={ExpressionKind.TextWithExpression}
                    showExpandButton
                    label='Section'
                    value={data.props?.sectionName}
                    onChange={(sectionName) => {
                        onChangeData({ props: { sectionName } });
                    }}
                ></ExpressionField>
            ) : null}
        </>
    );
};
