import { FC, useContext, useMemo } from 'react';
import { IResourceDataPage, PageElement } from '../../../../Services/SakuraApiClient';
import { EditorDataContext } from '../EditorContext/EditorDataContextProvider';
import { PageHierarchyMapItem } from './PageHierarchyMapItem';

//const listOfElements = Object.values(PageElementType);
export interface PageHierachyMapProps {
    data: IResourceDataPage;
}
export const PageHierachyMap: FC<PageHierachyMapProps> = (props: PageHierachyMapProps) => {
    const editorContext = useContext(EditorDataContext);
    const { data } = props;
    const page = useMemo(() => {
        return editorContext.viewType === 'Desktop' ? data.page : data.mobilePage;
    }, [editorContext.viewType, data.page, data.mobilePage]);
    return (
        <div className='hierachyMap'>
            <PageHierarchyMapItem
                data={page}
                onClick={(item) => {
                    editorContext.assignedPlanSelection(item as PageElement);
                }}
            />
        </div>
    );
};
