import { ISelectableOption, Label } from '@fluentui/react';
import axios from 'axios';
import { FC, useEffect, useMemo, useState } from 'react';
import { ImageCategories } from '../../Pages/Admin/Resources/Image/ImageCategory';
import { ResourceDetails } from '../../Pages/Admin/Resources/ResourceDetails';
import { useAppDispatch } from '../../Redux/hook';
import { datasetCollections, ResourceLookUp } from '../../Redux/Reducers/DataSet/DataSetName';
import { IResource, ResourceTypeEnum } from '../../Services/SakuraApiClient';
import { ResourceImageViewer } from '../components/ImageViewer/ResourceImageViewer';
import { FilterDefinition } from '../components/SearchComboBox/SearchParameters';
import { performApiCall } from '../Hooks/useApiCall';
import { useState2 } from '../Hooks/useState2';
import { DatasetLookupField } from './DatasetLookupField';

export interface ResourceLookupFieldProps {
    selectedResourceId: number | undefined;
    onSelectResource: (resource: IResource | undefined) => void;
    type: ResourceLookUp;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
    removeItemCreate?: boolean;
    filters?: FilterDefinition[];
}
function getCategoryFromResourceLookupType(type: ResourceLookUp) {
    switch (type) {
        case 'ResourceImagePubliciteLookup':
            return ImageCategories[1].key;
        case 'ResourceImageVignetteLookup':
            return ImageCategories[2].key;
        case 'ResourceImageContentLookup':
            return ImageCategories[3].key;
    }
    return undefined;
}
export const ResourceLookupField: FC<ResourceLookupFieldProps> = (props: ResourceLookupFieldProps) => {
    const { label, selectedResourceId, onSelectResource, readOnly, type, required, contextId, filters, removeItemCreate } = props;
    const [apiCall, setApiCall, apiCallRef] = useState2(datasetCollections[type]);
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const appDispatch = useAppDispatch();

    useEffect(() => {
        setApiCall(datasetCollections[type]);
        setSelectedResource(undefined);
    }, [type, setApiCall]);
    useEffect(() => {
        const source = axios.CancelToken.source();
        if (selectedResourceId && apiCallRef.current) {
            const items = apiCallRef.current.lastResult?.result?.filter((r) => r.id === selectedResourceId);
            if (items && items.length === 1) {
                setSelectedResource(items[0]);
            } else {
                performApiCall(
                    async (client, cancelToken) => {
                        const res = await client.getResource(selectedResourceId, cancelToken);
                        setSelectedResource(res);
                    },
                    appDispatch,
                    {
                        contextId,
                        cancelToken: source.token,
                    },
                );
            }
        } else {
            setSelectedResource(undefined);
        }
        return () => {
            source.cancel();
        };
    }, [selectedResourceId, apiCallRef, appDispatch, contextId]);
    const categoryForCreation = useMemo(() => getCategoryFromResourceLookupType(type), [type]);
    const viewMode = useMemo(() => {
        if (type === 'ResourceImageContentLookup' || type === 'ResourceImageLookup' || type === 'ResourceImagePubliciteLookup' || type === 'ResourceImageVignetteLookup') {
            return 'Grid';
        }
        return 'List';
    }, [type]);
    return (
        <>
            {apiCall !== undefined ? (
                <DatasetLookupField
                    label={label}
                    apiCall={apiCall}
                    viewMode={viewMode}
                    accessKeyProp={(i) => i.id.toString()}
                    accessTextProp={(i) => i.name ?? ''}
                    selectedItem={selectedResource}
                    onSelectItem={(item) => onSelectResource(item)}
                    onCreateNewItem={
                        removeItemCreate
                            ? undefined
                            : () => {
                                  setShowCreateDialog(true);
                              }
                    }
                    onRenderItem={(item, comboBoxProps?: ISelectableOption, defaultRender?: (dfProps?: ISelectableOption) => JSX.Element | null) => {
                        if (item && item.resourceType === ResourceTypeEnum.Image) {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <ResourceImageViewer blobPath={item.blobPath ?? ''} title={item.description} style={{ width: 100, height: 100 }} />
                                    <Label>{item.name}</Label>
                                </div>
                            );
                        }
                        if (defaultRender) {
                            return defaultRender(comboBoxProps);
                        }
                        return <span>{item.name}</span>;
                    }}
                    filters={filters}
                    readOnly={readOnly}
                    required={required}
                    contextId={contextId}
                />
            ) : (
                <></>
            )}
            {showCreateDialog && (
                <ResourceDetails
                    show={showCreateDialog}
                    type={type}
                    category={categoryForCreation}
                    onClose={(newItem: IResource | undefined) => {
                        if (newItem) {
                            onSelectResource(newItem);
                        }
                        setShowCreateDialog(false);
                    }}
                />
            )}
        </>
    );
};
