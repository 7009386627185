import { FC, useMemo } from 'react';

import { ConfiguredLinks } from '../../../Redux/Reducers/System/state';
import { capitalizeFirstLetter } from '../../../common/helpers/string';
import { openNewTab } from '../../../common/helpers/uri';
import './SocialNetworkButton.scss';
import { SocialNetworkLogo } from './SocialNetworkLogo';
import { SocialNetworkName } from './type';

export interface SocialNetworkLogoProps {
    socialNetwork: SocialNetworkName;
    links: ConfiguredLinks;
}
export const SocialNetworkButton: FC<SocialNetworkLogoProps> = (props: SocialNetworkLogoProps) => {
    const { socialNetwork, links } = props;

    const link = useMemo(() => {
        if (links) {
            return (links as unknown as Record<SocialNetworkName, string>)[socialNetwork];
        }
        return undefined;
    }, [links, socialNetwork]);
    return link ? (
        <div
            className='socialNetworkButton'
            onClick={() => {
                openNewTab(link);
            }}
        >
            <SocialNetworkLogo socialNetwork={socialNetwork} size='extraLarge' />
            <span>{capitalizeFirstLetter(socialNetwork)}</span>
        </div>
    ) : null;
};
