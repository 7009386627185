import { FC, useState } from 'react';
import { ResourceImageGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { clearContext } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { parseBlobPath } from '../../../../common/Hooks/StorageResolver';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { ButtonImageViewer } from '../../../../common/components/ImageViewer/ButtonImageViewer';
import { Panel } from '../../../../common/components/Panel/Panel';
import { ResourceList } from '../ResourceList';
import { ImageCategories } from './ImageCategory';
import { ResourceImageDetails } from './ResourceImageDetails';

export const ResourceImageList: FC = () => {
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showEditDialog, setShowEditDialog] = useState<'New' | 'Edit' | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const appDispatch = useAppDispatch();

    return (
        <Panel
            title='Liste des images'
            icon={GetResourceTypeIcon(ResourceTypeEnum.Image)}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        setShowEditDialog('New');
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditDialog('Edit');
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedResource,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ResourceImageGrid.refresh();
                    },
                },
            ]}
        >
            <ResourceList
                datasetName={'ResourceImageGrid'}
                onSelect={(selection) => setSelectedResource(selection)}
                categoryOptions={ImageCategories}
                extraColumns={[
                    {
                        key: 'Image',
                        name: 'Image',
                        fieldName: 'blobPath',
                        minWidth: 75,
                        maxWidth: 75,
                        allowSorting: false,
                        isResizable: true,
                        onRender: (item: IResource) => {
                            return item.blobPath ? (
                                <ButtonImageViewer blobPath={item.blobPath} imageName={item.name} imageDescription={item.description} display='Action' />
                            ) : (
                                <span>{"Pas d'image"}</span>
                            );
                        },
                    },
                    {
                        key: 'hasMobile',
                        name: 'Variante Mobile',
                        fieldName: 'hasMobile',
                        minWidth: 125,
                        maxWidth: 125,
                        allowSorting: false,
                        isResizable: true,
                        onRender: (item: IResource) => {
                            if (item.blobPath) {
                                const blobPathData = parseBlobPath(item.blobPath);
                                const hasMobile = blobPathData?.alternatives && blobPathData.alternatives.findIndex((s) => s === 'm') !== -1;
                                return hasMobile ? <span>{'Oui'}</span> : <span>{'Non'}</span>;
                            }
                            return <span>{''}</span>;
                        },
                    },
                ]}
                onRowDoubleClick={(item) => {
                    setSelectedResource(item);
                    setShowEditDialog('Edit');
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Image) }}
                show={showDeleteDialog}
                title="Suppression de l'image"
                subText="Etes-vous sur de vouloir supprimer l'image?"
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedResource?.id) {
                                    await client.deleteResource(selectedResource.id);
                                    setShowDeleteDialog(false);
                                    ResourceImageGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
            {showEditDialog && (
                <ResourceImageDetails
                    resource={showEditDialog === 'Edit' ? selectedResource : undefined}
                    show={showEditDialog !== undefined}
                    onClose={(res) => {
                        setShowEditDialog(undefined);
                        if (res) {
                            ResourceImageGrid.refresh();
                        }
                    }}
                />
            )}
        </Panel>
    );
};
