import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { IntelisenseInfo } from '../../../../../Redux/Reducers/DynamiqueData/state';
import './Intelisense.scss';
import { IntelisenseFunctionDetail } from './IntelisenseFunctionDetail';

export interface IntelisenseEntry {
    name: string;
    info: IntelisenseInfo;
}
export interface TriggerRange {
    end: number;
    start: number;
    text: string;
}
export interface IntelisenseData {
    searchText?: string;
    position: { top: number; left: number };
    selectedIndex?: number;
    triggerRange: TriggerRange;
    functionInfo?: {
        info: IntelisenseInfo;
        argumentIndex: number;
    };
}
export interface IntelisenseProps {
    dataSource: IntelisenseEntry[];
    onSelect: (data: IntelisenseData, intelisenseEntry: IntelisenseEntry) => void;
    intelisenseData: IntelisenseData;
    onLostFocus: () => void;
}

const icons = {
    rovar: { icon: 'Product', classname: 'icon_rovar' },
    var: { icon: 'ProductRelease', classname: 'icon_var' },
    func: { icon: 'Variable', classname: 'icon_func' },
};

export const IntelisensePopup: FC<IntelisenseProps> = (props: IntelisenseProps) => {
    const { dataSource, intelisenseData, onSelect, onLostFocus } = props;
    const divFunctionDetails = useRef<HTMLDivElement>(null);
    const divDataSource = useRef<HTMLDivElement>(null);
    const { selectedIndex, position, functionInfo } = intelisenseData;

    const height = useMemo(() => {
        return 25 * Math.min(dataSource.length, 10) + 2; //2 pour le border
    }, [dataSource]);

    useEffect(() => {
        if (selectedIndex !== undefined) {
            const elementDiv = document.getElementById(`Entry_${selectedIndex}`);
            if (elementDiv) {
                const rectElem = elementDiv.getBoundingClientRect();
                const container = elementDiv.parentElement?.parentElement as HTMLDivElement;
                const rectContainer = container.getBoundingClientRect();
                if (rectElem.bottom > rectContainer.bottom) elementDiv.scrollIntoView(false);
                if (rectElem.top < rectContainer.top) elementDiv.scrollIntoView();
            }
        }
    }, [selectedIndex]);
    // Fermeture du menu lors d'un clic en dehors
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (event.defaultPrevented) {
                return;
            }
            if (event.target && divFunctionDetails.current && !divFunctionDetails.current.contains(event.target as Node)) {
                onLostFocus();
            }
            if (event.target && divDataSource.current && !divDataSource.current.contains(event.target as Node)) {
                onLostFocus();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onLostFocus]);

    const refParentCallout = useRef<HTMLDivElement>();
    const [offset, setOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    useEffect(() => {
        let extraOffset = { x: 0, y: 0 };
        if (!refParentCallout.current) {
            const parentElement = document.getElementsByClassName('sakuraCallout');
            if (parentElement && parentElement.length === 1) {
                refParentCallout.current = parentElement.item(0) as HTMLDivElement;
                refParentCallout.current = refParentCallout.current.children.item(2) as HTMLDivElement;
                extraOffset = { x: 0, y: 0 };
            } else {
                const parentElement = document.getElementsByClassName('sakuraDialog');
                if (parentElement && parentElement.length > 0) {
                    refParentCallout.current = parentElement.item(parentElement.length - 1) as HTMLDivElement;
                    if (refParentCallout.current.className.indexOf('fullscreen') !== -1) {
                        extraOffset = { x: -15, y: -45 };
                    }
                    refParentCallout.current = refParentCallout.current.children.item(1)?.children.item(0) as HTMLDivElement;
                }
            }
        }
        if (refParentCallout.current) {
            const rect = refParentCallout.current.getBoundingClientRect();
            setOffset({ x: rect.left + extraOffset.x, y: rect.top + extraOffset.y });
        }
    }, []);
    const divStyleFunc = useMemo(() => {
        return { top: position.top - offset.y, left: position.left - offset.x, height: 'auto' };
    }, [position, offset]);
    const divStyleList = useMemo(() => {
        return { top: position.top - offset.y, left: position.left - offset.x, height };
    }, [position, offset, height]);

    return (dataSource && dataSource.length > 0) || functionInfo ? (
        <>
            {functionInfo ? (
                <div ref={divFunctionDetails} style={divStyleFunc} className={'intelisense overlay'}>
                    <IntelisenseFunctionDetail argumentIndex={functionInfo.argumentIndex} info={functionInfo.info} />
                </div>
            ) : (
                <div ref={divDataSource} style={divStyleList} className={'intelisense overlay overflow'}>
                    <div className='intelisense-list'>
                        {dataSource.map((result, index) => {
                            return (
                                <div
                                    id={`Entry_${index}`}
                                    key={`Entry_${index}`}
                                    className={clsx('intelisense-item', selectedIndex === index ? 'selected' : undefined)}
                                    onMouseDown={(ev) => {
                                        ev.preventDefault();
                                        onSelect(intelisenseData, result);
                                        return false;
                                    }}
                                >
                                    <Icon iconName={icons[result.info.type].icon} className={icons[result.info.type].classname} />
                                    <span>{result.name}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    ) : (
        <></>
    );
};
