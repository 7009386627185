import clsx from 'clsx';
import { FC } from 'react';
import { triggerDownload } from '../../Hooks/StorageResolver';

import './clickablePicture.scss';

interface ClickablePictureProps {
    src?: string;
    href?: string;
    onClick?: () => void;
    className?: string;
}
export const ClickablePicture: FC<ClickablePictureProps> = (props: ClickablePictureProps) => {
    const { src, href, onClick, className } = props;

    const click = () => {
        if (onClick) {
            onClick();
        }
        if (href) {
            triggerDownload(href);
        }
    };
    return (
        <div className={clsx('clickablePicture', className ?? '')} onClick={click}>
            <img alt='img clickable' src={src} />
        </div>
    );
};
