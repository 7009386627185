import { Breadcrumb, IBreadcrumbItem, Icon } from '@fluentui/react';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { showDesktop, showMobile, showTablette } from '../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../Redux/hook';

import { CstWidthMobile, CstWidthTablette } from '../../Redux/Reducers/System/state';
import { useNav } from '../Hooks/useNav';
import { MenuEntry } from './LayoutNavigation';

import './LayoutPage.scss';

interface LayoutPageProps {
    menu: MenuEntry[];
    rootPath: string;
}

export const LayoutPage: FC<PropsWithChildren<LayoutPageProps>> = (props: PropsWithChildren<LayoutPageProps>) => {
    const { children, menu } = props;

    const dispatch = useAppDispatch();
    const nav = useNav();

    const handleResize = useCallback(() => {
        const mobile = window.innerWidth <= CstWidthMobile;
        const tablette = window.innerWidth <= CstWidthTablette;
        if (mobile) {
            dispatch(showMobile());
        } else {
            if (tablette) {
                dispatch(showTablette());
            } else {
                dispatch(showDesktop());
            }
        }
    }, [dispatch]);
    const getBreadCrumb = useCallback(
        (data: MenuEntry[]): IBreadcrumbItem[] => {
            let result: IBreadcrumbItem[] = [];
            data.forEach((d) => {
                const child = getBreadCrumb(d.children ?? []);
                if (d.path === nav.current.pathname || child.length > 0) {
                    if (d.disabledBreadCrumb) {
                        result = [];
                    } else {
                        result = [
                            {
                                text: d.title,
                                key: d.key,
                                data: d.icon,
                                isCurrentItem: d.path === nav.current.pathname,
                                onClick: d.path
                                    ? () => {
                                          nav.navigate(d.path ?? '');
                                      }
                                    : undefined,
                            },
                            ...child,
                        ];
                    }
                    return;
                }
            });
            return result;
        },
        [nav],
    );
    const [breadCrumbItems, setBreadCrumbItems] = useState<IBreadcrumbItem[]>(getBreadCrumb(menu));
    useEffect(() => {
        setBreadCrumbItems(getBreadCrumb(menu));
    }, [menu, getBreadCrumb]);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);
    return (
        <div id='layoutRoot' className='sakura-layout-scrollView'>
            <div className='sakura-layout-page'>
                <Breadcrumb
                    items={breadCrumbItems}
                    onRenderItem={(props?: IBreadcrumbItem, defaultRender?: (props?: IBreadcrumbItem) => JSX.Element | null): JSX.Element | null => {
                        return (
                            <>
                                {props?.data && <Icon iconName={props.data} />}
                                {defaultRender && defaultRender(props)}
                            </>
                        );
                    }}
                    maxDisplayedItems={10}
                    ariaLabel="Fil d'ariane"
                    overflowAriaLabel='More links'
                />

                {children}
            </div>
        </div>
    );
};
